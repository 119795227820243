import Bugsnag, { Client } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { HttpError } from '@shared/errors';
import { AxiosError } from 'axios';
import { defaultTo } from 'lodash';

import { getAppConfig } from '@/config/app-config';
import { noOpBugsnag } from '@/lib/bugsnag/noop-bugsnag-client';

const DEFAULT_RELEASE_STAGES = ['Development', 'Production', 'Test'];

export const makeBugsnagClient = (): Client => {
  let instance: Client;

  // @ts-expect-error TS(2454): Variable 'instance' is used before being assigned.
  if (instance) return instance;

  const {
    bugsnagEnabled,
    bugsnagApiKey,
    bugsnagEnabledReleaseStages,
    bugsnagReleaseStage,
    isDev,
  } = getAppConfig();

  if (!bugsnagEnabled) {
    console.info('Starting noop Bugsnag Client');
    instance = noOpBugsnag().start('');
    return instance;
  }

  if (!bugsnagApiKey) {
    instance = noOpBugsnag().start('');
    console.warn('Missing Bugsnag API key.');
    return instance;
  }

  if (!bugsnagReleaseStage) {
    console.warn('Missing Bugsnag Release stage.');
  }

  instance = Bugsnag.start({
    apiKey: bugsnagApiKey,
    plugins: [ new BugsnagPluginReact()],
    releaseStage: defaultTo(bugsnagReleaseStage, isDev ? 'local' : undefined),
    enabledReleaseStages: defaultTo(bugsnagEnabledReleaseStages, DEFAULT_RELEASE_STAGES),
    onError: (event) => {
      if (event.errors.some(error => error instanceof HttpError || error instanceof AxiosError)) return false;
    }
  });

  return instance;
};

export const bugsnagClient = makeBugsnagClient();
