import React from 'react';
import { LiaUserAltSlashSolid } from 'react-icons/lia';

import { TextLink } from '@/components-new/text';
import { DrugList } from '@/features/home/drug-list';
import { CurrentUser } from '@/types/me';

type ClientUserHomeProps = {
  user: CurrentUser;
};

export const ClientUserHome = ({ user }: ClientUserHomeProps) => {
  return (
    <>
    {user.client?.isActive ?
        <DrugList user={user} /> :
      <div className="text-center">
          <LiaUserAltSlashSolid className="mx-auto text-gray-400" size="3rem"/>
        <h3 className="mt-2 text-sm font-semibold text-gray-900">
          Inactive Account
        </h3>
        <p className="mt-1 text-sm text-gray-500">
            Your account is inactive. Please contact <TextLink href="mailto:capsule@artiasolutions.com">capsule@artiasolutions.com</TextLink>.
        </p>
      </div>
    }
    </>
  );
};
