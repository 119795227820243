import { useErrorHandler } from '@shared/errors';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateCoverageTag } from '@/features/drugs/api/update-coverage-tag';
import { makeCoverageTagQueryKey } from '@/features/drugs/api/use-coverage-tag';
import { makeCoverageTagsQueryKey } from '@/features/drugs/api/use-coverage-tags';
import { CoverageTag } from '@/features/drugs/types/coverage-tag';
import { CreateUpdateCoverageTagFormSchema } from '@/features/drugs/types/create-update-coverage-tag-form-schema';
import { notifySuccess } from '@/lib/notification/notifications';

export const useUpdateCoverageTag = () => {
  const queryClient = useQueryClient();
  const { handleError } = useErrorHandler();

  return useMutation({
    mutationFn: ({ drugId, coverageTagId, form }: { drugId: number, coverageTagId: number, form: CreateUpdateCoverageTagFormSchema }) => updateCoverageTag(drugId, coverageTagId, form),
    onSuccess: () => {
      notifySuccess({ title: 'Saved', message: 'Coverage Tag updated successfully.' });
    },
    onMutate: ({ drugId, coverageTagId, form }) => {
      const queryKey = makeCoverageTagQueryKey(drugId, coverageTagId);
      const previousCoverageTag = queryClient.getQueryData<CoverageTag>(queryKey);

      if (previousCoverageTag) {
        const updatedCoverageTag = {
          ...previousCoverageTag,
          name: form.name,
          type: form.type
        };

        queryClient.setQueryData<CoverageTag[]>(makeCoverageTagsQueryKey(drugId), (old) => (
          old?.map(coverageTag => {
            if (coverageTag.id === coverageTagId) return updatedCoverageTag;

            return coverageTag;
          })
        ));
        queryClient.setQueryData<CoverageTag>(queryKey, () => (updatedCoverageTag));
      }

      return { previousCoverageTag };
    },
    onError: (error, { drugId, coverageTagId }, context) => {
      handleError(error, { title: 'Save Failed', message: 'Unable to update coverage tag.', });

      if (context?.previousCoverageTag) {
        queryClient.setQueryData<CoverageTag>(makeCoverageTagQueryKey(drugId, coverageTagId), context.previousCoverageTag);
        // @ts-expect-error TS(2345): Argument of type '(old: CoverageTag[] | undefined) => (Us... Remove this comment to see the full error message
        queryClient.setQueryData<CoverageTag[]>(makeCoverageTagsQueryKey(drugId), (old) => (
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          old.map(coverageTag => {
            if (coverageTag.id === coverageTagId) return context.previousCoverageTag;

            return coverageTag;
          })
        ));
      }
    },
    onSettled: async (_, __, { drugId, coverageTagId }) => {
      await queryClient.invalidateQueries({ queryKey: makeCoverageTagQueryKey(drugId, coverageTagId) });
      await queryClient.invalidateQueries({ queryKey: makeCoverageTagsQueryKey(drugId) });
    }
  });
};
