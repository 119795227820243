import { PaginationResult } from '@shared/pagination';
import { Box, CheckBox, CheckBoxGroup, FormField, TextInput } from 'grommet';
import { toString } from 'lodash';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Setting, SettingsList } from '@//components/setting';
import { Busy } from '@/components/busy';
import { LazyLoadMultiSelectList } from '@/components/form-controls';
import { Button } from '@/components-new/button';
import { Client } from '@/features/clients';
import { useUpdateSubscription } from '@/features/clients/api/use-update-subscription';
import { Subscription } from '@/features/clients/models/subscription';
import { SubscriptionFormInputs } from '@/features/clients/models/subscription-form-inputs';
import { useLookupsService } from '@/hooks/use-lookups-service';
import { Lookup } from '@/types/lookup';

export const SubscriptionTabContent = ({ client, subscription }: SubscriptionTabContentProps) => {
  const { id } = useParams();
  const { mutate, isPending } = useUpdateSubscription();

  const {
    control,
    formState: { errors, isValid },
    handleSubmit
  } = useForm<SubscriptionFormInputs>({
    mode: 'all'
  });

  const { getClassifications, getProductLookups } = useLookupsService();

  const onSave = async (value: SubscriptionFormInputs) => {
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    mutate({ id: parseInt(id), form: value });
  };

  return (
    <Box>
      <SettingsList>
        <Setting title="Subscription Status">
          <Controller
            control={control}
            name="isActive"
            defaultValue={subscription.isActive}
            render={({ field: { value, onChange, onBlur } }) => (
              <CheckBox
                checked={value}
                label="Active"
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </Setting>

        <Setting title="Plan Limits">
          <Controller
            control={control}
            name="numberOfUsers"
            defaultValue={toString(subscription.numberOfUsers)}
            rules={{
              maxLength: 4,
              required: false,
              min: 0
            }}
            render={({ field: { ref, value, onChange, onBlur } }) => (
              <FormField
                name="numberOfUsers"
                htmlFor="numberOfUsers"
                label="Number of Users"
                error={errors.numberOfUsers?.message}
              >
                <TextInput
                  type="number"
                  ref={ref}
                  // @ts-expect-error TS(2322): Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  id="numberOfUsers"
                  name="numberOfUsers"
                  maxLength={4}
                  min={0}
                  max={9999}
                />
              </FormField>
            )}
          />
        </Setting>

        <Setting title="Plan Features" description="Which features are available to this client.">
          <Controller
            control={control}
            name="premiumFeatures"
            defaultValue={subscription.premiumFeatures
              .filter(feature => feature.isEnabled)
              .map(feature => feature.key)}
            render={({ field: { value, onChange } }) => (
              <CheckBoxGroup
                options={subscription.premiumFeatures}
                labelKey="name"
                valueKey="key"
                value={value}
                onChange={(event) => {
                  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                  onChange((event.value as unknown) as string[]);
                }}
              />
            )}
          />
        </Setting>

        <Setting title="Tracking">
          <Controller
            control={control}
            name="trackedDrugs"
            defaultValue={client.trackedClinicalsProducts}
            render={({ field, fieldState: { error } }) => (
              <LazyLoadMultiSelectList
                label="Tracked Drugs"
                help="Track PDL updates for each of these drugs"
                showSelectedInline
                lazyLoadRequest={async (searchTerm, page, rpp) => {
                  const result = await getProductLookups({ query: searchTerm, page, rpp });
                  return result as PaginationResult<Lookup>;
                }}
                {...field}
                error={error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="trackedClassifications"
            defaultValue={client.trackedClassifications}
            render={({ field, fieldState: { error } }) => (
              <LazyLoadMultiSelectList
                label="Tracked Classes"
                help="Track PDL updates for every drug in these classes"
                showSelectedInline
                lazyLoadRequest={async (searchTerm, page, rpp) => {
                  const result = await getClassifications({ query: searchTerm, page, rpp });
                  return result as PaginationResult<Lookup>;
                }}
                {...field}
                error={error?.message}
              />
            )}
          />
        </Setting>
      </SettingsList>

      <Box direction="row" justify="end" gap="small">
        <Button
          onClick={handleSubmit(onSave)}
          disabled={isPending || !isValid}
        >
          <Busy busy={false} content="Save" />
        </Button>
      </Box>
    </Box>
  );
};

type SubscriptionTabContentProps = {
  client: Client;
  subscription: Subscription;
};
