import { Text } from 'grommet';
import React, { ReactNode } from 'react';

import { ArtiaButtonLink } from '@/components/artia-button';
import { Lookup } from '@/types/lookup';
import { stepTherapyDisplay } from '@/utils/state-coverage';

type PaTypeCellProps = {
  paTypes?: Lookup[];
  stepTherapyCount?: number;
  hasAdditionalSteps?: boolean
  format: 'pa-type' | 'shorthand';
  type: 'link' | 'text';
  onClick?: () => void;
};

export const PaTypeCell = ({ paTypes, stepTherapyCount, hasAdditionalSteps, format, type, onClick }: PaTypeCellProps) => {
  let text: ReactNode = <>&mdash;</>;
  if (format === 'pa-type' && paTypes && paTypes.length > 0) {
    text = paTypes.map(it => it.label)?.join('; ');
  } else if (format === 'shorthand' && (paTypes?.length || stepTherapyCount)) {
    text = (
      <>
        {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
        {paTypes?.length > 0 ? 'PA' : null}
        {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
        {stepTherapyCount ? `${paTypes?.length > 0 ? ' + ' : ''}ST${stepTherapyDisplay(stepTherapyCount, hasAdditionalSteps)}` : null}
      </>
    );
  }

  if (type === 'link') {
    return <ArtiaButtonLink onClick={onClick}>{text}</ArtiaButtonLink>;
  }

  return <Text>{text}</Text>;
};
