import { SelectValidators } from '@shared/validators';
import { Box, Card, CardBody, FormField, Select } from 'grommet';
import React, { useEffect } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import { type WizardControlState } from '@/components/wizard';
import { Button } from '@/components-new/button';
import { BidType } from '@/features/bid-analysis/types/bid-analysis';
import { StateBenefit } from '@/types/benefit';
import { Pool } from '@/types/pool';
import { State } from '@/types/state';

import { useCreateBidAnalysis } from '../../create-bid-analysis.provider';
import { useCreateBidAnalysisForm } from '../../create-bid-analysis-form.provider';

export const BenefitsSelectionStep = ({ nextStep, previousStep }: BenefitsSelectionStepProps) => {
  const { states, pools, stateBenefits } = useCreateBidAnalysis();

  const { form: {
    // @ts-expect-error TS(2339): Property 'control' does not exist on type 'UseForm... Remove this comment to see the full error message
    control,
    // @ts-expect-error TS(2339): Property 'formState' does not exist on type 'UseFo... Remove this comment to see the full error message
    formState: { errors, isValid },
    // @ts-expect-error TS(2339): Property 'getValues' does not exist on type 'UseFo... Remove this comment to see the full error message
    getValues,
    // @ts-expect-error TS(2339): Property 'setValue' does not exist on type 'UseFor... Remove this comment to see the full error message
    setValue,
    // @ts-expect-error TS(2339): Property 'trigger' does not exist on type 'UseForm... Remove this comment to see the full error message
    trigger
  } } = useCreateBidAnalysisForm();

  const resetBenefits = () => {
    const initialBenefits = states.reduce((previous, current) => {
      return { ...previous, [current.code]: null };
    }, {});

    setValue('benefits', initialBenefits);
  };

  // If the benefits form hasn't been initialized, initialized them here.
  useEffect(() => {
    const benefits = getValues('benefits');
    if (Object.keys(benefits).length === 0) {
      resetBenefits();
    }

  }, [states]);

  const handleStateChange = ({ value }: { value: State }) => {
    setValue('state', value);
    resetBenefits();
    trigger();
  };

  const handlePoolChange = ({ value }: { value: Pool }) => {
    setValue('pool', value);
    resetBenefits();
    trigger();
  };

  const handleNext = () => {
    nextStep();
  };

  const handleBack = () => {
    previousStep();
  };

  const getBenefitStates = () => {
    const { bidType, state, pool } = getValues();
    if (bidType === 'State' && state) {
      return [state];
    }

    if (bidType === 'Pool' && pool) {
      return pool.states;
    }

    if (bidType === 'All States') {
      return states;
    }

    return [];
  };

  return (
    <Card pad="medium" width="large">
      <CardBody>
        {getValues('bidType') === 'Pool' && (
          <Controller
            control={control}
            name="pool"
            rules={{
              validate: SelectValidators.hasValue
            }}
            render={({ field: { value, onBlur } }) => (
              <FormField
                required
                htmlFor="pool"
                name="pool"
                label="Pool"
                error={errors.pool?.message}
              >
                <Select
                  value={value}
                  onBlur={onBlur}
                  onChange={handlePoolChange}
                  options={pools}
                  id="pool"
                  name="pool"
                  labelKey="name"
                  placeholder="Select a Pool"
                />
              </FormField>
            )}
          />
        )}
        {getValues('bidType') === 'State' && (
          <Controller
            control={control}
            name="state"
            rules={{
              validate: SelectValidators.hasValue
            }}
            render={({ field: { value, onBlur } }) => (
              <FormField
                required
                htmlFor="state"
                name="state"
                label="State"
                error={errors.state?.message}
              >
                <Select
                  value={value}
                  onBlur={onBlur}
                  onChange={handleStateChange}
                  options={states}
                  id="state"
                  name="state"
                  labelKey="name"
                  placeholder="Select a State"
                />
              </FormField>
            )}
          />
        )}
        <StateBenefitsSelectionGroup
          bidType={getValues('bidType')}
          states={getBenefitStates()}
          stateBenefits={stateBenefits}
        />
        <Box flex direction="row" margin={{ top: 'large' }} justify="end" gap="medium">
          <Button outline onClick={handleBack}>Back</Button>
          <Button disabled={!isValid} onClick={handleNext}>Next</Button>
        </Box>
      </CardBody>
    </Card>
  );
};

const StateBenefitsSelectionGroup = (
  {
    bidType,
    states,
    stateBenefits
  }: {
    bidType?: BidType,
    states?: State[],
    stateBenefits?: { [_: string]: StateBenefit[] }
  }) => {
  const { form: {
    // @ts-expect-error TS(2339): Property 'control' does not exist on type 'UseForm... Remove this comment to see the full error message
    control,
    // @ts-expect-error TS(2339): Property 'formState' does not exist on type 'UseFo... Remove this comment to see the full error message
    formState: { errors } ,
    // @ts-expect-error TS(2339): Property 'setValue' does not exist on type 'UseFor... Remove this comment to see the full error message
    setValue,
    // @ts-expect-error TS(2339): Property 'trigger' does not exist on type 'UseForm... Remove this comment to see the full error message
    trigger
  } } = useCreateBidAnalysisForm();

  if (!states || states.length === 0) return null;

  const handleBenefitChange = (name: string, value: StateBenefit) => {
    setValue(name as any, value);
    trigger();
  };

  return (
    <>
    {states.map((state, index) => (
      <StateBenefitsSelection
        key={index}
        control={control}
        errors={errors}
        index={index}
        // @ts-expect-error TS(2322): Type 'BidType | undefined' is not assignable to ty... Remove this comment to see the full error message
        bidType={bidType}
        state={state}
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        stateBenefits={stateBenefits[state.code] ?? []}
        onChange={handleBenefitChange}
      />
    ))}
    </>
  );
};

const StateBenefitsSelection = (
  {
    control,
    errors,
    index,
    bidType,
    state,
    stateBenefits,
    onChange
  } : {
    control: Control<any>,
    errors: FieldErrors<any>
    index: number,
    bidType: BidType,
    state: State,
    stateBenefits: StateBenefit[],
    onChange: (name: string, value: StateBenefit) => void
  }) => {
  const name = `benefits.${state.code}`;

  const handleChange = (value: StateBenefit) => onChange(name, value);

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: true
      }}
      render={({ field: { value, onBlur } }) => {
        return (
          <FormField
            required
            key={index}
            label={bidType === 'State' ? 'Benefit' : state.name}
            name={name}
            error={errors[name]?.message as string | undefined}
          >
            <Select
              value={value}
              onBlur={onBlur}
              onChange={({ value }) => handleChange(value)}
              name={name}
              options={stateBenefits}
              labelKey="name"
            />
          </FormField>
        );
      }}
    />
  );
};

type BenefitsSelectionStepProps = {
  stepName: string
} & WizardControlState;
