import { useAsync, UseAsyncStatus } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { Box, FormField, MaskedInput, TextInput } from 'grommet';
import React, { useEffect, useId } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Busy } from '@/components/busy';
import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import { Button } from '@/components-new/button';
import { useBidRecommendationsService } from '@/features/bid-analysis/api/bid-recommendations-service';
import { notifySuccess } from '@/lib/notification/notifications';

type AddPackagingDialogProps = {
  open: boolean
  bidAnalysisId: number;
  bidRecommendationId: number;
  onSubmitComplete: () => void;
  onClose: () => void;
};

type AddPackagingFormInputs = {
  ndc: string
  description: string
};

export const AddPackagingDialog = ({ open, ...rest }: AddPackagingDialogProps) => {
  return (
    <Dialog open={open} width="large">
      <AddPackagingForm {...rest} />
    </Dialog>
  );
};

const AddPackagingForm = (props: Omit<AddPackagingDialogProps, 'open'>) => {
  const {
    bidAnalysisId,
    bidRecommendationId,
    onSubmitComplete,
    onClose
  } = props;

  const formId = useId();
  const { handleError } = useErrorHandler();
  const { createHistoricalBidRecommendationPackaging } = useBidRecommendationsService();

  const {
    control,
    formState: { errors, isValid },
    handleSubmit
  } = useForm<AddPackagingFormInputs>({
    mode: 'all',
    defaultValues: {
      ndc: '',
      description: ''
    }
  });

  const createPackagingAsync = useAsync(createHistoricalBidRecommendationPackaging);
  const saving = createPackagingAsync.status === UseAsyncStatus.Pending;

  const submitDisabled = !isValid || saving;

  useEffect(() => {
    const { status, error } = createPackagingAsync;
    if (status === UseAsyncStatus.Pending || status === UseAsyncStatus.Idle) return;

    if (status === UseAsyncStatus.Error) {
      handleError(error, { title: 'Packaging Creation Failed', message: 'Unable to create Packaging for Historical Bid.' });

      return;
    }

    notifySuccess({ title: 'Success', message: 'Successfully created Packaging for Historical Bid.' });
    onSubmitComplete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPackagingAsync.status]);

  const handleFormSubmit = ({ ndc, description }: AddPackagingFormInputs) => {
    void createPackagingAsync.execute(bidAnalysisId, bidRecommendationId, { ndc, description });
  };

  return (
    <>
      {/* @ts-expect-error TS(2322): Type 'false | (() => void)' is not assignable to t... Remove this comment to see the full error message */}
      <DialogHeader title="Create Packaging" onClose={!saving && onClose}/>
      <DialogBody>
        <Box id={formId} as="form" fill="horizontal" gap="medium" onSubmit={handleSubmit((data) => handleFormSubmit(data))}>
          <Controller
            control={control}
            name="ndc"
            rules={{
              maxLength: 11,
              minLength: 11,
              required: true
            }}
            render={({ field: { ref, value, onChange, onBlur } }) => (
              <FormField
                name="ndc"
                htmlFor="ndc"
                label="NDC"
                error={errors.ndc?.message}
              >
                <MaskedInput
                  ref={ref}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  id="ndc"
                  name="ndc"
                  maxLength={11}
                  mask={[
                    { regexp: /^[0-9]{0,11}$/ }
                  ]}
                />
              </FormField>
            )}
          />
          <Controller
            control={control}
            name="description"
            rules={{
              maxLength: 100,
              required: true
            }}
            render={({ field: { ref, value, onChange, onBlur } }) => (
              <FormField
                name="description"
                htmlFor="description"
                label="Description"
                error={errors.description?.message}
              >
                <TextInput
                  ref={ref}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  id="description"
                  name="description"
                  maxLength={100}
                />
              </FormField>
            )}
          />
        </Box>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={onClose} disabled={saving}>
          Cancel
        </Button>
        <Button
          type="submit"
          form={formId}
          onClick={handleSubmit(handleFormSubmit)}
          disabled={submitDisabled}
        >
          <Busy busy={saving} content="Save" />
        </Button>
      </DialogActions>
    </>
  );
};
