import clsx from 'clsx';
import React from 'react';

type CommaSeparatedListProps = {
  items: string[];
  renderItem?: (item: string) => React.ReactNode;
};

/**
 * Renders a list of items separated by commas.
 *
 * @param items The items to render.
 * @param renderItem An optional render prop to control formatting the item.
 */
export const CommaSeparatedList = ({ items, renderItem }: CommaSeparatedListProps) => {
  return (
    <ul className="inline list-none">
      {items.map((item, index) => (
        <li key={item} className={clsx(
          'inline',
          index < (items.length - 1) && 'after:content-[",_"]'
        )}>{renderItem ? renderItem(item) : item}</li>
      ))}
    </ul>
  );
};
