import { SubmitHandler, useForm } from 'react-hook-form';

import { Button } from '@/components-new/button';
import { ButtonBar } from '@/components-new/button-bar';
import { SectionDescription, SectionHeading } from '@/components-new/section';
import { useUpdateNotificationSettings, useUserNotificationSettings } from '@/features/user-settings/api/api';
import { UserNotificationSettingsForm } from '@/features/user-settings/components/user-notification-settings-form';
import { NotificationSetting } from '@/features/user-settings/types/notification-setting';
import { notifyError, notifySuccess } from '@/lib/notification/notifications';

type NotificationSettingsForm = {
  notificationSettings: NotificationSetting[];
}

const UserNotificationSettingsPage = () => {
  const { data: userNotificationSettings } = useUserNotificationSettings();
  const { mutate: mutateNotificationSettings } = useUpdateNotificationSettings();

  const notificationSettingsForm = useForm<NotificationSettingsForm>();
  const { handleSubmit } = notificationSettingsForm;

  const onSubmit: SubmitHandler<NotificationSettingsForm> = (data) => {
    mutateNotificationSettings(data.notificationSettings, {
      onSuccess: () => {
        notifySuccess({ title: 'Success', message: 'Notification preferences updated.' });
      },
      onError: () => {
        notifyError({ title: 'Uh Oh', message: 'Notification preferences failed to update. Try again later.' });
      }
    });
  };

  return(
    <div className="mt-6 lg:mx-0 lg:max-w-3xl">
      <SectionHeading level={2}>Notifications</SectionHeading>
      <SectionDescription>
        Manage how you receive notifications.
      </SectionDescription>
      <UserNotificationSettingsForm notificationSettingsForm={notificationSettingsForm} userNotificationSettings={userNotificationSettings ?? []} />
      <ButtonBar>
        <Button color="secondary" onClick={handleSubmit(onSubmit)}>Save</Button>
      </ButtonBar>
    </div>
  );

};
export default UserNotificationSettingsPage;
