import { Box, DataTable, Text, Tip } from 'grommet';
import { UserAdmin } from 'grommet-icons';
import { toString } from 'lodash';
import React from 'react';
import {
  HiOutlineEllipsisHorizontal,
  HiOutlineLockClosed,
  HiOutlinePencil, HiOutlineTrash
} from 'react-icons/hi2';

import { TableEmptyPlaceholder } from '@/components/loading';
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu
} from '@/components-new/dropdown';
import { User } from '@/features/admin/types/user';
import { Client } from '@/features/clients';
import { isUserPrimaryAdmin } from '@/features/clients/clients-utils';
import DeleteClientUserPrompt from '@/features/clients/components/delete-client-user-prompt';
import MakePrimaryAdminPrompt from '@/features/clients/components/make-primary-admin-prompt';
import ResetPasswordPrompt from '@/features/clients/components/reset-password-prompt';
import { useNavigate } from '@/router';

export const ClientUsersTable = ({ users, client }: ClientUsersTableProps) => {
  const [userForDelete, setUserForDelete] = React.useState<User | null>(null);
  const [userToMakePrimaryAdmin, setUserToMakePrimary] = React.useState<User | null>(null);
  const [userToResetPassword, setUserToResetPassword] = React.useState<User | null>(null);

  const navigate = useNavigate();

  const handleEditUserClick = (user: User) => {
    navigate('/clients/:id/users/:userId/edit', { params: { id: toString(client.id), userId: toString(user.id) } });
  };

  const handleResetPasswordClick = (user: User) => {
    setUserToResetPassword(user);
  };

  const handleDeleteUserClick = (user: User) => {
    setUserForDelete(user);
  };

  const handleMakeUserPrimaryAdminClick = (user: User) => {
    setUserToMakePrimary(user);
  };

  return (
    <Box pad={{ bottom: 'medium' }}>
      <Box margin={{ top: 'small' }} gap="small">
        <DataTable
          primaryKey="id"
          columns={[
            {
              search: true,
              property: 'name',
              header: 'Name',
              render: (user) => (
                <Box direction="row" align="center" gap="small">
                  <Text>{user.name}</Text>
                  {isUserPrimaryAdmin(user, client) && (
                    <Tip content="Primary Admin">
                      <UserAdmin />
                    </Tip>
                  )}
                </Box>
              )
            },
            { property: 'email', header: 'Email', search: true, },
            {
              sortable: false,
              property: 'features',
              header: 'Features',
              size: 'medium',
              render: (user) => (
                user.premiumFeatures
                  .filter(feature => feature.isEnabled)
                  .map(feature => feature.name)
                  .join(', ')
              )
            },
            {
              sortable: false,
              property: 'actions',
              size: '4rem',
              render: (user) => (
                <>
                  <Dropdown>
                    <DropdownButton plain>
                      <HiOutlineEllipsisHorizontal />
                    </DropdownButton>
                    <DropdownMenu anchor="bottom">
                      <DropdownItem onClick={() => handleEditUserClick(user)}>
                        <HiOutlinePencil />
                        <DropdownLabel>Edit</DropdownLabel>
                      </DropdownItem>
                      <DropdownItem onClick={() => handleResetPasswordClick(user)}>
                        <HiOutlineLockClosed />
                        <DropdownLabel>Reset Password</DropdownLabel>
                      </DropdownItem>
                      <DropdownItem onClick={() => handleMakeUserPrimaryAdminClick(user)} disabled={isUserPrimaryAdmin(user, client)}>
                        <UserAdmin />
                        <DropdownLabel>Make Primary Admin</DropdownLabel>
                      </DropdownItem>
                      <DropdownDivider />
                      <DropdownItem onClick={() => handleDeleteUserClick(user)}>
                        <HiOutlineTrash />
                        <DropdownLabel>Delete</DropdownLabel>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </>
              )
            }
          ]}
          data={users}
          sortable
          step={10}
          paginate
          placeholder={
            users.length === 0 && (
              <Box fill>
                <TableEmptyPlaceholder content="No User data is available." />
              </Box>
            )
          }
        />
      </Box>
      {
        userForDelete && (
          <DeleteClientUserPrompt
            onClose={() => setUserForDelete(null)}
            user={userForDelete}
            client={client}
          />
        )
      }
      {
        userToMakePrimaryAdmin && (
          <MakePrimaryAdminPrompt
            onClose={() => setUserToMakePrimary(null)}
            user={userToMakePrimaryAdmin}
            client={client}
          />
        )
      }
      {
        userToResetPassword && (
          <ResetPasswordPrompt
            onClose={() => setUserToResetPassword(null)}
            user={userToResetPassword}
            client={client}
          />
        )
      }
    </Box>
  );
};

type ClientUsersTableProps = {
  client: Client;
  users: User[];
};
