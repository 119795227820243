import { hasRole, Role } from '@shared/auth';
import { Box, DataTable } from 'grommet';
import React from 'react';
import { useAuth } from 'react-oidc-context';

import { Timestamp } from '@/components/date-time';
import { TableEmptyPlaceholder, TableLoadingOverlay } from '@/components/loading';
import { TextLink } from '@/components-new/text';
import { BidAnalysisBase } from '@/features/bid-analysis/types/bid-analysis';
import {
  BID_ANALYSIS_STATUS_CLOSED,
  BID_ANALYSIS_STATUS_OPEN,
  BidAnalysisStatusType
} from '@/features/bid-analysis/types/bid-analysis-status-type';

import { BidAnalysesRowActionsCell } from './bid-analyses-row-actions-cell';

type BidAnalysesTableProps = {
  /**
   * Bid Analyses data to show.
   */
  data: BidAnalysisBase[];

  /**
   * Whether new data is loading.
   */
  loading: boolean;

  /**
   * Status of bid analyses the table is showing for.
   */
  forStatus: BidAnalysisStatusType;

  /**
   * Callback for when a bid analysis status is changed.
   */
  onChangeBidAnalysisStatus: (bidAnalysisId: number, status: BidAnalysisStatusType) => void;

  /**
   * Callback for when a bid analysis status is deleted.
   */
  onDeleteBidAnalysis: (bidAnalysisId: number) => void;
};

/**
 * Displays Bid Analyses in a table format.
 * @param props
 */
export const BidAnalysesTable = (props: BidAnalysesTableProps) => {
  const { user } = useAuth();
  // @ts-expect-error TS(2345): Argument of type 'User | null | undefined' is not ... Remove this comment to see the full error message
  const isAdmin = hasRole(user, Role.Administrator);

  const {
    data,
    forStatus,
    loading,
    onChangeBidAnalysisStatus,
    onDeleteBidAnalysis
  } = props;

  const handleCloseBidAnalysis = (bidAnalysisId: number) => {
    onChangeBidAnalysisStatus(bidAnalysisId, BID_ANALYSIS_STATUS_CLOSED);
  };

  const handleOpenBidAnalysis = (bidAnalysisId: number) => {
    onChangeBidAnalysisStatus(bidAnalysisId, BID_ANALYSIS_STATUS_OPEN);
  };

  const handleDeleteBidAnalysis = (bidAnalysisId: number) => {
    onDeleteBidAnalysis(bidAnalysisId);
  };

  return (
    <DataTable
      columns={[
        {
          property: 'name',
          header: 'Name',
          render: (bidAnalysis) => (
            <TextLink href={`/bid-analyses/${bidAnalysis.id}-${bidAnalysis.slug}`}>
              {bidAnalysis.name}
            </TextLink>
          )
        },
        {
          property: 'clientName',
          header: 'Client',
        },
        {
          property: 'drug.name',
          header: 'Drug',
        },
        {
          property: 'createdAt',
          header: 'Created At',
          render: (bidAnalysis) => <Timestamp value={bidAnalysis.createdAt} />
        },
        {
          property: 'createdBy',
          header: 'Created By',
          render: (bidAnalysis) => bidAnalysis.createdBy.name
        },
        {
          property: 'actions',
          header: '',
          align: 'center',
          size: '8rem',
          sortable: false,
          render: (row) =>
            (
              <BidAnalysesRowActionsCell
                forStatus={forStatus}
                bidAnalysisId={row.id}
                onReopenBidAnalysis={handleOpenBidAnalysis}
                onCloseBidAnalysis={handleCloseBidAnalysis}
                canDelete={isAdmin}
                onDeleteBidAnalysis={handleDeleteBidAnalysis}
              />
            )
        }
      ]}
      primaryKey="id"
      data={data}
      sortable
      step={10}
      paginate
      placeholder={
        (loading || data.length === 0) &&
        <Box fill>
          {loading && <TableLoadingOverlay />}
          {!loading && data.length === 0 && <TableEmptyPlaceholder content="No Bid Analysis data is available." />}
        </Box>
      }
    />
  );
};
