import { useAsync, UseAsyncStatus } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { Box, FormField, Spinner } from 'grommet';
import React, { KeyboardEventHandler, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { InlineEdit, NumericTextInput } from '@/components/form-controls';
import { useNetCostComparisonService } from '@/features/bid-analysis/api/net-cost-comparison-service';
import { NetCostComparisonRow, PackagingNetCostComparison } from '@/features/bid-analysis/types/net-cost-comparison';
import { useToggle } from '@/hooks/use-toggle';

import { CurrencyCell } from './currency-cell';

type PharmacyReimbursementUnitCellProps = {
  bidAnalysisId: number
  row: NetCostComparisonRow;
  singleState: boolean;
  onSaveComplete: (row: NetCostComparisonRow, ndc: PackagingNetCostComparison) => void;
};

export const PharmacyReimbursementUnitCell = (props: PharmacyReimbursementUnitCellProps) => {
  const {
    bidAnalysisId,
    row,
    singleState,
    onSaveComplete
  } = props;

  const { updateNetCostComparisonPackaging } = useNetCostComparisonService(bidAnalysisId);
  const { handleError } = useErrorHandler();

  const [editing, toggleEditing] = useToggle(false);
  const id = `${row.key}-pharmacy-reimbursement-unit`;

  const {
    control,
    reset,
    getValues,
    formState: { isDirty }
  } = useForm<{ pharmacyReimbursementUnit: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { pharmacyReimbursementUnit: row.pharmacyReimbursementUnit?.toString() ?? '' }
  });

  useEffect(() => {
    reset({ pharmacyReimbursementUnit: row.pharmacyReimbursementUnit?.toString() ?? '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row.pharmacyReimbursementUnit]);

  const handleFocus = () => {
    toggleEditing();

    setTimeout(() => {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      document.getElementById(id).focus();
    });
  };

  const updateNetCostComparisonPackagingAsync = useAsync(updateNetCostComparisonPackaging);
  const saving = updateNetCostComparisonPackagingAsync.status === UseAsyncStatus.Pending;

  useEffect(() => {
    const { status, error, value } = updateNetCostComparisonPackagingAsync;
    if (status === UseAsyncStatus.Pending || status === UseAsyncStatus.Idle) return;

    if (status === UseAsyncStatus.Error) {
      handleCancel();
      handleError(error, { title: 'Update Net Cost Comparison Failed', message: 'Unable to update Net Cost Comparison' });

      return;
    }

    onSaveComplete(row, value);
    reset({ pharmacyReimbursementUnit: value?.pharmacyReimbursementUnit?.toString() ?? '' });
    toggleEditing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateNetCostComparisonPackagingAsync.status]);

  const handleSave = async ({ pharmacyReimbursementUnit }: { pharmacyReimbursementUnit: string }) => {
    if (updateNetCostComparisonPackagingAsync.status === UseAsyncStatus.Pending) {
      return;
    }

    if (isDirty) {
      let value = parseFloat(pharmacyReimbursementUnit);
      if (isNaN(value)) {
        // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'number'.
        value = null;
      }

      void updateNetCostComparisonPackagingAsync.execute(row.bidAnalysisNetCostComparisonPackagingId!, { pharmacyReimbursementUnit: value });
    } else {
      handleCancel();
    }
  };

  const handleCancel = () => {
    reset();
    toggleEditing();
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      void handleSave(getValues());
    }

    if (event.key === 'Escape') {
      handleCancel();
    }
  };

  return (
    <Box width="8rem">
      <Controller
        control={control}
        name="pharmacyReimbursementUnit"
        rules={{
          required: true
        }}
        render={({ field: { value, onChange } }) => (
          <InlineEdit
            editing={editing}
            onFocus={handleFocus}
            readonlyComponent={
              <CurrencyCell
                row={row}
                // @ts-expect-error TS(2322): Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
                value={value != null ? parseFloat(value) : null}
                minimumFractionDigits={2}
                maximumFractionDigits={2}
                singleState={singleState}
                showTotal
              />
            }
            inputComponent={
              <FormField
                name="pharmacyReimbursementUnit"
                htmlFor={id}
              >
                <NumericTextInput
                  id={id}
                  value={value}
                  onChange={onChange}
                  onBlur={() => handleSave(getValues())}
                  onKeyDown={handleKeyDown}
                  name="pharmacyReimbursementUnit"
                  maxWholePlaces={6}
                  maxDecimalPlaces={6}
                  icon={saving ? <Spinner size="xsmall" color="brand" /> : undefined}
                  reverse
                />
              </FormField>
            }
          />
        )}
      />
    </Box>
  );
};
