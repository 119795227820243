import { LinkProps } from '@generouted/react-router/client';
import * as Headless from '@headlessui/react';
import React, { forwardRef } from 'react';

import { Link as RouterLink, Params, Path } from '@/router';

/**
 * Determine if the given URL is internal to the app based on origin.
 */
const isExternalUrl = (url: string) => {
  const link = new URL(url, window.location.href);
  return link.origin !== window.location.origin;
};

/**
 * Renders an anchor tag either routed internal to the app with path and parameter parsing or external as the raw link.
 */
export const Link = forwardRef(function Link(
  { href, ...props }: { href: string | LinkProps<Path, Params> } & Omit<LinkProps<Path, Params>, 'to'>,
  ref: React.ForwardedRef<HTMLAnchorElement>
) {
  const hrefString = typeof href === 'string' ? href : href.to;
  const hrefPropObject = typeof href === 'string' ? { to: href } : href;

  return (
    <Headless.DataInteractive>
      {isExternalUrl(hrefString) ?
        (<a {...props} ref={ref} href={hrefString} />) :
        (<RouterLink {...props} ref={ref} {...hrefPropObject as any} />)
      }
    </Headless.DataInteractive>
  );
});
