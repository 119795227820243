import { Box, FormField, TextInput } from 'grommet';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button, ButtonLoadingIcon } from '@/components-new/button';
import { Classification } from '@/features/classifications/types/classification';
import { ClassificationFormInputs } from '@/features/classifications/types/classification-form-inputs';

type ClassificationFormProps = {
  defaultValues?: Classification;
  saving: boolean;
  onSubmit: (value: ClassificationFormInputs) => void;
  onCancel: () => void;
};

export const ClassificationForm = (props: ClassificationFormProps) => {
  const {
    defaultValues = {
      name: ''
    },
    saving,
    onSubmit,
    onCancel
  } = props;

  const {
    control,
    formState: { errors, isValid },
    handleSubmit
  } = useForm<ClassificationFormInputs>({
    mode: 'all',
    defaultValues: {
      name: defaultValues.name
    }
  });

  const submitDisabled = !isValid || saving;

  return (
    <>
      <Box pad={{ vertical: 'medium' }}>
        <Controller
          control={control}
          name="name"
          rules={{
            maxLength: 255,
            required: true
          }}
          render={({ field: { ref, value, onChange, onBlur } }) => (
            <FormField
              name="name"
              htmlFor="name"
              label="Name"
              error={errors.name?.message}
            >
              <TextInput
                ref={ref}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                id="name"
                name="name"
                maxLength={100}
                placeholder="Classification Name"
              />
            </FormField>
          )}
        />
      </Box>

      <Box direction="row" justify="end" gap="small">
        <Button plain onClick={onCancel} disabled={saving}>
          Cancel
        </Button>
        <Button onClick={handleSubmit(onSubmit)} disabled={submitDisabled}>
          {saving ? (
            <>
              <ButtonLoadingIcon/>
              Saving...
            </>
          ) : (
            <>Save</>
          )}
        </Button>
      </Box>
    </>
  );
};
