import { Controller, UseFormReturn } from 'react-hook-form';

import { Checkbox, CheckboxField, CheckboxGroup } from '@/components-new/checkbox';
import { Label } from '@/components-new/fieldset';
import { Radio, RadioField, RadioGroup } from '@/components-new/radio';

import { NotificationSetting, NotificationSettingsForm } from '../types/notification-setting';
import { UserSetting, UserSettingsList } from './user-setting';

type UserNotificationSettingsFormProps = {
  userNotificationSettings: NotificationSetting[];
  notificationSettingsForm: UseFormReturn<NotificationSettingsForm>,
}

export const UserNotificationSettingsForm = ({
  userNotificationSettings,
  notificationSettingsForm
}: UserNotificationSettingsFormProps) => {
  const { control } = notificationSettingsForm;
  
  return (
    <UserSettingsList>
        {
          userNotificationSettings?.map((notificationSetting, index) => (
            <UserSetting key={index} title={notificationSetting.category}>
              <div className="flex-col space-y-6">
                <CheckboxGroup>
                  <Controller
                    name={`notificationSettings.${index}.categoryId`}
                    defaultValue={notificationSetting.categoryId}
                    control={control}
                    render={({ field }) => (<input name={field.name} type="hidden" value={field.value} />)}
                  />
                  <Controller
                    name={`notificationSettings.${index}.category`}
                    defaultValue={notificationSetting.category}
                    control={control}
                    render={({ field }) => (<input name={field.name} type="hidden" value={field.value} />)}
                  />
                  <Controller
                    name={`notificationSettings.${index}.receiveInCapsule`}
                    control={control}
                    defaultValue={notificationSetting.receiveInCapsule}
                    render={({ field: { name, value, onChange } }) => (
                      <CheckboxField>
                        <Checkbox
                          name={name}
                          checked={value}
                          onChange={(isChecked) => onChange(isChecked)}
                        />
                        <Label>Receive in Capsule</Label>
                      </CheckboxField>
                    )}
                  />
                   <Controller
                     name={`notificationSettings.${index}.receiveEmails`}
                     control={control}
                     defaultValue={notificationSetting.receiveEmails}
                     render={({ field: { name, value, onChange } }) => (
                       <CheckboxField>
                         <Checkbox
                           name={name}
                           checked={value}
                           onChange={(isChecked) => onChange(isChecked)}
                         />
                         <Label>Receive emails</Label>
                       </CheckboxField>
                     )}
                  />
                </CheckboxGroup>
                <Controller
                  name={`notificationSettings.${index}.priorityStatesOnly`}
                  control={control}
                  defaultValue={notificationSetting.priorityStatesOnly}
                  render={({ field: { name, onChange } }) => (
                    <RadioGroup className="flex-1"
                      name={name}
                      defaultValue={notificationSetting.priorityStatesOnly ? 'Priority' : 'All' }
                      onChange={(value) => onChange(value == 'Priority')}
                    >
                      <RadioField>
                        <Radio value="All" />
                        <Label>For all states</Label>
                      </RadioField>
                      <RadioField>
                        <Radio value="Priority" />
                        <Label>For priority states only</Label>
                      </RadioField>
                    </RadioGroup>
                  )}
                />
              </div>
            </UserSetting>
          ))
        }

      </UserSettingsList>
  );
}