import { useErrorHandler } from '@shared/errors';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { User } from '@/features/admin/types/user';
import { makeClientUserQueryKey } from '@/features/clients/api/use-client-user';
import { makeClientUsersQueryKey } from '@/features/clients/api/use-client-users';
import { useClientService } from '@/features/clients/api/use-clients-service';
import { CreateUpdateClientUserFormSchema } from '@/features/clients/models/create-update-client-user-form-schema';
import { notifySuccess } from '@/lib/notification/notifications';

export const useUpdateClientUser = () => {
  const { updateClientUser } = useClientService();
  const queryClient = useQueryClient();
  const { handleError } = useErrorHandler();

  return useMutation({
    mutationFn: ({ clientId, userId, form }: { clientId: number, userId: number, form: CreateUpdateClientUserFormSchema }) => updateClientUser(clientId, userId, form),
    onSuccess: () => {
      notifySuccess({ title: 'Saved', message: 'User updated successfully.' });
    },
    onMutate: ({ clientId, userId, form }) => {
      const queryKey = makeClientUserQueryKey(clientId, userId);
      const previousUser = queryClient.getQueryData<User>(queryKey);

      if (previousUser) {
        const updatedUser = {
          ...previousUser,
          email: form.email,
          name: form.name,
          premiumFeatures: previousUser.premiumFeatures
            .filter(feature => form.enabledFeatures.includes(feature.key))
            .map(feature => ({
              ...feature,
              isEnabled: true,
            }))
        };

        queryClient.setQueryData<User[]>(makeClientUsersQueryKey(clientId), (old) => (
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          old.map(user => {
            if (user.id === userId) return updatedUser;

            return user;
          })
        ));
        queryClient.setQueryData<User>(queryKey, () => (updatedUser));
      }

      return { previousUser };
    },
    onError: (error, { clientId, userId }, context) => {
      handleError(error, { title: 'Save Failed', message: 'Unable to update user.' });

      if (context?.previousUser) {
        queryClient.setQueryData<User>(makeClientUserQueryKey(clientId, userId), context.previousUser);
        // @ts-expect-error TS(2345): Argument of type '(old: User[] | undefined) => (Us... Remove this comment to see the full error message
        queryClient.setQueryData<User[]>(makeClientUsersQueryKey(clientId), (old) => (
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          old.map(user => {
            if (user.id === userId) return context.previousUser;

            return user;
          })
        ));
      }
    },
    onSettled: async (_, __, { clientId, userId }) => {
      await queryClient.invalidateQueries({ queryKey: makeClientUserQueryKey(clientId, userId) });
      await queryClient.invalidateQueries({ queryKey: makeClientUsersQueryKey(clientId) });
    }
  });
};
