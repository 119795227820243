import { zodResolver } from '@hookform/resolvers/zod';
import { ProtectedRoute } from '@shared/auth';
import { FormProvider, useForm } from 'react-hook-form';

import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import { Button } from '@/components-new/button';
import { useCreateCoverageTag } from '@/features/drugs/api/use-create-coverage-tag';
import { useDrug } from '@/features/drugs/api/use-drug';
import CreateUpdateCoverageTagForm from '@/features/drugs/components/create-update-coverage-tag-form';
import {
  CreateUpdateCoverageTagFormSchema,
  createUpdateCoverageTagFormSchema
} from '@/features/drugs/types/create-update-coverage-tag-form-schema';
import { useNavigate, useParams } from '@/router';

const NewCoverageTagPage = () => {
  const { id } = useParams('/drugs/:id/coverage-tags/new');
  const navigate = useNavigate();
  const { data: drug } = useDrug(parseInt(id));
  const {
    mutate: createCoverageTag,
    isPending: isCreatingCoverageTag,
  } = useCreateCoverageTag();

  const form = useForm<CreateUpdateCoverageTagFormSchema>({
    resolver: zodResolver(createUpdateCoverageTagFormSchema),
  });

  const handleDismiss = () => {
    navigate('/drugs/:id/coverage-tags', { params: { id } });
  };

  const handleSubmit = (value: CreateUpdateCoverageTagFormSchema) => {
    createCoverageTag(
      { drugId: parseInt(id), form: value },
      {
        onSuccess: () => {
          navigate('/drugs/:id/coverage-tags', { params: { id } });
        }
      }
    );
  };

  const isSubmitDisabled = !form.formState.isValid || isCreatingCoverageTag;

  return (
    <Dialog open={true} onClickOutside={handleDismiss} width="large">
      <DialogHeader title="Add Coverage Tag" onClose={handleDismiss}/>
      <DialogBody>
        <FormProvider {...form}>
          <CreateUpdateCoverageTagForm drug={drug} />
        </FormProvider>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={handleDismiss}>Cancel</Button>
        <Button onClick={form.handleSubmit(handleSubmit)} disabled={isSubmitDisabled}>Add</Button>
      </DialogActions>
    </Dialog>
  );
};

const NewCoverageTagPageContainer = () => {
  return (
    <ProtectedRoute policies={['canManageCoverageTags']}>
      <NewCoverageTagPage />
    </ProtectedRoute>
  );
};

export default NewCoverageTagPageContainer;
