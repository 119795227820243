// NEWS
import { useQuery } from '@tanstack/react-query';

import { Post } from '@/features/posts/types/post';
import { artiaProtectedApi } from '@/lib/api-client';

const getNewsItems = async (): Promise<Post[]> => {
  return artiaProtectedApi.get('news');
};

export const useNews = () => {
  return useQuery({
    queryKey: ['news-items'],
    queryFn: async () => getNewsItems()
  });
};

// SHOW News Item
const getNewsItem = async (postId: number): Promise<Post> => {
  return artiaProtectedApi.get(`news/${postId}`);
};

export const useNewsItem = (postId: number) => {
  return useQuery({
    queryKey: ['news-item', postId],
    queryFn: async () => getNewsItem(postId)
  });
};
