import { RequestOptions, useHttpClient } from '@shared/http';

import { CoverageTag } from '@/features/drugs/types/coverage-tag';
import { Drug } from '@/products';

import { ProductFormValue } from '../product-form.provider';

type ProductRequest = {
  name: string,
  displayAsBrand: boolean,
  genericName?: string,
  clientId: number
  classificationId?: number;
  newClassification?: string;
};

export const useProductsService = () => {
  const httpClient = useHttpClient();

  const getProduct = (id: number): Promise<Drug> => {
    return httpClient.get(`drugs/${id}`);
  };

  const createProduct = (form: ProductFormValue): Promise<any> => {
    const data: ProductRequest = {
      name: form.name,
      displayAsBrand: form.displayAsBrand,
      genericName: form.genericName,
      clientId: form.client?.id
    };

    if (form.classification) {
      if (form.classification.value) {
        data.classificationId = form.classification.value;
      } else {
        data.newClassification = form.classification.name;
      }
    }

    const options: RequestOptions<any> = {
      data
    };

    return httpClient.post('drugs', options);
  };

  const updateProduct = (id: number, form: ProductFormValue): Promise<any> => {
    const data: ProductRequest = {
      name: form.name,
      displayAsBrand: form.displayAsBrand,
      genericName: form.genericName,
      clientId: form.client?.id
    };

    if (form.classification) {
      if (form.classification.value) {
        data.classificationId = form.classification.value;
      } else {
        data.newClassification = form.classification.name;
      }
    }

    const options: RequestOptions<any> = {
      data
    };

    return httpClient.put(`drugs/${id}`, options);
  };

  const uploadDrugPackagingsFile = (file: File, productId: number): Promise<any> => {
    const data = new FormData();

    data.append('Upload', file);

    const options: RequestOptions<any> = {
      data
    };

    return httpClient.post(`drugs/${productId}/import-drug-packagings`, options);
  };

  const createCoverageTag = (drugId: number, data: Omit<CoverageTag, 'id'>) => {
    return httpClient.post(`drugs/${drugId}/coverage-tags`, { data });
  };

  const updateCoverageTag = (drugId: number, coverageTag: CoverageTag) => {
    return httpClient.put(`drugs/${drugId}/coverage-tags/${coverageTag.id}`, {
      data: coverageTag
    });
  };

  const deleteCoverageTag = (drugId: number, coverageTagId: number) => {
    return httpClient.delete(`drugs/${drugId}/coverage-tags/${coverageTagId}`);
  };

  return {
    getProduct,
    createProduct,
    updateProduct,
    uploadDrugPackagingsFile,
    createCoverageTag,
    updateCoverageTag,
    deleteCoverageTag,
  };
};
