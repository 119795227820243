import { useAsync, UseAsyncStatus } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { Box, ColumnConfig, DataTable } from 'grommet';
import { Checkmark, Close } from 'grommet-icons';
import React, { useEffect, useState } from 'react';
import { HiOutlineTrash } from 'react-icons/hi2';

import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import { TableEmptyPlaceholder } from '@/components/loading';
import { Button } from '@/components-new/button';
import { notifySuccess } from '@/lib/notification/notifications';

import { useStatesService } from '../../../api';
import { McoCarveOutMethod, StateDrugOrClass } from '../../../types';
import { AddMcoCarveOutForm } from './add-mco-carve-out-form';
import { ProviderMode } from './mco-carve-out-provider';

type McoCarveOutDialogProps = {
  mode: ProviderMode;
  open: boolean;
  onClose: () => void;
  mcoCarveOuts: StateDrugOrClass[];
  carveOutMethod: McoCarveOutMethod | null;
  stateName: string | null;
  stateCode: string | null;
  refreshCarveOuts: () => void;
};

export const McoCarveOutDialog = ({ mode, open, onClose, mcoCarveOuts, carveOutMethod, stateName, stateCode, refreshCarveOuts }: McoCarveOutDialogProps) => {
  const [rowConfirmingDelete, setRowConfirmingDelete] = useState<number | null>(null);
  const entityName = mode === ProviderMode.MCO_CARVE_OUTS ? 'MCO Carve Out' : 'PDL Exemption';

  const { handleError } = useErrorHandler();
  const { deleteMcoCarveOut, deletePdlExemption, createMcoCarveOut, createPdlExemption } = useStatesService();
  const deleteRequest = useAsync(mode === ProviderMode.MCO_CARVE_OUTS ? deleteMcoCarveOut : deletePdlExemption);
  useEffect(() => {
    const { status, error } = deleteRequest;
    if (status === UseAsyncStatus.Pending || status === UseAsyncStatus.Idle) return;

    if (status === UseAsyncStatus.Error) {
      setRowConfirmingDelete(null);
      handleError(error, { title: `Removal of ${entityName} failed` });

      return;
    }

    notifySuccess({ title: 'Success', message: `Successfully removed ${entityName}.` });
    setRowConfirmingDelete(null);
    refreshCarveOuts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRequest.status]);

  const createRequest = useAsync(mode === ProviderMode.MCO_CARVE_OUTS ? createMcoCarveOut : createPdlExemption);
  useEffect(() => {
    const { status, error } = createRequest;
    if (status === UseAsyncStatus.Pending || status === UseAsyncStatus.Idle) return;

    if (status === UseAsyncStatus.Error) {
      handleError(error, { title: `Creating ${entityName} failed` });

      return;
    }

    notifySuccess({ title: 'Success', message: `Successfully created ${entityName}.` });
    refreshCarveOuts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createRequest.status]);

  const loading = createRequest.status === UseAsyncStatus.Pending
    || deleteRequest.status === UseAsyncStatus.Pending;

  const handleClose = () => {
    setRowConfirmingDelete(null);
    onClose();
  };

  const carveOutsToShow = mcoCarveOuts.filter(x => x.discriminator === carveOutMethod);

  let noRowsMessage = null;
  let title = null;
  let columns: ColumnConfig<StateDrugOrClass>[] = [];
  switch (carveOutMethod) {
    case McoCarveOutMethod.DRUG:
      title = `Individual Drugs ${mode === ProviderMode.MCO_CARVE_OUTS ? 'Carved-Out from MCO' : 'Exempt from the PDL'}`;
      noRowsMessage = 'Drugs';
      columns = [
        {
          property: 'name',
          header: 'Drug Name',
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          render: (row) => row.clinicalsProduct.name
        },
        {
          property: 'class',
          header: 'Drug Class',
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          render: (row) => row.clinicalsProduct.classification?.name
        },
        {
          property: 'type',
          header: 'Drug Type',
          render: () => <>&mdash;</>
        }
      ];
      break;
    case McoCarveOutMethod.CLASS:
      title = `Classes ${mode === ProviderMode.MCO_CARVE_OUTS ? 'Carved-Out from MCO' : 'Exempt from the PDL'}`;
      noRowsMessage = 'Classes';
      columns = [
        {
          property: 'name',
          header: 'Class Name',
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          render: (row) => row.classification.name
        }
      ];
      break;
  }

  return (
    <Dialog open={open} width="large">
      {/* @ts-expect-error TS(2322): Type 'false | (() => void)' is not assignable to t... Remove this comment to see the full error message */}
      <DialogHeader title={`${stateName}: ${title}`} onClose={!loading && handleClose} />
      <DialogBody>
        <Box width="100%" gap="small">
          <AddMcoCarveOutForm
            mode={mode}
            // @ts-expect-error TS(2322): Type 'McoCarveOutMethod | null' is not assignable ... Remove this comment to see the full error message
            carveOutMethod={carveOutMethod}
            requestStatus={createRequest.status}
            // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
            onSubmit={(carveOutMethod, associationIdList) => createRequest.execute(stateCode, carveOutMethod, associationIdList)}
            // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
            stateCode={stateCode}
          />
          <DataTable
            data={carveOutsToShow}
            columns={[
              ...columns,
              {
                property: 'delete',
                header: '',
                align: 'end',
                render: (row) => (
                  <DeleteWithConfirmButton
                    onDelete={() => setRowConfirmingDelete(row.id)}
                    onConfirm={() => deleteRequest.execute(row.id)}
                    onCancel={() => setRowConfirmingDelete(null)}
                    isConfirming={row.id === rowConfirmingDelete}
                    disabled={loading}
                  />
                )
              }
            ]}
            placeholder={
              carveOutsToShow.length === 0 ? (
                <Box fill>
                  <TableEmptyPlaceholder content={`No Carved Out ${noRowsMessage} for ${stateName}.`} />
                </Box>
              ) : null
            }
          />
        </Box>
      </DialogBody>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type DeleteWithConfirmButtonProps = {
  onDelete: () => void;
  onConfirm: () => void;
  onCancel: () => void;
  isConfirming: boolean;
  disabled?: boolean;
};

const DeleteWithConfirmButton = ({ onDelete, onConfirm, onCancel, isConfirming, disabled }: DeleteWithConfirmButtonProps) => {
  if (isConfirming) {
    return (
      <Box direction="row" gap="xxsmall">
        <Button
          plain
          title="Confirm"
          aria-label="Confirm"
          disabled={disabled}
          onClick={() => onConfirm()}
        >
          <Checkmark color="accent-1" size="medium" />
        </Button>
        <Button
          plain
          title="Cancel"
          aria-label="Cancel"
          disabled={disabled}
          onClick={() => onCancel()}
        >
          <Close color="accent-1" size="medium" />
        </Button>
      </Box>
    );
  }

  return (
    <Button
      plain
      title="Delete"
      aria-label="Delete"
      disabled={disabled}
      onClick={() => onDelete()}
    >
      <HiOutlineTrash/>
    </Button>
  );
};
