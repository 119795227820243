import clsx from 'clsx';
import React from 'react';

const styles = {
  // base
  base: 'absolute inset-0 flex items-center justify-center z-10',
  transparent: 'bg-white/70 dark:bg-zinc-950/70',
  solid: 'bg-white dark:bg-zinc-950'
};

type OverlayProps = {
  solid?: boolean,
} & React.ComponentPropsWithoutRef<'div'>;

/**
 * A component that creates an overlay by rendering its children inside a div
 * element with a semi-transparent white background.
 *
 * Usage:
 * Should be used inside a container with position of relative.
 */
const Overlay = (
  {
    children,
    className,
    solid = false,
    ...rest
  } : OverlayProps
) => {
  return (
    <div
      className={clsx(
        className,
        styles.base,
        solid ? styles.solid : styles.transparent
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

export { Overlay };
