import { queryOptions, useQuery } from '@tanstack/react-query';

import { DrugCoverage } from '@/features/drugs/types/drug-coverage';
import { artiaProtectedApi } from '@/lib/api-client';
import { QueryConfig } from '@/lib/react-query';

const getDrugCoverages = (drugId: number): Promise<DrugCoverage[]> => {
  return artiaProtectedApi.get(`drugs/${drugId}/coverages`);
};

export const makeDrugCoveragesQueryKey = (drugId: number) => ['drug-coverages', drugId];

export const getDrugCoveragesOptions = (drugId: number) => {
  return queryOptions({
    queryKey: makeDrugCoveragesQueryKey(drugId),
    queryFn: () => getDrugCoverages(drugId)
  });
};

type UseDrugCoveragesOptions = {
  drugId: number;
  queryConfig?: QueryConfig<typeof getDrugCoveragesOptions>
};

export const useDrugCoverages = ({ drugId, queryConfig }: UseDrugCoveragesOptions) => {
  return useQuery({
    ...getDrugCoveragesOptions(drugId),
    ...queryConfig
  });
};
