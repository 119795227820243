import { PaCriteriaDialog } from '@shared/pa-info/pa-criteria-dialog';
import { PaTypeCell } from '@shared/pa-info/pa-type-cell';
import { SortDirection, SortingInfo } from '@shared/pagination';
import { Box, ColumnConfig, DataTable, Pagination } from 'grommet';
import React, { ReactNode, useState } from 'react';

import { Date } from '@/components/date-time';
import { TableEmptyPlaceholder, TableLoadingOverlay } from '@/components/loading';
import Nullish from '@/components/nullish';
import { TextLink } from '@/components-new/text';
import CoverageTagChip from '@/features/coverage/components/coverage-tag-chip';
import TrackedByCell from '@/features/coverage/components/index/tracked-by-cell';
import { StateCoverage } from '@/features/coverage/types/state-coverage';
import { stepTherapyDisplay } from '@/utils/state-coverage';

import { PdlStatusCell } from './pdl-status-cell';
import { getSortableCoverageTag } from '@/features/drugs/types/coverage-tag';

type CoverageTableProps = {
  data: StateCoverage[];
  loading: boolean;
  onSort: (sortData: SortingInfo) => void;
  sort: SortingInfo;
  page: number;
  step: number;
  totalItems: number;
  onPaginate: (newPage: number) => void;
  additionalColumns?: ColumnConfig<StateCoverage>[];
  emptyMessage: ReactNode;
};

export const CoverageTable = (props: CoverageTableProps) => {
  const {
    data,
    loading,
    onSort,
    sort,
    page,
    step,
    totalItems,
    onPaginate,
    additionalColumns = [],
    emptyMessage
  } = props;

  const [openedProduct, setOpenedProduct] = useState<StateCoverage | null>(null);

  return (
    <>
      <Box overflow="auto">
        <DataTable
          sortable
          sort={{
            property: sort.sortBy,
            direction: sort.sortDirection === SortDirection.ASCENDING ? 'asc' : 'desc',
            external: true
          }}
          onSort={({ property, direction }) => onSort({
            sortBy: property,
            sortDirection: direction === 'asc' ? SortDirection.ASCENDING : SortDirection.DESCENDING
          })}
          columns={[
            {
              property: 'state',
              header: 'State',
              render: (row) => row.state.name
            },
            {
              property: 'name',
              header: 'Drug Name',
              render: (row) => {
                return (
                  <Box direction="row" gap="medium" align="center">
                    {row.genericName ? (`${row.name} (${row.genericName})`) : row.name}
                    {row.coverageTags.map(tag => getSortableCoverageTag(tag))
                      .sort((a, b) => a.sortOrder - b.sortOrder)
                      .map(sortableTag =>
                      <CoverageTagChip key={sortableTag.coverageTag.id} coverageTag={sortableTag.coverageTag}/>
                    )}
                  </Box>
                );
              }
            },
            {
              property: 'trackedByClients',
              header: 'Tracked By',
              sortable: false,
              render: (row: StateCoverage) => <TrackedByCell row={row} />
            },
            {
              property: 'classification',
              header: 'Drug Class',
              render: (row) => row.classification?.label ?? <>&mdash;</>
            },
            {
              property: 'pdlStatus',
              header: 'PDL Status',
              render: (row) => (
                <div style={{ width: 'max-content' }}>
                  <PdlStatusCell pdlStatus={row.pdlStatus} />
                </div>
              )
            },
            {
              property: 'paType',
              header: 'PA Types',
              render: (row) => (
                <PaTypeCell
                  paTypes={row.paTypes}
                  stepTherapyCount={row.stepTherapyCount}
                  hasAdditionalSteps={row.hasAdditionalSteps}
                  format="pa-type"
                  type={row.paCriteriaDescription ? 'link' : 'text'}
                  onClick={() => setOpenedProduct(row)}
                />
              )
            },
            {
              property: 'stepTherapyCount',
              header: 'Step Therapy',
              render: ({ stepTherapyCount, hasAdditionalSteps }) => (
                <Nullish value={stepTherapyDisplay(stepTherapyCount, hasAdditionalSteps)} />
              )
            },
            {
              property: 'pdlStatusDate',
              header: 'PDL Status Date',
              render: (row) => {
                if (row.pdlLink && (row.pdlLink.startsWith('https://') || row.pdlLink.startsWith('http://'))) {
                  return (
                    <TextLink href={row.pdlLink} referrerPolicy="no-referrer" target="_blank">
                      {/* @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message */}
                      <Date value={row.pdlStatusDate} />
                    </TextLink>
                  );
                }
                // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                return <Date value={row.pdlStatusDate} />;
              }
            },
            {
              property: 'pdlStatusEffectiveDate',
              header: 'Status Change Effective Date',
              // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
              render: (row) => <Date value={row.pdlStatusEffectiveDate} />
            },
            {
              property: 'hasAutoPa',
              header: 'Has Auto PA?',
              render: (row) => row.hasAutoPa ? 'Yes' : 'No'
            },
            {
              property: 'notes',
              header: 'Has Notes?',
              render: (row) => row.notes && row.notes.length > 0 ? 'Yes' : 'No'
            },
            ...additionalColumns
          ]}
          primaryKey="id"
          data={data}
          placeholder={(loading || data.length === 0) ? (
            <Box fill>
              {loading && <TableLoadingOverlay />}
              {!loading && data.length === 0 ? (
                <TableEmptyPlaceholder content={<>{emptyMessage}</>} />
              ) : null}
            </Box>
          ) : null}
        />
        <PaCriteriaDialog
          open={openedProduct !== null}
          onClose={() => setOpenedProduct(null)}
          paTypes={openedProduct?.paTypes}
          stepTherapyCount={openedProduct?.stepTherapyCount}
          hasAdditionalSteps={openedProduct?.hasAdditionalSteps}
          paCriteriaDescription={openedProduct?.paCriteriaDescription}
          paCriteriaSourceUrl={openedProduct?.paCriteriaSourceUrl}
        />
      </Box>
      {totalItems > 0 ? (
        <Box direction="row" justify="center" pad={{ top: '20px' }}>
          <Pagination
            size="medium"
            page={page}
            step={step}
            numberItems={totalItems}
            onChange={({ page }) => onPaginate(page)}
          />
        </Box>
      ) : null}
    </>
  );
};
