import clsx from 'clsx';
import React from 'react';
import {
  HiCheckCircle,
  HiExclamationTriangle,
  HiInformationCircle,
  HiXCircle
} from 'react-icons/hi2';

export type AlertVariant = 'critical' | 'warning' | 'info' | 'success';

type AlertProps = {
  variant: AlertVariant;
  children: React.ReactNode;
};

export const Alert = ({ variant, children }: AlertProps) => {
  let Icon;
  if (variant === 'critical') {
    Icon = HiXCircle;
  } else if (variant === 'success') {
    Icon = HiCheckCircle;
  } else if (variant === 'warning') {
    Icon = HiExclamationTriangle;
  } else {
    Icon = HiInformationCircle;
  }

  return (
    <div data-variant={variant} className={clsx('group/root rounded-md p-4', 'data-[variant=critical]:bg-red-50 data-[variant=info]:bg-blue-50 data-[variant=success]:bg-green-50 data-[variant=warning]:bg-yellow-50')}>
      <div className="flex">
        <div className="shrink-0">
          <Icon aria-hidden="true" className="size-5 group-data-[variant=critical]/root:text-red-400 group-data-[variant=info]/root:text-blue-400 group-data-[variant=success]/root:text-green-400 group-data-[variant=warning]/root:text-yellow-400"/>
        </div>
        <div className="ml-3">
          {children}
        </div>
      </div>
    </div>
  );
};

export const AlertTitle = ({ children }: { children: React.ReactNode }) => {
  return (
    <h3 className="text-sm font-semibold group-data-[variant=critical]/root:text-red-800 group-data-[variant=info]/root:text-blue-800 group-data-[variant=success]/root:text-green-800 group-data-[variant=warning]/root:text-yellow-800">{children}</h3>
  );
};

export const AlertBody = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="mt-2 text-sm group-data-[variant=critical]/root:text-red-700 group-data-[variant=info]/root:text-blue-700 group-data-[variant=success]/root:text-green-700 group-data-[variant=warning]/root:text-yellow-700">{children}</div>
  );
};
