import * as Headless from '@headlessui/react';
import clsx from 'clsx';
import React, { forwardRef } from 'react';

import { TouchTarget } from '@/components-new/button';
import { Link } from '@/components-new/link';

const styles = {
  buttonGroupButton: {
    base: [
      // Base
      'relative isolate inline-flex items-center justify-center gap-x-2 first:rounded-l-lg last:rounded-r-lg border text-base/6 tracking-normal font-semibold font-sans',
      // Sizing
      'px-[calc(theme(spacing[3.5])-1px)] py-[calc(theme(spacing[2.5])-1px)] sm:px-[calc(theme(spacing.3)-1px)] sm:py-[calc(theme(spacing[1.5])-1px)] sm:text-sm/6',
      // Focus
      'focus:outline-none data-[focus]:outline data-[focus]:outline-2 data-[focus]:outline-offset-2 data-[focus]:outline-blue-500',
      // Disabled
      'data-[disabled]:opacity-50',
      // Icon
      '[&>[data-slot=icon]]:-mx-0.5 [&>[data-slot=icon]]:my-0.5 [&>[data-slot=icon]]:size-5 [&>[data-slot=icon]]:shrink-0 [&>[data-slot=icon]]:text-[--btn-icon] [&>[data-slot=icon]]:sm:my-1 [&>[data-slot=icon]]:sm:size-4 forced-colors:[--btn-icon:ButtonText] forced-colors:data-[hover]:[--btn-icon:ButtonText]',
    ],
    outline: [
      // Base
      'border-zinc-950/10 text-zinc-950 data-[active]:bg-zinc-950/[2.5%] data-[hover]:bg-zinc-950/[2.5%]',
      // Dark mode
      'dark:border-white/15 dark:text-white dark:[--btn-bg:transparent] dark:data-[active]:bg-white/5 dark:data-[hover]:bg-white/5',
      // Icon
      '[--btn-icon:theme(colors.zinc.500)] data-[active]:[--btn-icon:theme(colors.zinc.700)] data-[hover]:[--btn-icon:theme(colors.zinc.700)] dark:data-[active]:[--btn-icon:theme(colors.zinc.400)] dark:data-[hover]:[--btn-icon:theme(colors.zinc.400)]',
    ],
    solid: [
      // Optical border, implemented as the button background to avoid corner artifacts
      'border-transparent bg-[--btn-border]',
      // Dark mode: border is rendered on `after` so background is set to button background
      'dark:bg-[--btn-bg]',
      // Button background, implemented as foreground layer to stack on top of pseudo-border layer
      'before:absolute before:inset-0 before:-z-10 before:rounded-[calc(theme(borderRadius.lg)-1px)] before:bg-[--btn-bg]',
      // Drop shadow, applied to the inset `before` layer so it blends with the border
      'before:shadow',
      // Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
      'dark:before:hidden',
      // Dark mode: Subtle white outline is applied using a border
      'dark:border-white/5',
      // Shim/overlay, inset to match button foreground and used for hover state + highlight shadow
      'after:absolute after:inset-0 after:-z-10 after:rounded-[calc(theme(borderRadius.lg)-1px)]',
      // Inner highlight shadow
      'after:shadow-[shadow:inset_0_1px_theme(colors.white/15%)]',
      // White overlay on hover
      'after:data-[active]:bg-[--btn-hover-overlay] after:data-[hover]:bg-[--btn-hover-overlay]',
      // Dark mode: `after` layer expands to cover entire button
      'dark:after:-inset-px dark:after:rounded-lg',
      // Disabled
      'before:data-[disabled]:shadow-none after:data-[disabled]:shadow-none',
    ],
    colors: {
      primary: [
        'text-white [--btn-hover-overlay:theme(colors.white/10%)] [--btn-bg:theme(colors.primary.950)] [--btn-border:theme(colors.primary.950)]',
        '[--btn-icon:theme(colors.white)] data-[active]:[--btn-icon:theme(colors.white)] data-[hover]:[--btn-icon:theme(colors.white)]',
      ],
      secondary: [
        'text-white [--btn-hover-overlay:theme(colors.white/10%)] [--btn-bg:theme(colors.secondary.600)] [--btn-border:theme(colors.secondary.600)]',
        '[--btn-icon:theme(colors.secondary.300)] data-[active]:[--btn-icon:theme(colors.secondary.200)] data-[hover]:[--btn-icon:theme(colors.secondary.200)]',
      ],
    }
  }
};

/**
 * Just like buttons but grouped together, useful for closely related actions.
 */
export const ButtonGroup = ({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'div'>) => {
  return (
    <div {...props} className={clsx(className, 'relative z-0 inline-flex')} />
  );
};

type ButtonGroupButtonProps = (
  | { solid: boolean, color?: keyof typeof styles.buttonGroupButton.colors }
  | { solid?: false, color?: never }
)
  & { className?: string; children?: React.ReactNode }
  & (
    | Omit<Headless.ButtonProps, 'as' | 'className'>
    | Omit<React.ComponentPropsWithoutRef<typeof Link>, 'className'>
  );

export const ButtonGroupButton = forwardRef((
  { className, solid, color = 'primary', children, ...props }: ButtonGroupButtonProps,
  ref: React.ForwardedRef<HTMLElement>
) => {
  const classes = clsx(
    className,
    styles.buttonGroupButton.base,
    solid ? [...styles.buttonGroupButton.solid, ...styles.buttonGroupButton.colors[color]] : styles.buttonGroupButton.outline
  );

  return 'href' in props ? (
    <Link {...props} className={classes} ref={ref as React.ForwardedRef<HTMLAnchorElement>}>
      <TouchTarget>{children}</TouchTarget>
    </Link>
  ) : (
    <Headless.Button {...props} className={clsx(classes, 'cursor-pointer')} ref={ref}>
      <TouchTarget>{children}</TouchTarget>
    </Headless.Button>
  );
});
