import { UseAsyncResult, UseAsyncStatus } from '@shared/async/use-async';
import { useErrorHandler } from '@shared/errors';
import { Box, Text } from 'grommet';
import React, { useEffect } from 'react';

import { Busy } from '@/components/busy';
import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import { Button } from '@/components-new/button';
import { notifySuccess } from '@/lib/notification/notifications';

type DeleteConfirmationDialogProps<TExecuteParameters extends unknown[]> = {
  /**
   * Whether the dialog is open or not, required.
   */
  open: boolean;

  /**
   * The name of the thing being deleted, required. Used in the success/error notifications.
   */
  itemName: string;

  /**
   * The title to display in the dialog, optional. If not specified, a default
   * title will be used involving the item name.
   */
  title?: string;

  /**
   * The message to display in the dialog, optional. If not specified, a default
   * message will be used involving the item name.
   */
  message?: string;

  /**
   * Cancellation handler, required.
   */
  onCancel: () => void;

  /**
   * Handler to call when the deletion is complete, required.
   */
  onDeleteComplete: () => void;

  /**
   * The request to delete the record, required.
   */
  request: UseAsyncResult<TExecuteParameters, unknown>;

  /**
   * The parameters to pass to the delete request, required.
   */
  parameters: TExecuteParameters;
};

/**
 * Generic dialog to confirm the deletion of a record.
 */
export const DeleteConfirmationDialog = <TExecuteParameters extends unknown[]>({ open, itemName, title, message, onCancel, onDeleteComplete, request, parameters }: DeleteConfirmationDialogProps<TExecuteParameters>) => {
  const loading = request.status === UseAsyncStatus.Pending;
  const { handleError } = useErrorHandler();

  const confirmationTitle = title || `Delete ${itemName}`;
  const confirmationMessage = message || `Are you sure you want to delete this ${itemName}?`;

  useEffect(() => {
    const { status, error } = request;
    if (status === UseAsyncStatus.Pending || status === UseAsyncStatus.Idle) return;

    if (status === UseAsyncStatus.Error) {
      handleError(error, { title: `Failed to delete ${itemName}` });

      return;
    }

    notifySuccess({ title: 'Deleted successfully', message: `Successfully deleted ${itemName}.` });
    onDeleteComplete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request.status]);

  return (
    <Dialog open={open} width="large">
      <DialogHeader title={confirmationTitle} onClose={onCancel} />
      <DialogBody>
        <Box>
          <Text>{confirmationMessage}</Text>
        </Box>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={onCancel} disabled={loading}>Cancel</Button>
        <Button
          color="red"
          onClick={() => request.execute(...parameters)}
          disabled={loading}
        >
          <Busy busy={loading} content="Delete" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
