import { useAsync, UseAsyncStatus } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { PaginationRequest } from '@shared/pagination';
import { DateTimeValidators } from '@shared/validators';
import { useEffect, useState } from 'react';

import { useFilters } from '@/components/filters';
import { notifyError } from '@/lib/notification/notifications';

import { generateDefaultBoardMeetingFilters } from './generate-default-board-meeting-filters';
import { BoardMeetingFilterData, useBoardMeetingService } from './use-board-meeting-service';

/**
 * Hook to manage retrieving Board Members.
 */
export const useBoardMeetings = () => {
  const [filterData, setFilterData] = useFilters<BoardMeetingFilterData>(
    'board-meetings',
    generateDefaultBoardMeetingFilters()
  );
  const [pagination, setPagination] = useState<PaginationRequest>({
    page: 1,
    rpp: 25
  });
  const { getBoardMeetings } = useBoardMeetingService();
  const { value, status, error: getBoardMeetingsError, execute } = useAsync(getBoardMeetings, true);
  const { handleError } = useErrorHandler();

  // load initial
  useEffect(() => {
    if (DateTimeValidators.isValidDate(filterData.dateRangeBegin) || DateTimeValidators.isValidDate(filterData.dateRangeEnd)) {
      notifyError({ title: 'Start and End Date must be in the format MM/DD/YYYY', });
    }

    void execute({ ...filterData, ...pagination });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData, pagination]);

  useEffect(() => {
    if (status !== UseAsyncStatus.Error) return;

    handleError(getBoardMeetingsError, { title: 'Failed to load Board Meetings' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return {
    boardMeetings: value?.items ?? [],
    loading: status === UseAsyncStatus.Pending,
    refresh: execute,
    filterData,
    setFilterData,
    setPagination,
    page: value?.page ?? 1,
    resultsPerPage: value?.resultsPerPage ?? 25,
    total: value?.total ?? 0
  };
};
