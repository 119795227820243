import { useAsync, UseAsyncStatus } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import React, { useEffect, useId } from 'react';

import { Busy } from '@/components/busy';
import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import { Button } from '@/components-new/button';
import { notifySuccess } from '@/lib/notification/notifications';

import { useBoardMeetingService } from '../api';
import { BoardMeetingForm } from './board-meeting-form';

type BoardMeetingFormProps = {
  /**
   * Whether the dialog is open or not, required.
   */
  open: boolean;

  /**
   * Cancel event handler, required. Usually used to close the dialog w/o saving changes.
   */
  onCancel: () => void;

  /**
   * Submit-completion event handler, required. Usually used to close the dialog and refresh any data needed.
   */
  onSubmitComplete: () => void;
};

/**
 * Dialog that lets you create a new Board Meeting
 */
export const CreateBoardMeetingDialog = ({ open, onCancel, onSubmitComplete }: BoardMeetingFormProps) => {
  const formId = useId();

  const { createBoardMeeting } = useBoardMeetingService();
  const createAsync = useAsync(createBoardMeeting);
  const saving = createAsync.status === UseAsyncStatus.Pending;
  const { handleError } = useErrorHandler();

  useEffect(() => {
    const { status, error } = createAsync;
    if (status === UseAsyncStatus.Pending || status === UseAsyncStatus.Idle) return;

    if (status === UseAsyncStatus.Error) {
      handleError(error, { title: 'Failed to create Board Meeting' });
      return;
    }

    notifySuccess({ title: 'Successfully created Board Meeting' });
    onSubmitComplete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAsync.status]);

  return (
    <Dialog open={open} width="xlarge">
      {/* @ts-expect-error TS(2322): Type 'false | (() => void)' is not assignable to t... Remove this comment to see the full error message */}
      <DialogHeader title="Add Meeting" onClose={!saving && onCancel} />
      <DialogBody>
        <BoardMeetingForm id={formId} onSubmit={createAsync.execute} />
      </DialogBody>
      <DialogActions>
        <Button
          plain
          onClick={onCancel}
          disabled={saving}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          form={formId}
          disabled={saving}
        >
          <Busy busy={saving} content="Save" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
