import { featureManager } from '@shared/feature-flags/feature-manager';

/**
 * Access the application feature manager.
 */
export const useFeatures = () => {
  const { getFeatures, getFeature } = featureManager;

  return {
    getFeatures,
    getFeature
  };
};
