import React from 'react';

import { UseStepper } from '@/components-new/stepper.hooks';

type StepperProviderProps = {
  /**
   * The stepper to provide as context.
   */
  stepper: UseStepper,
  children?: React.ReactNode;
}

export const StepperContext = React.createContext<UseStepper | null>(null);

/**
 * Provides a stepper as context to its children.
 */
export const StepperProvider = ({ children, stepper }: StepperProviderProps) => {
  return (
    <StepperContext.Provider value={stepper}>
      {children}
    </StepperContext.Provider>
  );
};
