import { useErrorHandler } from '@shared/errors';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { makeClientUsersQueryKey } from '@/features/clients/api/use-client-users';
import { useClientService } from '@/features/clients/api/use-clients-service';
import { notifySuccess } from '@/lib/notification/notifications';

export const useDeleteClientUser = () => {
  const { deleteClientUser } = useClientService();
  const queryClient = useQueryClient();
  const { handleError } = useErrorHandler();

  return useMutation({
    mutationFn: ({ clientId, userId }: { clientId: number, userId: number }) => deleteClientUser(clientId, userId),
    onSuccess: () => {
      notifySuccess({ title: 'Saved', message: 'User was successfully deleted.' });
    },
    onError: (error) => {
      handleError(error, { title: 'Save Failed', message: 'Unable to delete user.' });
    },
    onSettled: async (_, __, { clientId }) => {
      await queryClient.invalidateQueries({ queryKey: makeClientUsersQueryKey(clientId) });
    }
  });
};
