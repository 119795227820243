import { useErrorHandler } from '@shared/errors';
import React, { createContext, useContext, useState } from 'react';

import { useClassificationService } from '@/features/classifications/api/use-classifications-service';
import { Classification } from '@/features/classifications/types/classification';

type ClassificationsContextValue = {
  classifications: Classification[];
  loadingClassifications: boolean;
  loadClassifications: () => void;
};

const DEFAULT_CONTEXT_VALUE: ClassificationsContextValue = {
  classifications: [],
  loadingClassifications: false,
  loadClassifications: () => null
};

const ClassificationsContext = createContext<ClassificationsContextValue>(DEFAULT_CONTEXT_VALUE);

// eslint-disable-next-line react-refresh/only-export-components
export const useClassifications = () => useContext<ClassificationsContextValue>(ClassificationsContext);

type ClassificationsProps = {
  children?: React.ReactNode
};

export const ClassificationsProvider = ({ children }: ClassificationsProps) => {
  const { getClassifications } = useClassificationService();
  const { handleError } = useErrorHandler();

  const [classifications, setClients] = useState<Classification[]>([]);
  const [loadingClassifications, setLoadingClassifications] = useState(false);

  const loadClassifications = async () => {
    setLoadingClassifications(true);

    try {
      const classifications = await getClassifications();
      setClients(classifications);
    } catch (ex) {
      handleError(
        ex as Error,
        {
          title: 'Failed to Load Classifications',
          message: 'We encountered an unexpected error while fetching Classifications. Please try again or contact an administrator.',
        }
      );
    } finally {
      setLoadingClassifications(false);
    }
  };

  const contextValue: ClassificationsContextValue = {
    classifications,
    loadingClassifications,
    loadClassifications
  };

  return (
    <ClassificationsContext.Provider value={contextValue}>
      {children}
    </ClassificationsContext.Provider>
  );
};
