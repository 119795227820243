import { useErrorHandler } from '@shared/errors';
import { FileDownload } from '@shared/http';
import { PaginationResult } from '@shared/pagination';
import { required } from '@shared/validators';
import * as DateValidators from '@shared/validators/date-time-validators';
import { Box, FormField, RadioButtonGroup, Text } from 'grommet';
import React, { useId } from 'react';
import { Control, Controller, useForm, useWatch } from 'react-hook-form';

import { Busy } from '@/components/busy';
import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import { BorderlessFormField, DateTextInput, LazyLoadMultiSelectList } from '@/components/form-controls';
import { MultiSelectOption } from '@/components/form-controls/multi-select-list';
import { Button } from '@/components-new/button';
import { useLookupsService } from '@/hooks/use-lookups-service';
import { Lookup } from '@/types/lookup';

import { useStatesService } from '../api';
import { ColumnConfigurationGroup } from '../types';

type ExportToCsvDialogProps = {
  open: boolean;
  onCancel: () => void;
  onExportCompletion: () => void;
  groupsToExport: ColumnConfigurationGroup[];
  statesToExport: string[];
};

export const ExportToCsvDialog = ({ open, onCancel, onExportCompletion, groupsToExport, statesToExport }: ExportToCsvDialogProps) => {
  const formId = useId();
  const { handleError } = useErrorHandler();
  const { exportStatesToCsv, exportHistoricalStateLivesToCsv, exportHistoricalContactsToCsv } = useStatesService();

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting, errors },
    reset,
    watch
  } = useForm<ExportToCsvFormData>({
    mode: 'onSubmit',
    defaultValues: {
      format: 'full',
      startDate: '',
      endDate: '',
      clinicalsProducts: [],
      classifications: []
    }
  });
  const format = watch('format');

  const handleClose = () => {
    reset();
    onCancel();
  };

  const exportToCsv = async ({ format, startDate, endDate, clinicalsProducts, classifications }: ExportToCsvFormData) => {
    try {
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'FileDownloa... Remove this comment to see the full error message
      let csv: FileDownload = null;

      if (format === 'full' || format === 'drug-report') {
        csv = await exportStatesToCsv(
          groupsToExport,
          statesToExport,
          clinicalsProducts?.map(option => option.id) ?? [],
          classifications?.map(option => option.id) ?? []
        );
      } else if (format === 'lives') {
        csv = await exportHistoricalStateLivesToCsv(statesToExport, startDate, endDate);
      } else if (format === 'contacts') {
        csv = await exportHistoricalContactsToCsv(statesToExport, startDate, endDate);
      } else {
        throw new Error(`Unexpected CSV export format of ${format}.`);
      }

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(csv.contents);
      link.download = csv.filename;
      link.click();
      reset();
      onExportCompletion();
    } catch (e) {
      handleError(
        e as Error,
        {
          title: 'Download Failed',
          message: 'We encountered an unexpected error while downloading your file. Please try again or contact an administrator.'
        }
      );
    }
  };

  return (
    <Dialog open={open} width="xlarge">
      {/* @ts-expect-error TS(2322): Type 'false | (() => void)' is not assignable to t... Remove this comment to see the full error message */}
      <DialogHeader title="Export" onClose={!isSubmitting && handleClose} />
      <DialogBody>
        <Box direction="column" gap="medium" as="form" id={formId} onSubmit={handleSubmit(exportToCsv)}>
          <Controller
            control={control}
            name="format"
            rules={{
              validate: {
                required: required('Format is a required field')
              }
            }}
            render={({ field: { value, onChange, onBlur, name } }) => (
              <>
                <Text as="label" margin="none" weight="bold">Format</Text>
                <BorderlessFormField required name="format" htmlFor={`${formId}-format`}>
                  <RadioButtonGroup
                    onChange={onChange}
                    onBlur={onBlur}
                    // @ts-expect-error TS(2322): Type '"lives" | "full" | "contacts" | "drug-report... Remove this comment to see the full error message
                    value={value}
                    direction="row"
                    name={name}
                    id={`${formId}-${name}`}
                    options={[
                      { value: 'full', label: 'Open Tabs' },
                      { value: 'lives', label: 'Historical State Lives' },
                      { value: 'contacts', label: 'Historical State Contacts' },
                      { value: 'drug-report', label: 'Drug Report (includes open tabs)' }
                    ]}
                  />
                </BorderlessFormField>
              </>
            )}
          />
          {format === 'lives' || format === 'contacts' ? (
            <Box direction="row" gap="medium">
              <Controller
                control={control}
                name="startDate"
                rules={{
                  validate: {
                    // @ts-expect-error TS(2322): Type '(value: string) => string | undefined' is no... Remove this comment to see the full error message
                    valid: DateValidators.isValidDate,
                  }
                }}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <FormField
                    width="50%"
                    name={name}
                    htmlFor={`${formId}-${name}`}
                    label="Start Date"
                    error={errors.startDate?.message}
                  >
                    <DateTextInput
                      name={name}
                      id={`${formId}-${name}`}
                      value={value}
                      onChange={({ target }) => onChange((target as any).value)}
                      onBlur={onBlur}
                    />
                  </FormField>
                )}
              />
              <Controller
                control={control}
                name="endDate"
                rules={{
                  validate: {
                    // @ts-expect-error TS(2322): Type '(value: string) => string | undefined' is no... Remove this comment to see the full error message
                    valid: DateValidators.isValidDate,
                  }
                }}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <FormField
                    width="50%"
                    name={name}
                    htmlFor={`${formId}-${name}`}
                    label="End Date"
                    error={errors.endDate?.message}
                  >
                    <DateTextInput
                      name={name}
                      id={`${formId}-${name}`}
                      value={value}
                      onChange={({ target }) => onChange((target as any).value)}
                      onBlur={onBlur}
                    />
                  </FormField>
                )}
              />
            </Box>
          ) : null}
          {format === 'drug-report' ? (
            <Box direction="column" gap="medium">
              <Text>
                The Drug Report will include all the columns in the currently-opened tabs and several columns for
                each of the drugs selected below.
              </Text>
              <ClinicalsProductSelect control={control} />
              <ClassificationSelect control={control} />
            </Box>
          ) : null}
        </Box>
      </DialogBody>
      <DialogActions>
        <Button
          plain
          onClick={handleClose}
          disabled={isSubmitting}
        >Cancel</Button>
        <Button
          disabled={!isValid || isSubmitting}
          form={formId}
          type="submit"
        >
          <Busy busy={isSubmitting} content="Export" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type SelectProps = {
  control: Control<ExportToCsvFormData>;
};

const ClinicalsProductSelect = ({ control }: SelectProps) => {
  const { getCoverageTags } = useLookupsService();

  const classifications = useWatch({
    name: 'classifications',
    control,
    defaultValue: []
  });

  const clinicalsProducts = useWatch({
    name: 'clinicalsProducts',
    control,
    defaultValue: []
  });

  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const required = classifications.length === 0 && clinicalsProducts.length === 0;

  return (
    <Controller
      control={control}
      name="clinicalsProducts"
      rules={{
        required: required ? 'At least 1 Drug is required if no Classes are selected' : false,
        deps: ['classifications']
      }}
      render={({ field, fieldState: { error } }) => (
        // @ts-expect-error TS(2322): Type '{ showSelectedInline: true; error: string | ... Remove this comment to see the full error message
        <LazyLoadMultiSelectList
          required={required}
          label="Drugs"
          {...field}
          showSelectedInline
          error={error?.message}
          lazyLoadRequest={(searchTerm, page, rpp) => getCoverageTags({ query: searchTerm, page, rpp })}
        />
      )}
    />
  );
};

const ClassificationSelect = ({ control }: SelectProps) => {
  const { getClassifications } = useLookupsService();

  const classifications = useWatch({
    name: 'classifications',
    control,
    defaultValue: []
  });

  const clinicalsProducts = useWatch({
    name: 'clinicalsProducts',
    control,
    defaultValue: []
  });

  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const required = classifications.length === 0 && clinicalsProducts.length === 0;

  return (
    <Controller
      control={control}
      name="classifications"
      rules={{
        required: required ? 'At least 1 Class is required if no Drugs are selected' : false,
        deps: ['clinicalsProducts']
      }}
      render={({ field, fieldState: { error } }) => (
        // @ts-expect-error TS(2322): Type '{ showSelectedInline: true; error: string | ... Remove this comment to see the full error message
        <LazyLoadMultiSelectList
          required={required}
          label="Classes"
          {...field}
          showSelectedInline
          error={error?.message}
          lazyLoadRequest={async (searchTerm, page, rpp) => {
            const result = await getClassifications({ query: searchTerm, page, rpp });
            return result as PaginationResult<Lookup>;
          }}
        />
      )}
    />
  );
};

type ExportToCsvFormData = {
  format: 'lives' | 'full' | 'contacts' | 'drug-report' | null;
  startDate?: string;
  endDate?: string;
  clinicalsProducts?: MultiSelectOption[];
  classifications?: MultiSelectOption[];
};
