import { useAsync } from '@shared/async';
import { PaginationResult } from '@shared/pagination';
import { Box, Card, CardBody, CheckBox, FileInput, Form, FormField, TextInput } from 'grommet';
import React, { useEffect, useMemo } from 'react';

import { ComboBox } from '@/components/combo-box';
import { LazyLoadSelectList } from '@/components/form-controls';
import { Button } from '@/components-new/button';
import { useLookupsService } from '@/hooks/use-lookups-service';
import { Lookup } from '@/types/lookup';

import { useProductForm } from './product-form.provider';

export const ProductForm = ({ onSubmit, onCancel, submitting }: { onSubmit: () => void, onCancel: () => void, submitting: boolean }) => {
  const { form, updateForm } = useProductForm();
  const { getClassifications, getClientLookups } = useLookupsService();
  const { value: classifications, execute: loadClassifications } = useAsync(getClassifications);

  useEffect(() => {
    void loadClassifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classificationOptions = useMemo(() => {
    const lookups = classifications as unknown as Lookup[] ?? [];
    return lookups.map(lookup => ({ value: lookup.id, name: lookup.label }));
  }, [classifications]);

  return (
    <Box direction="row" justify="center">
      <Card justify="center" width="902px" background="#fff">
        <CardBody pad="medium">
          <Box pad={{ vertical: 'medium' }}>
            <Form
              value={form}
              onChange={updateForm}
              validate="blur"
              messages={{ required: 'Value is required.' }}
            >
              <Box direction="column" gap="medium">
                <FormField
                  required
                  name="name"
                  htmlFor="name"
                  label="Name"
                >
                  <TextInput
                    id="name"
                    name="name"
                    maxLength={80}
                    placeholder="Drug Name"
                  />
                </FormField>

                <CheckBox
                  name="displayAsBrand"
                  label="Display as BRAND"
                />

                <FormField
                  name="genericName"
                  htmlFor="genericName"
                  label="Generic Name"
                >
                  <TextInput
                    id="genericName"
                    name="genericName"
                    placeholder="Generic Name"
                  />
                </FormField>

                <LazyLoadSelectList
                  name="client"
                  id="client"
                  label="Client (Optional)"
                  placeholder="Search for Clients"
                  value={form.client}
                  // @ts-expect-error TS(2345): Argument of type '(current: ProductFormValue) => {... Remove this comment to see the full error message
                  onChange={(value) => updateForm((current) => ({ ...current, client: value }))}
                  lazyLoadRequest={async (searchTerm, page, rpp) => {
                    const result = await getClientLookups({ query: searchTerm, page, rpp });
                    return result as PaginationResult<Lookup>;
                  }}
                  clear={{ label: 'Remove Client Association' }}
                />

                <FormField
                  label="Drug Class"
                  name="classification"
                >
                  <ComboBox
                    options={classificationOptions}
                    value={form.classification}
                    placeholder="Search for Drug Class"
                    onChange={(option) => updateForm({ ...form, classification: option } )}
                  />
                </FormField>

                <FormField
                  label="Gold Standard File (Optional)"
                  name="marketDetailsFile"
                >
                  <FileInput
                    multiple={false}
                    name="drugPackagingsFile"
                    accept=".csv"
                    messages={{ browse: 'Browse' }}
                  />
                </FormField>
              </Box>
            </Form>

            <Box direction="row" justify="end" align="center" gap="small" pad={{ top: '20px' }}>
              <Box>
                <Button plain onClick={onCancel}>Cancel</Button>
              </Box>
              <Box>
                <Button disabled={submitting || !form.name} onClick={onSubmit}>
                  Save
                </Button>
              </Box>
            </Box>
          </Box>
        </CardBody>
      </Card>
    </Box>
  );
};
