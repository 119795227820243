import { ProtectedRoute } from '@shared/auth';
import { Box, ColumnConfig, DataTable, Pagination } from 'grommet';
import React from 'react';

import { Date } from '@/components/date-time';
import { TableEmptyPlaceholder, TableLoadingOverlay } from '@/components/loading';
import { MonospaceText } from '@/components/monospace-text';
import { Page } from '@/components-new/page';
import { PageTitleRow } from '@/components-new/page-title-row';
import { Nadac as NadacModel } from '@/features/nadacs';
import { NadacsProvider, useNadacs } from '@/features/nadacs';
import { NadacFilterData, NadacFilters } from '@/features/nadacs/components/nadac-filters';
import { formatCurrency } from '@/utils/formatting';

const Nadac = () => {
  const { nadacs, searchInfo, searchNadacs, searchingNadacs } = useNadacs();

  React.useEffect(() => {
    searchNadacs({
      page: searchInfo.page,
      ndc: searchInfo.ndc,
      description: searchInfo.description,
      rpp: searchInfo.resultsPerPage
    });
  }, []);

  const onSearch = ({ ndc, description }: NadacFilterData) => {
    searchNadacs({
      ndc,
      description,
      // sb: hard coding this to 1 because whenever reapplying filters, we
      // should reset the page number so the user sees their new results.
      page: 1,
      rpp: searchInfo.resultsPerPage
    });
  };

  const onPageChange = ({ page }: { page: number }) => {
    searchNadacs({ page, ndc: searchInfo.ndc, description: searchInfo.description, rpp: searchInfo.resultsPerPage });
  };

  const columns: ColumnConfig<NadacModel>[] = [
    { property: 'ndc', header: 'NDC', size: '10rem', render: (nadac: NadacModel) => <MonospaceText>{nadac.ndc}</MonospaceText> },
    { property: 'description', header: 'Description' },
    { property: 'netCostPerUnit', header: 'NADAC', align: 'end', render: (nadac: NadacModel) => formatCurrency(nadac.netCostPerUnit, 4, 4) },
    { property: 'pricingUnit', header: 'Pricing Unit' },
    { property: 'effectiveDate', header: 'Effective Date', render: (nadac) => <Date value={nadac.effectiveDate} /> },
    { property: 'asOfDate', header: 'As Of Date', render: (nadac) => <Date value={nadac.asOfDate} /> }
  ];

  return (
    <Page
      title="NADAC"
      header={({ title }) => (
        <PageTitleRow title={title}/>
      )}
    >
      <Box pad={{ bottom: 'medium' }}>
        <Box margin={{ top: 'small' }} gap="small">
          <NadacFilters defaultValue={searchInfo} onSearch={onSearch} />

          <DataTable
            columns={columns}
            data={nadacs}
            placeholder={
              (searchingNadacs || nadacs.length === 0) &&
              <Box fill>
                {searchingNadacs && <TableLoadingOverlay />}
                {!searchingNadacs && nadacs.length === 0 && <TableEmptyPlaceholder content="No NADAC data is available." />}
              </Box>
            }
          />

          {nadacs.length > 0 && <Box direction="row" justify="center" pad={{ top: '20px' }}>
            <Pagination
              size="medium"
              page={searchInfo.page}
              step={searchInfo.resultsPerPage}
              numberItems={searchInfo.total}
              onChange={onPageChange}
            />
          </Box>}
        </Box>
      </Box>
    </Page>
  );
};

const NadacPage = () => {
  return (
    <NadacsProvider>
      <ProtectedRoute component={Nadac} policies={['isAnyArtiaUser']} />
    </NadacsProvider>
  );
};

export default NadacPage;
