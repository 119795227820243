import { Box } from 'grommet';
import React, { ReactNode } from 'react';

import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import { Button } from '@/components-new/button';

type ConfirmationDialogProps = {
  open: boolean;
  title: ReactNode;
  children: ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
};

export const ConfirmationDialog = ({ open, title, children, onCancel, onConfirm }: ConfirmationDialogProps) => {
  return (
    <Dialog open={open} width="large">
      <DialogHeader title={title} onClose={onCancel} />
      <DialogBody>
        <Box>
          {children}
        </Box>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={onCancel}>Cancel</Button>
        <Button onClick={onConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};
