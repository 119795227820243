import { useAsync, UseAsyncStatus } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { Box, RadioButtonGroup, Text } from 'grommet';
import React, { useId } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Busy } from '@/components/busy';
import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import { BorderlessFormField } from '@/components/form-controls';
import { Button } from '@/components-new/button';
import { ChangeLogReason, changeLogReasonOptions } from '@/features/changelog/types';
import { useCoverageService } from '@/features/coverage/api/use-coverage-service';
import { StateCoverage } from '@/features/coverage/types/state-coverage';
import { notifySuccess } from '@/lib/notification/notifications';

type ConfirmCoverageDeleteDialogProps = {
  coverageToDelete: StateCoverage | null;
  onDeleteComplete: () => void;
  onCancel: () => void;
};

type CoverageDeleteFormData = {
  reason: ChangeLogReason;
}

export const ConfirmCoverageDeleteDialog = ({ coverageToDelete, onDeleteComplete, onCancel }: ConfirmCoverageDeleteDialogProps) => {
  const formId = useId();
  const { handleError } = useErrorHandler();

  const {
    control,
    handleSubmit,
    reset
  } = useForm<CoverageDeleteFormData>({
    defaultValues: {
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'ChangeLogRe... Remove this comment to see the full error message
      reason: null
    }
  });

  const { deleteCoverage } = useCoverageService();
  const { execute, status } = useAsync(deleteCoverage);
  const loading = status === UseAsyncStatus.Pending;

  const handleClose = () => {
    reset();
    onCancel();
  };

  const handleDelete = async ({ reason }: CoverageDeleteFormData) => {
    try {
      await execute(coverageToDelete!.id, reason);

      notifySuccess({
        title: 'Coverage Deleted',
        message: `Successfully deleted ${coverageToDelete?.name} in ${coverageToDelete?.state.name}.`,
      });

      reset();
      onDeleteComplete();
    } catch (e) {
      handleError(
        e as Error,
        {
          title: 'Failed to Delete Coverage',
          message: `An unexpected error occurred while deleting ${coverageToDelete?.name} in ${coverageToDelete?.state.name}. Please try again or contact an administrator.`
        }
      );
    }
  };

  return (
    <Dialog open={coverageToDelete !== null}>
      <DialogHeader title="Delete Coverage" onClose={handleClose} />
      <DialogBody>
        <Box gap="small">
          <Text weight="bold">
            Are you sure you want to delete coverage for {coverageToDelete?.name} in {coverageToDelete?.state?.name}?
          </Text>
          <Text>All history in the Changelog will be kept, including this deletion.</Text>
          <form id={formId} onSubmit={handleSubmit(handleDelete)}>
            <Controller
              name="reason"
              control={control}
              rules={{ required: 'A reason is required to delete this Coverage record' }}
              render={({ field: { name, onChange, onBlur, value }, fieldState: { error } }) => (
                <Box>
                  <Text weight="bold">Reason for Deletion</Text>
                  <BorderlessFormField
                    name={name}
                    htmlFor={`${formId}-${name}`}
                    error={error?.message}
                  >
                    <RadioButtonGroup
                      name={name}
                      id={`${formId}-${name}`}
                      value={value}
                      options={changeLogReasonOptions.map((reason) => ({ ...reason, value: reason.id }))}
                      onChange={onChange}
                      onBlur={onBlur}
                      direction="row"
                    />
                  </BorderlessFormField>
                </Box>
              )}
            />
          </form>
        </Box>
      </DialogBody>
      <DialogActions>
        <Button
          plain
          onClick={handleClose}
          disabled={loading}
        >Cancel</Button>
        <Button
          color="red"
          type="submit"
          form={formId}
          disabled={loading}
        >
          <Busy content="Delete" busy={loading} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
