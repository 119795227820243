import React from 'react';

import { StepperContext } from '@/components-new/stepper';

type OnNextStep = (() => Promise<boolean>) | (() => boolean) | null;

export type UseStepper = {
  /**
   * Registers a callback for when nextStep is called. It will be unregistered when navigating away from the step..
   */
  onNextStep: (callback: OnNextStep) => void;

  /**
   * Navigates to the next step.
   */
  nextStep: () => Promise<void>;

  /**
   * Navigates to the previous step.
   */
  previousStep: () => void;

  /**
   * The index of the current step.
   */
  currentStepIndex: number;

  /**
   * The total number of steps in the process.
   */
  totalSteps: number;

  /**
   * Whether the current step is the first step.
   */
  isFirstStep: boolean;

  /**
   * Whether the current step is the last step.
   */
  isLastStep: boolean;

  /**
   * Whether the stepper is loading.
   */
  isLoading: boolean;
}

export const useStepperContext = () => {
  const context = React.useContext(StepperContext);

  if (!context) {
    throw new Error('useStepperContext must be used within `StepperProvider`.');
  }

  return context;
};

type UseStepperOptions = {
  /**
   * The step index to start at.
   */
  initialStepIndex?: number;
  /**
   * The number of steps in the process.
   */
  numberOfSteps: number;
}

/**
 * Manages the state of a multi-step process and provides methods to navigate between steps.
 */
export const useStepper = ({ initialStepIndex = 0, numberOfSteps }: UseStepperOptions) => {
  const [currentStepIndex, setCurrentStepIndex] = React.useState(initialStepIndex);
  const [isLoading, setIsLoading] = React.useState(false);

  const onNextStepRef = React.useRef<OnNextStep | null>(null);

  const onNextStep = React.useRef<UseStepper['onNextStep']>((callback) => {
    onNextStepRef.current = callback;
  });

  const isFirstStep = React.useMemo(() => {
    return currentStepIndex === 0;
  }, [currentStepIndex]);

  const isLastStep = React.useMemo(() => {
    return currentStepIndex >= numberOfSteps - 1;
  }, [numberOfSteps, currentStepIndex]);

  const goToNextStep = React.useCallback(async () => {
    try {
      let shouldContinue = true;
      if (onNextStepRef.current) {
        setIsLoading(true);
        shouldContinue = await onNextStepRef.current?.();
        setIsLoading(false);
      }

      if (!isLastStep && shouldContinue) {
        onNextStepRef.current = null;
        setCurrentStepIndex(currentStepIndex + 1);
      }
    } catch(error) {
      setIsLoading(false);
      throw error;
    }
  }, [currentStepIndex, isLastStep]);

  const goToPreviousStep = React.useCallback(() => {
    onNextStepRef.current = null;
    if (!isFirstStep) {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  }, [currentStepIndex, isFirstStep]);

  return React.useMemo(
    (): UseStepper => ({
      totalSteps: numberOfSteps,
      onNextStep: onNextStep.current,
      nextStep: goToNextStep,
      previousStep: goToPreviousStep,
      currentStepIndex,
      isFirstStep,
      isLastStep,
      isLoading
    }),
    [
      numberOfSteps,
      goToNextStep,
      goToPreviousStep,
      currentStepIndex,
      isFirstStep,
      isLastStep,
      isLoading
    ]);
};
