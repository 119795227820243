import { FormField, TextInput } from 'grommet';
import React, { useId } from 'react';
import { Control, Controller } from 'react-hook-form';
import styled from 'styled-components';

import { BoardMeetingFormData } from './types';

type VirtualMeetingLocationFormProps = {
  control: Control<BoardMeetingFormData>;
};

/**
 * Wrapper around the fields needed for the virtual meeting location portion of the BoardMeetingForm.
 * Only really used to reduce noise in the main file.
 */
export const VirtualMeetingLocationForm = ({ control }: VirtualMeetingLocationFormProps) => {
  const id = useId();

  return (
    <VirtualLocation>
      <Controller
        name="webinarLink"
        control={control}
        render={({ field: { ref, value, onChange, onBlur, name }, fieldState: { error } }) => (
          <FormField
            name={name}
            htmlFor={`${id}-${name}`}
            label="Webinar Link"
            error={error?.message}
            margin="none"
          >
            <TextInput
              id={`${id}-${name}`}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
              value={value}
              ref={ref}
            />
          </FormField>
        )}
      />
      <Controller
        name="webinarPhone"
        control={control}
        render={({ field: { ref, value, onChange, onBlur, name }, fieldState: { error } }) => (
          <FormField
            name={name}
            htmlFor={`${id}-${name}`}
            label="Webinar Phone"
            error={error?.message}
            margin="none"
          >
            <TextInput
              id={`${id}-${name}`}
              type="tel"
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
              value={value}
              ref={ref}
            />
          </FormField>
        )}
      />
      <Controller
        name="webinarMeetingId"
        control={control}
        render={({ field: { ref, value, onChange, onBlur, name }, fieldState: { error } }) => (
          <FormField
            name={name}
            htmlFor={`${id}-${name}`}
            label="Webinar Meeting ID"
            error={error?.message}
            margin="none"
          >
            <TextInput
              id={`${id}-${name}`}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
              value={value}
              ref={ref}
            />
          </FormField>
        )}
      />
      <Controller
        name="webinarPassword"
        control={control}
        render={({ field: { ref, value, onChange, onBlur, name }, fieldState: { error } }) => (
          <FormField
            name={name}
            htmlFor={`${id}-${name}`}
            label="Webinar Password"
            error={error?.message}
            margin="none"
          >
            <TextInput
              id={`${id}-${name}`}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
              value={value}
              ref={ref}
            />
          </FormField>
        )}
      />
    </VirtualLocation>
  );
};

const VirtualLocation = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: row;
  gap: 1rem;
`;
