import { useAsync } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import React, { useId } from 'react';

import { useStatesService } from '@/features/state-reports/api';
import { CustomStateReportForm, useStatesProvider } from '@/features/state-reports/components';
import { notifySuccess } from '@/lib/notification/notifications';

const NewReportPage = () => {
  const formId = useId();
  const { createNewCustomReport } = useStatesService();
  const createRequest = useAsync(createNewCustomReport);

  const { handleError } = useErrorHandler();

  const { reloadColumnGroups } = useStatesProvider();

  return (
    <CustomStateReportForm
      id={formId}
      heading="New Report"
      onSubmit={async ({ name, columns }) => {
        try {
          await createRequest.execute(name, columns);
          notifySuccess({ title: 'Custom state report created' });
          reloadColumnGroups();
        } catch (error) {
          handleError(error as Error, { title: 'Failed to create custom state report' });
          throw error;
        }
      }}
    />
  );
};

export default NewReportPage;
