import { ProtectedRoute } from '@shared/auth';
import { Box, ColumnConfig, DataTable, Pagination, Text } from 'grommet';
import React from 'react';

import { TableEmptyPlaceholder, TableLoadingOverlay } from '@/components/loading';
import { MonospaceText } from '@/components/monospace-text';
import { Page } from '@/components-new/page';
import { PageTitleRow } from '@/components-new/page-title-row';
import { DrugUtilizationsProvider, useDrugUtilizations } from '@/features/drug-utilization';
import { DrugUtilization } from '@/features/drug-utilization';
import { DrugUtilizationFilterData, DrugUtilizationFilters } from '@/features/drug-utilization/components/drug-utilization-filters';
import { formatCurrency, formatNumber } from '@/utils/formatting';

const DrugUtilizations = () => {
  const {
    drugUtilizations,
    searchInfo,
    pagination,
    searchDrugUtilizations,
    searchingDrugUtilizations,
    loadLookups
  } = useDrugUtilizations();

  React.useEffect(() => {
    searchDrugUtilizations(searchInfo);
    // TODO: revisit, missing deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    loadLookups();
    // TODO: revisit, missing deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = ({ ndc, product, state, year }: DrugUtilizationFilterData) => {
    searchDrugUtilizations({
      year: year ?? undefined,
      state: state ?? undefined,
      ndc: ndc ?? undefined,
      product: product ?? undefined,
      // sb: hard coding this to 1 because whenever reapplying filters, we
      // should reset the page number so the user sees their new results.
      page: 1,
      rpp: pagination.resultsPerPage
    });
  };

  const onPageChange = ({ page }: { page: number }) => {
    searchDrugUtilizations({
      page,
      year: searchInfo.year,
      state: searchInfo.state,
      ndc: searchInfo.ndc,
      product: searchInfo.product,
      rpp: pagination.resultsPerPage
    });
  };

  const columns: ColumnConfig<DrugUtilization>[] = [
    { property: 'ndc', header: 'NDC', size: '10rem', render: (item) => <MonospaceText>{item.ndc}</MonospaceText> },
    { property: 'productName', header: 'Product', render: (item) => item.productName ? item.productName : <>&mdash;</> },
    { property: 'state', header: 'State', render: (item) => item.state.name },
    { property: 'period', header: 'Quarter', render: (item) => item.period.label },
    { property: 'utilizationType', header: 'Utilization Type' },
    {
      property: 'unitsReimbursed',
      header: '# of Units',
      align: 'end',
      render: (item) => {
        if (item.isSuppressionUsed) {
          return <Text>Suppressed</Text>;
        }

        return item.unitsReimbursed ? formatNumber(item.unitsReimbursed) : <>&mdash;</>;
      }
    },
    {
      property: 'numberOfPrescriptions',
      header: '# of Prescriptions',
      align: 'end',
      render: (item) => {
        if (item.isSuppressionUsed) {
          return <Text>Suppressed</Text>;
        }

        return item.numberOfPrescriptions ? formatNumber(item.numberOfPrescriptions) : <>&mdash;</>;
      }
    },
    {
      property: 'totalAmountReimbursed',
      header: 'Total Reimbursement',
      align: 'end',
      render: (item) => {
        if (item.isSuppressionUsed) {
          return <Text>Suppressed</Text>;
        }

        return item.totalAmountReimbursed ? formatCurrency(item.totalAmountReimbursed) : <>&mdash;</>;
      }
    },
    {
      property: 'medicaidAmountReimbursed',
      header: 'Medicaid Reimbursement',
      align: 'end',
      render: (item) => {
        if (item.isSuppressionUsed) {
          return <Text>Suppressed</Text>;
        }

        return item.medicaidAmountReimbursed ? formatCurrency(item.medicaidAmountReimbursed) : <>&mdash;</>;
      }
    },
    {
      property: 'nonMedicaidAmountReimbursed',
      header: 'Non-Medicaid Reimbursement',
      align: 'end',
      render: (item) => {
        if (item.isSuppressionUsed) {
          return <Text>Suppressed</Text>;
        }

        return item.nonMedicaidAmountReimbursed ? formatCurrency(item.nonMedicaidAmountReimbursed) : <>&mdash;</>;
      }
    }
  ];

  return (
    <Page
      title="Drug Utilizations"
      header={({ title }) => (
        <PageTitleRow title={title}/>
      )}
    >
      <Box pad={{ bottom: 'medium' }}>
        <Box margin={{ top: 'small' }} gap="small">
          <DrugUtilizationFilters defaultValue={searchInfo} onSearch={onSearch} />

          <div className="overflow-auto">
            <DataTable
              primaryKey="id"
              columns={columns}
              data={drugUtilizations}
              placeholder={
                (searchingDrugUtilizations || drugUtilizations.length === 0) &&
                <Box fill>
                  {searchingDrugUtilizations && <TableLoadingOverlay />}
                  {!searchingDrugUtilizations && drugUtilizations.length === 0 && <TableEmptyPlaceholder content="No Drug Utilization data is available." />}
                </Box>
              }
            />

            {drugUtilizations.length > 0 && <Box direction="row" justify="center" pad={{ top: '20px' }}>
              <Pagination
                size="medium"
                page={pagination.page}
                step={pagination.resultsPerPage}
                numberItems={pagination.total}
                onChange={onPageChange}
              />
            </Box>}
          </div>
        </Box>
      </Box>
    </Page>
  );
};

const DrugUtilizationsPage = () => {
  return (
    <DrugUtilizationsProvider>
      <ProtectedRoute component={DrugUtilizations} policies={['isAnyArtiaUser']} />
    </DrugUtilizationsProvider>
  );
};

export default DrugUtilizationsPage;
