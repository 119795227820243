import React from 'react';
import { IconContext } from 'react-icons';

export const ReactIconsProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <IconContext.Provider
      value={{
        style: { verticalAlign: 'middle', fontSize: '1rem' },
        attr: { 'data-slot': 'icon' } as any // forces data slot required for correct sizes in our UI kit
      }}
    >
      {children}
    </IconContext.Provider>
  );
};
