import { debounce } from 'lodash';
import React, { useRef } from 'react';

import { useUnmount } from '@/hooks/use-unmount';

/**
 * Configuration options for controlling the behavior of debounced function.
 */
type DebounceOptions = {
  /**
   * Whether the function should be invoked on the leading edge of the timeout.
   * Default: false
   */
  leading?: boolean;
  /**
   * Whether the function should be invoked on the trailing edge of the timeout.
   * Default: false
   */
  trailing?: boolean;
  /**
   * The Maximum time function is allowed to delay before it is invoked.
   */
  maxWait?: number;
}

/**
 * Functions to manage debounced callback.
 */
type ControlFunctions = {
  /**
   * Cancels pending function invocations.
   */
  cancel: () => void;
  /**
   * Immediately invokes pending function invocations.
   */
  flush: () => void;
  /**
   * Checks if there are any pending functions invocations.
   */
  isPending: () => boolean;
}

/**
 * State and control of debounced callback. Subsequent calls to the debounced function
 * return the result of the last invocation.
 * If there are no previous invocations, the result will be undefined.
 */
export type DebounceState<T extends (...args: any) => ReturnType<T>> = ((
  ...args: Parameters<T>
) => ReturnType<T> | undefined) &
  ControlFunctions

/**
 * Creates a debounced version of a callback function.
 * @param func - The callback function to be debounced.
 * @param delay - The delay in milliseconds before the callback is invoked.
 * @param options - Options to control the behavior of the debounced function.
 */
export const useDebounceCallback = <T extends (...args: any) => ReturnType<T>>(
  func: T,
  delay = 500,
  options?: DebounceOptions
): DebounceState<T> => {
  const debouncedFunc = useRef<ReturnType<typeof debounce>>();

  useUnmount(() => {
    if (debouncedFunc.current) {
      debouncedFunc.current.cancel();
    }
  });

  const debounced = React.useMemo(() => {
    const debouncedFuncInstance = debounce(func, delay, options);

    const wrappedFunc: DebounceState<T> = (...args: Parameters<T>) => {
      return debouncedFuncInstance(...args);
    };

    wrappedFunc.cancel = () => {
      debouncedFuncInstance.cancel();
    };

    wrappedFunc.isPending = () => {
      return !!debouncedFunc.current;
    };

    wrappedFunc.flush = () => {
      return debouncedFuncInstance.flush();
    };

    return wrappedFunc;
  }, [func, delay, options]);

  // update the debounced function ref whenever, func, wait or options change
  React.useEffect(() => {
    debouncedFunc.current = debounce(func, delay, options);
  }, [func, delay, options]);

  return debounced;
};
