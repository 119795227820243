import { Box, CheckBox, FormField, Text, TextInput, Tip } from 'grommet';
import { CircleInformation } from 'grommet-icons';
import { Controller, useFormContext } from 'react-hook-form';

import { User } from '@/features/admin/types/user';
import { Client } from '@/features/clients';
import { CreateUpdateClientUserFormSchema } from '@/features/clients/models/create-update-client-user-form-schema';

type Props = {
  client: Client;
  user?: User;
}

const CreateUpdateClientUserForm = ({ client, user }: Props) => {
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const featureOptions = client.subscription.premiumFeatures.map(feature => ({
    key: feature.key,
    name: feature.name,
    disabled: !feature.isEnabled
  }));

  const form = useFormContext<CreateUpdateClientUserFormSchema>();

  const getEnabledFeatures = (user: User) => {
    return user?.premiumFeatures
      ?.filter(feature => feature.isEnabled)
      .map(feature => feature.key);
  };

  return (
    <form>
      <Controller
        control={form.control}
        name="name"
        defaultValue={user?.name ?? ''}
        render={({ field, fieldState: { error } }) => (
          <FormField
            required
            name={field.name}
            label="Name"
            error={error?.message}
          >
            <TextInput {...field} />
          </FormField>
        )}
      />
      <Controller
        control={form.control}
        name="email"
        defaultValue={user?.email ?? ''}
        render={({ field, fieldState: { error } }) => (
          <FormField
            required
            name={field.name}
            label="Email"
            error={error?.message}
          >
            <TextInput {...field}/>
          </FormField>
        )}
      />
      <Controller
        control={form.control}
        name="enabledFeatures"
        // @ts-expect-error TS(2345): Argument of type 'User | undefined' is not assigna... Remove this comment to see the full error message
        defaultValue={getEnabledFeatures(user) ?? []}
        render={({ field }) => (
          <Box>
            <Text margin={{ vertical: 'small' }} weight="bold">Features</Text>
            <Box gap="small">
              {
                featureOptions.map(option => (
                  <Box direction="row" gap="small" align="center" key={option.key}>
                    <CheckBox
                      label={option.name}
                      disabled={option.disabled}
                      value={option.key}
                      checked={field.value.includes(option.key)}
                      onChange={({ target }) => target.checked
                        ? field.onChange([...field.value, target.value])
                        : field.onChange(field.value.filter((val: string) => val !== option.key))
                      }
                    />
                    {option.disabled && (
                      <Tip
                        content={`"${option.name}" is not enabled for this client.`}
                      >
                        <CircleInformation size="small"/>
                      </Tip>
                    )
                    }
                  </Box>
                ))
              }
            </Box>
          </Box>
        )}
      />
    </form>
  );
};

export default CreateUpdateClientUserForm;
