import { useErrorHandler } from '@shared/errors';
import React, { createContext, useContext, useState } from 'react';

import { notifyError } from '@/lib/notification/notifications';

import { BidAnalysis, useBidAnalysisService } from './index';

type BidAnalysisDetailsContextValue = {
  bidAnalysis?: BidAnalysis;
  loadingBidAnalysis: boolean;
  loadBidAnalysis: () => Promise<void>;
};

const DEFAULT_CONTEXT_VALUE: BidAnalysisDetailsContextValue = {
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'BidAnalysis... Remove this comment to see the full error message
  bidAnalysis: null,
  loadingBidAnalysis: false,
  loadBidAnalysis: () => Promise.resolve(),
};

const BidAnalysisDetailsContext = createContext<BidAnalysisDetailsContextValue>(DEFAULT_CONTEXT_VALUE);

// eslint-disable-next-line react-refresh/only-export-components
export const useBidAnalysisDetails = () => useContext<BidAnalysisDetailsContextValue>(BidAnalysisDetailsContext);

export const BidAnalysisDetailsProvider = ({ id, children }: BidAnalysisDetailsProps) => {
  const { getBidAnalysis } = useBidAnalysisService();
  const { handleError } = useErrorHandler();

  // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  const [bidAnalysis, setBidAnalysis] = useState<BidAnalysis>(null);
  const [loadingBidAnalysis, setLoadingBidAnalysis] = useState<boolean>(false);

  const loadBidAnalysis = async () => {
    setLoadingBidAnalysis(true);

    try {
      const bidAnalysis = await getBidAnalysis(id);
      setBidAnalysis(bidAnalysis);
    } catch (ex) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      if (ex.response && ex.response.status === 403) {
        notifyError({
          title: 'Access denied',
          message: 'You do not have access to view this bid analysis.',
        });
      } else {
        handleError(
          ex as Error,
          {
            title: 'Failed to Load Bid Analysis',
            message: 'We encountered an unexpected error while fetching Bid Analysis information. Please try again or contact an administrator.'
          }
        );
      }
    } finally {
      setLoadingBidAnalysis(false);
    }
  };

  const contextValue: BidAnalysisDetailsContextValue = {
    bidAnalysis,
    loadingBidAnalysis,
    loadBidAnalysis,
  };

  return (
    <BidAnalysisDetailsContext.Provider value={contextValue}>
      {children}
    </BidAnalysisDetailsContext.Provider>
  );
};

type BidAnalysisDetailsProps = {
  id: number;
  children?: React.ReactNode
};
