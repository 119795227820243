import { ThemeType } from 'grommet';
import { createGlobalStyle } from 'styled-components';

import { useThemeContext } from '@/styles/use-theme-context';

/**
 * Applies styles at the global level of the DOM.
 * Update this when you want to apply styles to the overall app outside an individual component.
 */
const GlobalStyle = createGlobalStyle<{ theme: ThemeType }>`
`;

export const ThemedGlobalStyle = () => {
  const theme = useThemeContext();
    return <GlobalStyle theme={theme} />;
};
