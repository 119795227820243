import { useMarkdown } from '@shared/markdown';
import { DateTime } from 'luxon';
import React from 'react';
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form';

import { useGetStates } from '@/api/use-states';
import { Button } from '@/components-new/button';
import { ButtonBar } from '@/components-new/button-bar';
import { Checkbox, CheckboxField, CheckboxGroup } from '@/components-new/checkbox';
import { Description, ErrorMessage, Field, FieldGroup, Fieldset, Label } from '@/components-new/fieldset';
import { Input } from '@/components-new/input';
import { RichTextEditorInput } from '@/components-new/rich-text-editor';
import { Select } from '@/components-new/select';
import { usePostCategories } from '@/features/posts/api';
import { CreateUpdatePostFormSchema } from '@/features/posts/types/create-post-form-schema';
import { Post } from '@/features/posts/types/post';
import { isPostPublished } from '@/features/posts/utils';

type CreateUpdatePostFormProps = {
  post?: Post;
  form: UseFormReturn<CreateUpdatePostFormSchema>;
  submitButtonLabel: string;
  isSubmitDisabled: boolean;
  onSubmit: () => void;
}

export const CreateUpdatePostForm = ({ post, form, submitButtonLabel, isSubmitDisabled, onSubmit }: CreateUpdatePostFormProps) => {
  const { data: stateOptions, isLoading: isLoadingStates } = useGetStates();
  const { data: postCategories, isLoading: isLoadingPostCategories } = usePostCategories();
  const { fromHtml, toHtml } = useMarkdown();

  return (
    <FormProvider {...form}>
      <form className="flex space-x-6">
        <div className="w-3/5">
          <Fieldset>
            <FieldGroup>
              <Controller
                control={form.control}
                name="title"
                defaultValue={post?.title ?? ''}
                render={({ field, fieldState: { error } }) => (
                  <Field>
                    <Label>Title</Label>
                    <Input
                      placeholder="Post title"
                      {...field}
                    />
                    {error && <ErrorMessage>{error?.message}</ErrorMessage>}
                  </Field>
                )}
              />

              <Controller
                control={form.control}
                name="content"
                defaultValue={post?.content ?? ''}
                render={({ field: { onChange, value, ...fieldRest }, fieldState: { error } }) => (
                  <Field>
                    <Label>Content</Label>
                    <RichTextEditorInput
                      className="h-96 max-h-96"
                      placeholder="Begin writing your post..."
                      onChange={(event) => onChange(fromHtml(event))}
                      value={toHtml(value)}
                      {...fieldRest}
                    />
                    {error && <ErrorMessage>{error?.message}</ErrorMessage>}
                  </Field>
                )}
              />

              <Controller
                control={form.control}
                name="summary"
                defaultValue={post?.summary ?? ''}
                render={({ field: { onChange, value, ...fieldRest }, fieldState: { error } }) => (
                  <Field>
                    <Label>Summary</Label>
                    <RichTextEditorInput
                      className="h-32 max-h-32"
                      placeholder="A brief summary of the post..."
                      onChange={(event) => onChange(fromHtml(event))}
                      value={toHtml(value)}
                      {...fieldRest}
                    />
                    {error && <ErrorMessage>{error?.message}</ErrorMessage>}
                  </Field>
                )}
              />
            </FieldGroup>
          </Fieldset>

          <ButtonBar>
            <Button href="/posts" plain>Cancel</Button>
            <Button
              color="secondary"
              disabled={isSubmitDisabled}
              onClick={onSubmit}
            >{submitButtonLabel}</Button>
          </ButtonBar>
        </div>

        <div className="w-2/5">
          <Fieldset>
            <FieldGroup>
              <Controller
                control={form.control}
                name="category"
                defaultValue={post?.category?.id?.toString()}
                render={({ field, fieldState: { error } }) => (
                  <Field>
                    <Label>Category</Label>
                    <Select {...field} onChange={(event) => {
                      field.onChange(event);
                    }}>
                      <option value="">Select a category...</option>
                      {!isLoadingPostCategories && (postCategories ?? []).map(category => (
                        <option key={category.id} value={category.id}>{category.label}</option>
                      ))}
                    </Select>
                    {error && <ErrorMessage>{error?.message}</ErrorMessage>}
                  </Field>
                )}
              />

              <Controller
                control={form.control}
                name="scheduledForPublicationOn"
                defaultValue={post?.scheduledForPublicationOn ?? ''}
                render={({ field, fieldState: { error } }) => (
                  <Field>
                    <Label>Publish Date (optional)</Label>
                    <Input
                      min={DateTime.now().toISODate()}
                      disabled={post && isPostPublished(post)}
                      readOnly={post && isPostPublished(post)}
                      type="date"
                      {...field}
                    />
                    {error && <ErrorMessage>{error?.message}</ErrorMessage>}
                  </Field>
                )}
              />

              <Controller
                control={form.control}
                name="targetStates"
                defaultValue={post?.targetStates ?? []}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Field>
                    <Label>Target Audience</Label>
                    <Description>Select the states that this post is relevant for or
                      leave all checkboxes blank to target all subscribers.</Description>

                    <CheckboxGroup role="group" aria-label="Target states" className="mt-6 sm:columns-2 md:columns-3">
                      {!isLoadingStates && stateOptions && stateOptions.map((stateOption) => (
                        <CheckboxField key={stateOption.code}>
                          <Checkbox
                            name="targetStates"
                            value={stateOption.code}
                            checked={value.includes(stateOption.code)}
                            onChange={(checked) => {
                              if (checked) {
                                onChange([...value, stateOption.code]);
                              } else {
                                onChange(value.filter(code => code !== stateOption.code));
                              }
                            }}
                          />
                          <Label>{stateOption.name}</Label>
                        </CheckboxField>
                      ))}
                    </CheckboxGroup>
                    {error && <ErrorMessage>{error?.message}</ErrorMessage>}
                  </Field>
                )}
              />
            </FieldGroup>
          </Fieldset>
        </div>
      </form>
    </FormProvider>
  );
};
