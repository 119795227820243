import { useHeaderDetails } from '@shared/header';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { HiOutlineArrowDownTray, HiOutlineArrowUpTray } from 'react-icons/hi2';

import { Button } from '@/components-new/button';
import { PageTitleRow } from '@/components-new/page-title-row';
import { ColumnGroupsResponse } from '@/features/state-reports/api/use-states-service';

import { ColumnConfiguration, ColumnConfigurationUsage } from '../types';
import { ExportToCsvDialog } from './export-to-csv-dialog';
import { OpenReportsMenu } from './open-reports-menu';
import { StateDataFilters, StateFilterData } from './state-data-filters';
import { StateDataTable } from './state-data-table';
import { StateDataTab } from './state-data-tabs';
import { StateImportDialog } from './state-import-dialog';
import { useStatesProvider } from './states-provider';

type ActiveStateViewProps = {
  allGroups?:  Record<string, { description: string, columnConfigurations: ColumnConfiguration[] }>;
  allCustomReports?: ColumnGroupsResponse['customReports'];
  allTabs: Omit<StateDataTab, 'active'>[];
  filterData: StateFilterData;
  onFilterChange: (filterData: StateFilterData) => void;
  activeTab: StateDataTab;
  openedTabs: StateDataTab[];
  setOpenedTabs: Dispatch<SetStateAction<StateDataTab[]>>;
}

export const ActiveStateView = ({ allGroups, allCustomReports, allTabs, activeTab, filterData, onFilterChange, openedTabs, setOpenedTabs }: ActiveStateViewProps) => {
  const { statesCache, states, loading, refresh } = useStatesProvider();
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const currentTabValue = statesCache[activeTab.key] ?? { columns: [], states: [] };

  const { allColumnConfigurations, openColumnConfigurations } = useMemo(() => {
    if (!allGroups) {
      return {
        allColumnConfigurations: [],
        openColumnConfigurations: []
      };
    }

    const allColumnConfigurations = Object.values(allGroups)
      .flatMap(({ columnConfigurations }) => columnConfigurations);
    const openColumnConfigurations: ColumnConfiguration[] = [];

    for (const openedTab of openedTabs) {
      const systemGroup = allGroups[openedTab.key];
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const customGroup = allCustomReports.find((report) => openedTab.key === `${report.id}`);

      if (systemGroup) {
        openColumnConfigurations.push(...systemGroup.columnConfigurations);
      } else if (customGroup) {
        openColumnConfigurations.push(
          ...Object.values(allGroups).flatMap(
            ({ columnConfigurations }) => columnConfigurations.filter(
              cc => customGroup.columns.some(c => c.id === cc.propertyName)
            )
          )
        );
      }
    }

    return { allColumnConfigurations, openColumnConfigurations };
  }, [allGroups, openedTabs, allCustomReports]);

  const { setHeaderDetails, clearHeaderDetails } = useHeaderDetails();
  useEffect(() => {
    setHeaderDetails({
      documentTitle: 'States',
      pageTitle: (
        <PageTitleRow title="States">
          <Button
            aria-label="Import from CSV"
            onClick={() => setImportDialogOpen(true)}
            disabled={loading}
          >
            <HiOutlineArrowUpTray/>
            Import from CSV
          </Button>
          <Button
            aria-label="Generate Report"
            onClick={() => setExportDialogOpen(true)}
            disabled={loading}
          >
            <HiOutlineArrowDownTray/>
            Generate Report
          </Button>
        </PageTitleRow>
      ),
      actions: <OpenReportsMenu allTabs={allTabs} openedTabs={openedTabs} setOpenedTabs={setOpenedTabs} />,
      breadcrumbs: []
    });
    return () => clearHeaderDetails();
    // TODO: revisit this, missing deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTabs, openedTabs, states, loading]);

  useEffect(() => {
    refresh(activeTab.key, filterData);
    // TODO: revisit this, missing deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab.key, filterData]);

  return (
    <>
      <StateDataTable activeTab={activeTab} columns={currentTabValue.columns} states={currentTabValue.states} loading={loading}>
        <StateDataFilters
          filterData={filterData}
          onFilterChange={onFilterChange}
          openColumnConfigurations={openColumnConfigurations}
        />
      </StateDataTable>
      <StateImportDialog
        open={importDialogOpen}
        columnConfigurations={allColumnConfigurations.filter((config) => config.usages.includes(ColumnConfigurationUsage.UPDATE))}
        onCancel={() => setImportDialogOpen(false)}
        onImportCompletion={() => {
          setImportDialogOpen(false);
          refresh(activeTab.key, filterData);
        }}
      />
      <ExportToCsvDialog
        open={exportDialogOpen}
        onCancel={() => setExportDialogOpen(false)}
        onExportCompletion={() => setExportDialogOpen(false)}
        groupsToExport={openedTabs.map((tab) => tab.key)}
        statesToExport={currentTabValue.states.map(state => state.code)}
      />
    </>
  );
};
