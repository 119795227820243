import { useAuthorization } from '@shared/auth/use-authorization';
import { Text } from 'grommet';
import React from 'react';

import { MonospaceText } from '@/components/monospace-text';
import { TextLink } from '@/components-new/text';
import { DrugPackaging } from '@/features/drugs/types/drug-packaging';
import { Drug } from '@/products';

type NdcCellProps = {
  product: Drug;
  packaging: DrugPackaging;
}

const NdcCell = ({ product, packaging }: NdcCellProps) => {
  const { checkPolicies } = useAuthorization();

  const canManagePackagings = checkPolicies(['canManageDrugPackagings']);

  return (
    canManagePackagings
      ? (
        <TextLink href={`/drugs/${product.id}/packagings/${packaging.ndc}`}>
          <MonospaceText>
            {packaging.ndc}
          </MonospaceText>
        </TextLink>
      )
      : (
        <Text>
          {packaging.ndc}
        </Text>
      )
  );
};

export default NdcCell;
