import { ValidationError } from '@shared/errors';
import React from 'react';

import { bugsnagClient } from '@/lib/bugsnag/bugsnag-client';
import { NotificationConfig, notifyError, notifyWarn } from '@/lib/notification/notifications';

import { ErrorHandlerContext, ErrorSeverity } from './error-handler.context';

export const ErrorHandlerProvider = ({ children }: ErrorHandlerProviderProps) => {

  const handleError = (ex: Error, notificationConfig: NotificationConfig, severity: ErrorSeverity = 'error') => {
    if (ex instanceof ValidationError) {
      if (severity === 'warn') {
        notifyWarn({
          title: ex.title,
          message: ex.message,
          ...notificationConfig
        });
      } else {
        notifyError({
          title: ex.title,
          message: ex.message,
          ...notificationConfig
        });
      }
    } else {
      bugsnagClient.notify(ex);
      if (severity === 'warn') {
        notifyWarn(notificationConfig);
      } else {
        notifyError(notificationConfig);
      }
    }
  };

  return (
    <ErrorHandlerContext.Provider value={{ handleError }}>
      {children}
    </ErrorHandlerContext.Provider>
  );
};

type ErrorHandlerProviderProps = {
  children?: React.ReactNode
};
