import { useErrorHandler } from '@shared/errors';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { CreateUpdatePostFormSchema } from '@/features/posts/types/create-post-form-schema';
import { Post } from '@/features/posts/types/post';
import { PostBase } from '@/features/posts/types/post-base';
import { artiaProtectedApi } from '@/lib/api-client';
import { notifySuccess } from '@/lib/notification/notifications';
import { IdBasedLookup } from '@/types/lookup';
import { coerceStringNull } from '@/utils/parsing';

type CreateUpdatePostRequest = {
  title: string;
  content: string;
  summary: string;
  categoryId: number;
  scheduledForPublicationOn?: string | null;
  targetStates: string[];
}

const mapCreateUpdatePostFormToRequest = (form: CreateUpdatePostFormSchema): CreateUpdatePostRequest => {
  return {
    title: form.title,
    content: form.content,
    summary: form.summary,
    categoryId: parseInt(form.category),
    scheduledForPublicationOn: coerceStringNull(form.scheduledForPublicationOn),
    targetStates: form.targetStates,
  };
};

// POST CATEGORIES
const getPostCategories = async (): Promise<IdBasedLookup[]> => {
  return artiaProtectedApi.get('lookups/post-categories');
};

const makePostCategoriesQueryKey = () => ['post-categories'];

export const usePostCategories = () => {
  return useQuery({
    queryKey: makePostCategoriesQueryKey(),
    queryFn: async () => getPostCategories()
  });
};

// POSTS
const getPosts = async (): Promise<PostBase[]> => {
  return artiaProtectedApi.get('posts');
};

const makePostsQueryKey = () => ['posts'];

export const usePosts = () => {
  return useQuery({
    queryKey: makePostsQueryKey(),
    queryFn: async () => getPosts()
  });
};

// SHOW POST
const getPost = async (postId: number): Promise<Post> => {
  return artiaProtectedApi.get(`posts/${postId}`);
};

const makePostQueryKey = (postId: number) => ['posts', postId];

export const usePost = (postId: number) => {
  return useQuery({
    queryKey: makePostQueryKey(postId),
    queryFn: async () => getPost(postId)
  });
};

// CREATE POST
const createPost = async (formInputs: CreateUpdatePostFormSchema): Promise<{ postId: number }> => {
  const payload = mapCreateUpdatePostFormToRequest(formInputs);
  const response = await artiaProtectedApi.post('posts', payload);

  return response.data;
};

export const useCreatePost = () => {
  const queryClient = useQueryClient();
  const { handleError } = useErrorHandler();

  return useMutation({
    mutationFn: ({ form }: { form: CreateUpdatePostFormSchema }) => createPost(form),
    onSuccess: () => {
      notifySuccess({ title: 'Saved', message: 'Post was successfully created.' });
    },
    onError: (error) => {
      handleError(error, { title: 'Save Failed', message: 'Unable to create post.' });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: makePostsQueryKey() });
    }
  });
};

// UPDATE POST
const updatePost = async (id: number, formInputs: CreateUpdatePostFormSchema): Promise<{ postId: number }> => {
  const payload = mapCreateUpdatePostFormToRequest(formInputs);
  console.log({ payload });
  const response = await artiaProtectedApi.put(`posts/${id}`, payload);

  return response.data;
};

export const useUpdatePost = (id: number) => {
  const queryClient = useQueryClient();
  const { handleError } = useErrorHandler();

  return useMutation({
    mutationFn: ({ form }: { form: CreateUpdatePostFormSchema }) => updatePost(id, form),
    onSuccess: () => {
      notifySuccess({ title: 'Saved', message: 'Post was successfully updated.' });
    },
    onError: (error) => {
      handleError(error, { title: 'Save Failed', message: 'Unable to update post.' });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: makePostsQueryKey() });
    }
  });
};

// SEND PREVIEW
export const sendPreview = async (postId: number): Promise<any> => {
  return artiaProtectedApi.post(`posts/${postId}/send-preview`);
};

// DELETE POST
const deletePost = async (postId: number): Promise<PostBase[]> => {
  return artiaProtectedApi.delete(`posts/${postId}`);
};

export const useDeletePost = () => {
  const queryClient = useQueryClient();
  const { handleError } = useErrorHandler();

  return useMutation({
    mutationFn: ({ postId }: { postId: number }) => deletePost(postId),
    onSuccess: () => {
      notifySuccess({ title: 'Deleted', message: 'The post was successfully deleted.' });
    },
    onError: (error) => {
      handleError(error, { title: 'Delete Failed', message: 'Unable to delete post.' });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: makePostsQueryKey() });
    }
  });
};
