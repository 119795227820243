import { useMarkdown } from '@shared/markdown';
import React, { useMemo, useState } from 'react';
import { LiaBell } from 'react-icons/lia';

import { Breadcrumbs } from '@/components-new/breadcrumbs';
import { Button } from '@/components-new/button';
import { Dialog, DialogActions, DialogBody, DialogTitle } from '@/components-new/dialog';
import { EmptyState, EmptyStateBody, EmptyStateHeading } from '@/components-new/empty-state';
import { Page } from '@/components-new/page';
import { PageTitleRow } from '@/components-new/page-title-row';
import { RichTextPreview } from '@/components-new/rich-text-editor';
import { useNotifications } from '@/features/notifications/api/use-notifications';
import { useUpdateNotifications } from '@/features/notifications/api/use-update-notification-status';
import { NotificationsTable } from '@/features/notifications/components/notifications-table';
import { Notification } from '@/features/notifications/types/notification';

const NotificationsPage = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentNotification, setCurrentNotification] = useState<Notification | undefined>(undefined);
  const { toHtml } = useMarkdown();

  const { data: notifications, isLoading: isLoadingNotifications } = useNotifications();
  const { mutate } = useUpdateNotifications();

  const [selectedNotifications, setSelectedNotifications] = useState<Notification[]>([]);

  const visibleNotifications = useMemo(() => {
    return notifications?.filter(notification => !notification.deleted);
  }, [notifications]);

  const onViewNotification = (notification: Notification) => {
    setCurrentNotification(notification);
    setDialogOpen(true);

    if (!notification.read) {
      mutate({ operation: 'read', ids: [notification.id] });
    }
  };

  const onDeleteSelected = () => {
    const ids = selectedNotifications.map((selectedNotification) => selectedNotification.id);

    mutate({ operation: 'delete', ids }, {
      onSuccess: () => setSelectedNotifications([])
    });
  };

  const onMarkSelectedAsRead = () => {
    const ids = selectedNotifications.map((selectedNotification) => selectedNotification.id);

    mutate({ operation: 'read', ids }, {
      onSuccess: () => setSelectedNotifications([])
    });
  };

  const onMarkSelectedAsUnread = () => {
    const ids = selectedNotifications.map((selectedNotification) => selectedNotification.id);

    mutate({ operation: 'unread', ids }, {
      onSuccess: () => setSelectedNotifications([])
    });
  };

  return (
    <Page
      title="Notifications"
      header={({ title }) => (
        <>
          <Breadcrumbs
            breadcrumbs={[
              { label: 'Notifications', url: '/notifications' },
            ]}
          />
          <PageTitleRow title={title}/>
        </>
      )}
    >
      <div className="relative mt-8">
        {isLoadingNotifications && (
          <div>Loading...</div>
        )}
        {visibleNotifications && (<NotificationsTable
          notifications={visibleNotifications}
          selectedNotifications={selectedNotifications}
          setSelectedNotifications={setSelectedNotifications}
          onViewNotification={onViewNotification}
          onDelete={onDeleteSelected}
          onMarkAsRead={onMarkSelectedAsRead}
          onMarkAsUnread={onMarkSelectedAsUnread}
        />)}

        {visibleNotifications && visibleNotifications.length < 1 && (
          <EmptyState>
            <LiaBell className="size-12 text-gray-500"/>
            <EmptyStateHeading>No notifications</EmptyStateHeading>
            <EmptyStateBody>
              You don't have any notifications right now. When we post news that you are subscribed to, we'll notify you here.
            </EmptyStateBody>
          </EmptyState>
        )}
      </div>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} size="5xl">
        <DialogTitle>{currentNotification?.title}</DialogTitle>
        <DialogBody className="text-sm/6 text-zinc-900 dark:text-white">
          {currentNotification?.content && (
            <RichTextPreview content={toHtml(currentNotification.content)} />
          )}
        </DialogBody>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default NotificationsPage;
