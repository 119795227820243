import { useAsync, UseAsyncStatus } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { Box, Spinner } from 'grommet';
import React, { useId, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { DeleteConfirmationDialog } from '@/components/delete-confirmation-dialog';
import { Button } from '@/components-new/button';
import { useStatesService } from '@/features/state-reports/api';
import { CustomStateReportForm } from '@/features/state-reports/components';
import { useStatesProvider } from '@/features/state-reports/components';
import { notifySuccess } from '@/lib/notification/notifications';
import NotFoundPage from '@/pages/404';

const EditReportPage = () => {
  const formId = useId();
  const { id } = useParams<'id'>();
  const { updateCustomReport, deleteCustomReport } = useStatesService();
  const updateRequest = useAsync(updateCustomReport);
  const deleteRequest = useAsync(deleteCustomReport);
  const isSubmitting = updateRequest.status === UseAsyncStatus.Pending
    || deleteRequest.status === UseAsyncStatus.Pending;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const {
    columnGroups: { customReports },
    loading,
    reloadColumnGroups
  } = useStatesProvider();

  const navigate = useNavigate();
  const { handleError } = useErrorHandler();

  if (loading) {
    return (
      <Box align="center" justify="center" fill>
        <Spinner size="large" />
      </Box>
    );
  }

  const report = customReports.find(
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    (report) => report.id === +id
  );

  if (!report) {
    return <NotFoundPage />;
  }

  return (
    <>
      <DeleteConfirmationDialog
        open={deleteModalOpen}
        itemName="State Report"
        onCancel={() => setDeleteModalOpen(false)}
        onDeleteComplete={() => {
          setDeleteModalOpen(false);
          reloadColumnGroups();
          navigate('/state-reports/manage-reports', { state: 'skipBlock' });
        }}
        request={deleteRequest}
        parameters={[report.id]}
      />
      <CustomStateReportForm
        id={formId}
        key={report.id}
        heading={`Edit Report: ${report.name}`}
        defaultValues={{
          name: report.name,
          columns: report.columns
        }}
        onSubmit={async ({ name, columns }) => {
          try {
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            await updateRequest.execute(+id, name, columns);
            notifySuccess({ title: 'Custom state report updated' });
            reloadColumnGroups();
          } catch (error) {
            handleError(error as Error, { title: 'Failed to update custom state report' });

            throw error;
          }
        }}
      >
        <Button
          disabled={isSubmitting}
          className="size-max"
          color="red"
          onClick={() => setDeleteModalOpen(true)}
        >
          Delete
        </Button>
      </CustomStateReportForm>
    </>
  );
};

export default EditReportPage;
