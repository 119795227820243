import { useAsync, UseAsyncStatus } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { Box, FormField, RadioButtonGroup, Text, TextArea, TextInput } from 'grommet';
import React, { useEffect, useId, useMemo } from 'react';
import { Control, useController, useForm } from 'react-hook-form';

import { Busy } from '@/components/busy';
import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import { BorderlessFormField, DateTextInput, LookupSelect, NumericTextInput } from '@/components/form-controls';
import { Button } from '@/components-new/button';
import { notifySuccess } from '@/lib/notification/notifications';
import { Lookup } from '@/types/lookup';

import { useStatesService } from '../api';
import { ColumnConfiguration, ColumnConfigurationType, ColumnConfigurationUsage, CustomState } from '../types';
import { StateDataTab } from './state-data-tabs';
import { useStatesProvider } from './states-provider';

type EditStateDataDialogProps = {
  activeTab: StateDataTab;
  editingState: CustomState | null;
  columnConfigurations: ColumnConfiguration[];
  onClose: () => void;
  onSubmitComplete: () => void;
};

export const EditStateDataDialog = ({ activeTab, editingState, columnConfigurations, onClose, onSubmitComplete }: EditStateDataDialogProps) => {
  const formId = useId();

  const formValues = useMemo(() => {
    if (!editingState) return null;

    const result: Record<string, string> = {};
    for (const columnConfiguration of columnConfigurations) {
      if (!columnConfiguration.usages.includes(ColumnConfigurationUsage.UPDATE)) continue;

      result[columnConfiguration.propertyName] = editingState.values[columnConfiguration.propertyName];
    }
    return result;
  }, [editingState, columnConfigurations]);

  const { control, handleSubmit, reset } = useForm({
    mode: 'all',
    // @ts-expect-error TS(2322): Type 'Record<string, string> | null' is not assign... Remove this comment to see the full error message
    defaultValues: formValues
  });

  useEffect(() => {
    // @ts-expect-error TS(2345): Argument of type 'Record<string, string> | null' i... Remove this comment to see the full error message
    reset(formValues);
  }, [formValues, reset]);

  const { updateStateData } = useStatesService();
  const updateRequest = useAsync(updateStateData);
  const saving = updateRequest.status === UseAsyncStatus.Pending;
  const { handleError } = useErrorHandler();

  const onSubmit = (updatedValues: Record<string, string>) => {
    const payload: Record<string, string> = {};
    for (const property in updatedValues) {
      const columnConfig = columnConfigurations.find(config => config.usages.includes(ColumnConfigurationUsage.UPDATE) && config.propertyName === property);
      if (!columnConfig) continue;

      payload[columnConfig.header] = updatedValues[property];
    }

    void updateRequest.execute(editingState!.code, payload);
  };

  useEffect(() => {
    const { status, error } = updateRequest;
    if (status === UseAsyncStatus.Pending || status === UseAsyncStatus.Idle) return;

    if (status === UseAsyncStatus.Error) {
      handleError(error, { title: `Failed to update ${editingState?.name}`, message: 'We encountered an unexpected error while saving your changes.' });

      return;
    }

    notifySuccess({ title: `Updated ${editingState?.name}'s ${activeTab.name} successfully` });
    onSubmitComplete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateRequest.status]);

  return (
    <Dialog open={editingState !== null} width="large">
      {/* @ts-expect-error TS(2322): Type 'false | (() => void)' is not assignable to t... Remove this comment to see the full error message */}
      <DialogHeader title={`Edit ${editingState?.name}: ${activeTab.name}`} onClose={!saving && onClose} />
      <DialogBody>
        <Box id={formId} as="form" fill="horizontal" gap="medium" onSubmit={handleSubmit(onSubmit)}>
          {columnConfigurations.map((config) => {
            if (!config.usages.includes(ColumnConfigurationUsage.UPDATE)) return null;

            return (
              <StateField
                key={config.propertyName}
                control={control}
                columnConfiguration={config}
              />
            );
          })}
        </Box>
      </DialogBody>
      <DialogActions>
        <Button
          plain
          onClick={onClose}
          disabled={saving}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          form={formId}
          disabled={saving}
        >
          <Busy busy={saving} content="Save" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type StateFieldProps = {
  control: Control<Record<string, string>>;
  columnConfiguration: ColumnConfiguration;
};

const StateField = ({ control, columnConfiguration }: StateFieldProps) => {
  const fieldId = useId();
  const {
    field: { name, onChange, onBlur, value, ref },
    fieldState: { error }
  } = useController({
    name: columnConfiguration.propertyName,
    control
  });

  const { pools, states } = useStatesProvider();
  const poolOptions = useMemo<Lookup<string>[]>(
    () => pools.map(pool => ({ id: pool.code, label: pool.name })),
    [pools]
  );
  const stateOptions = useMemo<Lookup<string>[]>(
    () => states.map(state => ({ id: state.name, label: state.name })),
    [states]
  );

  // TODO: come up with a better mechanism for breaking out of the defaults
  if (columnConfiguration.propertyName === 'Pool') {
    return (
      <LookupSelect
        name={name}
        multiple={false}
        label={columnConfiguration.header}
        clear={{ label: 'Clear Pool' }}
        placeholder="Select a Pool"
        options={poolOptions}
        value={value}
        error={error?.message}
        onBlur={onBlur}
        onChange={onChange}
        ref={ref}
      />
    );
  } else if (['DurMeetingAddressState', 'PtMeetingAddressState'].includes(columnConfiguration.propertyName)) {
    return (
      <LookupSelect
        name={name}
        multiple={false}
        label={columnConfiguration.header}
        clear={{ label: 'Clear State' }}
        placeholder="Select a State"
        options={stateOptions}
        value={value}
        error={error?.message}
        onBlur={onBlur}
        onChange={onChange}
        ref={ref}
      />
    );
  }

  switch (columnConfiguration.type) {
    case ColumnConfigurationType.URL:
      return (
        <FormField
          name={name}
          htmlFor={fieldId}
          label={columnConfiguration.header}
          error={error?.message}
        >
          <TextInput
            id={fieldId}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            ref={ref}
          />
        </FormField>
      );
    case ColumnConfigurationType.STRING:
      if (columnConfiguration.possibleValues && columnConfiguration.possibleValues.length > 0) {
        return (
          <>
            <Text as="label" margin="small" weight="bold">{columnConfiguration.header}</Text>
            <BorderlessFormField
              name={name}
              htmlFor={fieldId}
              error={error?.message}
              required
            >
              <RadioButtonGroup
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                direction="row"
                name={name}
                id={fieldId}
                options={columnConfiguration.possibleValues}
              />
            </BorderlessFormField>
          </>
        );
      }
      return (
        <FormField
          name={name}
          htmlFor={fieldId}
          label={columnConfiguration.header}
          error={error?.message}
        >
          <TextInput
            id={fieldId}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            ref={ref}
          />
        </FormField>
      );
    case ColumnConfigurationType.INTEGER:
      return (
        <FormField
          name={name}
          htmlFor={fieldId}
          label={columnConfiguration.header}
          error={error?.message}
        >
          <NumericTextInput
            id={fieldId}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            ref={ref}
          />
        </FormField>
      );
    case ColumnConfigurationType.BOOLEAN:
      return (
        <>
          <Text as="label" margin="small" weight="bold">{columnConfiguration.header}</Text>
          <BorderlessFormField
            name={name}
            htmlFor={fieldId}
            error={error?.message}
            required
          >
            <RadioButtonGroup
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              direction="row"
              name={name}
              id={fieldId}
              options={[
                {
                  value: 'True',
                  label: 'Yes'
                },
                {
                  value: 'False',
                  label: 'No'
                }
              ]}
            />
          </BorderlessFormField>
        </>
      );
    case ColumnConfigurationType.DATE_ONLY:
      return (
        <FormField
          name={name}
          htmlFor={fieldId}
          label={columnConfiguration.header}
          error={error?.message}
        >
          <DateTextInput
            id={fieldId}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            ref={ref}
          />
        </FormField>
      );
    case ColumnConfigurationType.MULTILINE_STRING:
      return (
        <FormField
          name={name}
          htmlFor={fieldId}
          label={columnConfiguration.header}
          error={error?.message}
        >
          <TextArea
            id={fieldId}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            resize="vertical"
            ref={ref}
          />
        </FormField>
      );
  }
};
