import { useAsync, UseAsyncStatus } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { useHeaderDetails } from '@shared/header';
import {
  Box,
  Card,
  CardBody,
  NameValueList,
  NameValuePair,
  Text
} from 'grommet';
import { Basket } from 'grommet-icons';
import React, { useEffect, useMemo, useState } from 'react';
import { HiOutlinePaperClip, HiOutlinePencil } from 'react-icons/hi2';
import { useNavigate, useParams } from 'react-router-dom';

import { AttachmentUploadDialog } from '@/components/attachment-upload-dialog';
import { AttachmentsTable } from '@/components/attachments-table';
import { Timestamp, YearMonth } from '@/components/date-time';
import { Tabs } from '@/components/tabs';
import { Alert, AlertBody, AlertTitle } from '@/components-new/alert';
import { Button } from '@/components-new/button';
import { PageTitleRow } from '@/components-new/page-title-row';
import { TextLink } from '@/components-new/text';
import { MarketBasket } from '@/features/market-baskets';
import { useMarketBasketService } from '@/features/market-baskets';
import { ChangeLogTable } from '@/features/market-baskets/components/show/change-log';
import { DrugMarketDetails } from '@/features/market-baskets/components/show/drug-market-details';
import { useMarketBasketDetails } from '@/features/market-baskets/market-basket-details.provider';
import { notifySuccess } from '@/lib/notification/notifications';

const ShowMarketBasketPage = () => {
  const { handleError } = useErrorHandler();
  const { setHeaderDetails, clearHeaderDetails } = useHeaderDetails();
  const { loadMarketBasket, marketBasket } = useMarketBasketDetails();
  const { downloadAttachment, uploadAttachment } = useMarketBasketService();
  const navigate = useNavigate();
  const { id } = useParams();

  const generateAlertMessages = (marketBasket?: MarketBasket): string[] => {
    const messages: string[] = [];

    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    if (marketBasket?.drugPackagings.length === 0) {
      messages.push('There are no drug packagings in this market basket.');
      return messages;
    }

    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    if (marketBasket?.drugPackagings.every(it => it.clientId !== marketBasket?.client?.id)) {
      messages.push(`None of the drug packagings in this market basket belong to ${marketBasket?.client?.label}.`);
    }

    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    if (marketBasket?.drugPackagings.some(it => it.needsAttention)) {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      messages.push(`There are ${marketBasket?.drugPackagings.filter(it => it.needsAttention).length} drug packagings in this market basket that need attention.`);
    }

    return messages;
  };

  const alertMessages = useMemo(() => generateAlertMessages(marketBasket), [marketBasket]);

  React.useEffect(() => {
    const breadcrumbs = marketBasket ? [
      { icon: Basket, label: 'Market Baskets', url: '/market-baskets' },
      { label: marketBasket.name, url: `/market-baskets/${marketBasket.id}` }
    ] : [];

    setHeaderDetails({
      documentTitle: 'Market Basket Details',
      pageTitle: (
        <PageTitleRow title={marketBasket?.name}>
          <Box>
            <Button
              aria-label="Edit Market Basket"
              onClick={() => navigate('edit')}
            >
              <HiOutlinePencil/>
              Edit
            </Button>
          </Box>
        </PageTitleRow>
      ),
      breadcrumbs
    });

    return () => {
      clearHeaderDetails();
    };
    // TODO: revisit this, missing deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketBasket]);

  React.useEffect(() => {
    if (id === null) {
      return;
    }

    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    loadMarketBasket(parseInt(id));
    // TODO: revisit this, missing deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // TODO: refactor this duplicate block
  const onDownload = async (url: string) => {
    try {
      const attachment = await downloadAttachment(url);

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(attachment.contents);
      link.download = attachment.filename;
      link.click();
    } catch (ex) {
      handleError(
        ex as Error,
        {
          title: 'Download Failed',
          message: 'We encountered an unexpected error while downloading your file. Please try again or contact an administrator.',
        }
      );
    }
  };

  const [ showUploadAttachmentDialog, setShowUploadAttachmentDialog ] = useState(false);

  const handleUploadAttachment = () => {
    setShowUploadAttachmentDialog(true);
  };

  const handleCancelUploadAttachment = () => {
    setShowUploadAttachmentDialog(false);
  };

  const uploadAttachmentAsync = useAsync(uploadAttachment);

  const uploading = uploadAttachmentAsync.status === UseAsyncStatus.Pending;

  useEffect(() => {
    if (uploadAttachmentAsync.status === UseAsyncStatus.Success) {
      notifySuccess({ title: 'Upload Successful', message: 'Successfully uploaded attachment.' });
      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      loadMarketBasket(parseInt(id));
      setShowUploadAttachmentDialog(false);
    }

    if (uploadAttachmentAsync.status === UseAsyncStatus.Error) {
      handleError(uploadAttachmentAsync.error, { title: 'Upload Failed', message: 'Unable to upload attachment.' });
    }
    // TODO: revisit this, missing deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadAttachmentAsync.status]);

  const handleSubmitAttachment = (file: File) => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    void uploadAttachmentAsync.execute(file, marketBasket.id);
  };

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <>
      <Box direction="column" pad={{ bottom: 'medium' }} gap="medium">
        <Card style={{ background: '#fff' }}>
          <CardBody pad="medium">
            <Box direction="row" gap="medium" pad={{ bottom: 'medium' }}>
              <Box width="50%">
                <NameValueList nameProps={{ width: '25%' }} valueProps={{ width: '75%' }}>
                  <NameValuePair name="Client">
                    <Text wordBreak="break-word" color="text-strong">{marketBasket?.client?.label}</Text>
                  </NameValuePair>
                  <NameValuePair name="Last Refreshed At">
                    {/* @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message */}
                    <Timestamp color="text-strong" value={marketBasket?.lastRefreshedAt} />
                  </NameValuePair>
                  <NameValuePair
                    name={
                      <span>
                      <TextLink
                        className="text-sm font-bold"
                        href="https://www.bls.gov/regions/mid-atlantic/data/consumerpriceindexhistorical_us_table.htm"
                        target="_blank"
                      >
                        Current CPI-U
                      </TextLink>
                    </span>
                    }
                  >
                    <Text color="text-strong">
                      {marketBasket?.currentCpiu != null &&
                        <span>
                        {marketBasket.currentCpiu}
                          {marketBasket?.currentCpiuDate != null &&
                            <span> (as of <YearMonth value={marketBasket.currentCpiuDate} />)</span>
                          }
                      </span>
                      }
                    </Text>
                  </NameValuePair>
                </NameValueList>
              </Box>
              {alertMessages.length > 0 ?
                <Box width="50%">
                  <Alert variant="warning">
                    <AlertTitle>Warning</AlertTitle>
                    <AlertBody>
                      <ul role="list" className="list-disc space-y-1 pl-5">
                        {alertMessages.map((msg, i) => (
                          <li key={i}><Text>{msg}</Text></li>
                        ))}
                      </ul>
                    </AlertBody>
                  </Alert>
                </Box>
                :
                <></>
              }
            </Box>
          </CardBody>
        </Card>

        <Tabs
          activeIndex={activeTabIndex}
          onSelect={setActiveTabIndex}
          items={[
            {
              key: 'drugMarketDetails',
              name: 'Drug Market Details',
              content: (
                <Box>
                  <DrugMarketDetails />
                </Box>
              )
            },
            {
              key: 'attachments',
              name: 'Attachments',
              content: (
                <Box gap="small">
                  <AttachmentsTable attachments={marketBasket?.attachments ?? []} onDownloadHandler={onDownload} />
                  <Box align="end">
                    <Box>
                      <Button
                        aria-label="Upload Attachment"
                        onClick={handleUploadAttachment}
                      >
                        <HiOutlinePaperClip/>
                        Upload Attachment
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )
            },
            {
              key: 'changeLog',
              name: 'Changelog',
              content: (
                <Box>
                  <ChangeLogTable changeLogs={marketBasket?.changelogs ?? []} />
                </Box>
              )
            }
          ]}
        />
      </Box>

      <AttachmentUploadDialog
        open={showUploadAttachmentDialog}
        submitting={uploading}
        accept=".csv,.docx,.jpeg,.jpg,.pdf,.png,.xlsx"
        onSubmit={handleSubmitAttachment}
        onClose={handleCancelUploadAttachment}
      />
    </>
  );
};

export default ShowMarketBasketPage;
