import { useAsync, UseAsyncStatus } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { Box, Heading, RadioButtonGroup, Text } from 'grommet';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Busy } from '@/components/busy';
import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import { BorderlessFormField, InMemoryMultiSelectList } from '@/components/form-controls';
import { MultiSelectOption } from '@/components/form-controls/multi-select-list';
import { Button } from '@/components-new/button';
import { MedicaidDataset } from '@/features/medicaid-datasets';
import { useMedicaidDatasetsService } from '@/features/medicaid-datasets';
import { useLookupsService } from '@/hooks/use-lookups-service';
import { notifySuccess } from '@/lib/notification/notifications';
import { State } from '@/types/state';

type MedicaidSyncDialogProps = {
  open: boolean;
  dataset: MedicaidDataset;
  onCancel: () => void;
  onRequestComplete: () => void;
};

type MedicaidSyncFormInputs = {
  type: 'api' | 'downloadFile',
  quarters: MultiSelectOption[],
  states: MultiSelectOption<string>[]
};

/**
 * A modal that allows the user to choose how they would like to sync a Medicaid
 * dataset.
 */
export const MedicaidSyncDialog = (props: MedicaidSyncDialogProps) => {
  const { handleError } = useErrorHandler();
  const { refreshMedicaidDataset } = useMedicaidDatasetsService();
  const { getStates } = useLookupsService();

  const {
      open,
      dataset,
      onCancel,
      onRequestComplete
  } = props;

  const {
    control,
    handleSubmit,
    resetField,
    reset
  } = useForm<MedicaidSyncFormInputs>({ mode: 'all', defaultValues: {
    type: 'api',
    states: [],
    quarters: []
  } });

  useEffect(() => {
    reset({
      type: 'api',
      states: [ ...statesOptions ],
      quarters: [ ...quartersOptions ]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const quartersOptions: MultiSelectOption[] = [
    { label: 'Q1', id: 1 },
    { label: 'Q2', id: 2 },
    { label: 'Q3', id: 3 },
    { label: 'Q4', id: 4 }
  ];

  useEffect(() => {
    resetField('quarters', { defaultValue: [ ...quartersOptions ] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const [states, setStates] = useState<State[]>([]);

  const loadStates = async () => {
    const stateLookups = await getStates();
    setStates(stateLookups);
  };

  useEffect(() => {
    void loadStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const statesOptions = useMemo<MultiSelectOption<string>[]>(() => states.map(({ code, name }) => ({
    id: code,
    label: name
  })), [states]);

  useEffect(() => {
    if (statesOptions.length > 0) {
      resetField('states', { defaultValue: [ ...statesOptions ] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statesOptions]);

  const syncAsync = useAsync(refreshMedicaidDataset);
  const submitting = syncAsync.status === UseAsyncStatus.Pending;

  const handleFormSubmit = (form: MedicaidSyncFormInputs) => {
    void syncAsync.execute(dataset.id, {
      importMethod: form.type,
      quarters: form.quarters.map(({ id }) => id as number),
      states: form.states.map(({ id }) => id as string)
    });
  };

  useEffect(() => {
    const { status, error } = syncAsync;
    if (status === UseAsyncStatus.Pending || status === UseAsyncStatus.Idle) return;

    if (status === UseAsyncStatus.Error) {
      handleError(error, { title: 'Sync Initiation Failed', message: 'Unable to initiate medicaid sync' });
      return;
    }

    notifySuccess({ title: 'Sync Initiated Successfully', message: 'Successfully initiated medicaid sync.' });
    onRequestComplete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncAsync.status]);

  const canSelectQuartersAndStates = useMemo(() => dataset?.type === 'StateDrugUtilization', [dataset?.type]);

  return (
    <Dialog open={open} width={dataset?.type === 'StateDrugUtilization' ? 'large' : 'medium'}>
      <DialogHeader
        title={(<Heading level="3" margin="none">Sync Medicaid Data</Heading>)}
        subtitle={(<Heading level="6" color="dark-6" margin={{ horizontal: 'none', vertical: 'xsmall', bottom: 'none' }}>{dataset?.title ?? ''}</Heading>)}
        // @ts-expect-error TS(2322): Type 'false | (() => void)' is not assignable to t... Remove this comment to see the full error message
        onClose={!submitting && onCancel}
      />
      <DialogBody>
        <Box fill="horizontal">
          <Box margin="small">
            <Text margin={{ bottom: 'small' }}>Choose how you would like to sync this Medicaid data. In most cases you would want to sync from the Medicaid <strong>API</strong>, however in some scenarios where this is not working syncing from the Medicaid <strong>download file</strong> can be used.</Text>
            <Controller
              control={control}
              name="type"
              render={({ field: { onChange, value } }) => (
                <BorderlessFormField
                  name="type"
                  htmlFor="type"
                >
                  <RadioButtonGroup
                    id="type"
                    name="type"
                    options={[{ id: 'api', label: 'API', value: 'api' }, { id: 'downloadFile', label: 'Download File', value: 'downloadFile' }]}
                    value={value}
                    onChange={(event) => onChange(event.target.value as 'api' | 'downloadFile')}
                  />
                </BorderlessFormField>
              )}
            />
          </Box>
          {canSelectQuartersAndStates &&
          <Box margin="small">
            <Text margin={{ bottom: 'small' }}>Select the quarters and states to sync Medicaid data for. All quarters and states are selected by default.</Text>
            <Box direction="row" gap="small">
              <Box basis="1/2">
                <Controller
                  control={control}
                  name="quarters"
                  render={({ field }) => (
                    <InMemoryMultiSelectList
                      label="Quarters"
                      options={quartersOptions}
                      {...field}
                    />
                  )}
                />
              </Box>
              {states.length > 0 && <Box basis="1/2">
                <Controller
                  control={control}
                  name="states"
                  render={({ field }) => (
                    <InMemoryMultiSelectList
                      label="States"
                      options={statesOptions}
                      {...field}
                    />
                  )}
                />
              </Box>}
            </Box>
          </Box>}
        </Box>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={onCancel} disabled={submitting}>Cancel</Button>
        <Button
          onClick={handleSubmit(handleFormSubmit)}
          disabled={submitting}
        >
          <Busy busy={submitting} content="Sync" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
