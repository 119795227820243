import React from 'react';
import { Outlet } from 'react-router-dom';

import { Breadcrumbs } from '@/components-new/breadcrumbs';
import { Page } from '@/components-new/page';
import { PageTitleRow } from '@/components-new/page-title-row';
import { NewsFeed } from '@/features/news/components/news-feed';

// floated to the top to support route-based modal at /daily-dose/:id
const NewsPages = () => {
  return (
    <Page
      title="Daily Dose"
      description="Stay in the loop with today’s updates."
      header={({ title, description }) => (
        <>
          <Breadcrumbs breadcrumbs={[ { label: 'Daily Dose', url: '/daily-dose' }]} />
          <PageTitleRow title={title} subtitle={description} />
        </>
      )}
    >
      <NewsFeed />
      {/* Modal view outlet for /news/:id */}
      <Outlet />
    </Page>
  );
};

export default NewsPages;
