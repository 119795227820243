import { useErrorHandler } from '@shared/errors';
import React, { createContext, useContext, useState } from 'react';

import { useClientService } from './api/use-clients-service';
import { Client } from './models/client';

type ClientsContextValue = {
  clients: Client[];
  loadingClients: boolean;
  loadClients: () => void;
};

const DEFAULT_CONTEXT_VALUE: ClientsContextValue = {
  clients: [],
  loadingClients: false,
  loadClients: () => null
};

const ClientsContext = createContext<ClientsContextValue>(DEFAULT_CONTEXT_VALUE);

// eslint-disable-next-line react-refresh/only-export-components
export const useClients = () => useContext<ClientsContextValue>(ClientsContext);

type ClientsProps = {
  children?: React.ReactNode
};

export const ClientsProvider = ({ children }: ClientsProps) => {
  const { getClients } = useClientService();
  const { handleError } = useErrorHandler();

  const [clients, setClients] = useState<Client[]>([]);
  const [loadingClients, setLoadingClients] = useState(false);

  const loadClients = async () => {
    setLoadingClients(true);

    try {
      const clients = await getClients();
      setClients(clients);
    } catch (ex) {
      handleError(
        ex as Error,
        {
          title: 'Failed to Load Clients',
          message: 'We encountered an unexpected error while fetching Clients. Please try again or contact an administrator.'
        }
      );
    } finally {
      setLoadingClients(false);
    }
  };

  const contextValue: ClientsContextValue = {
    clients,
    loadingClients,
    loadClients
  };

  return (
    <ClientsContext.Provider value={contextValue}>
      {children}
    </ClientsContext.Provider>
  );
};
