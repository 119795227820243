import { ProtectedRoute } from '@shared/auth';
import { useErrorHandler } from '@shared/errors';
import { useQueryClient } from '@tanstack/react-query';
import { Cubes } from 'grommet-icons';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Breadcrumbs } from '@/components-new/breadcrumbs';
import { PageTitleRow } from '@/components-new/page-title-row';
import { Seo } from '@/components-new/seo';
import { resetDrugsQuery } from '@/features/drugs/api';
import { notifySuccess } from '@/lib/notification/notifications';
import { useProductsService } from '@/products/api/use-products-service';
import { ProductForm } from '@/products/product-form';
import { ProductFormProvider, useProductForm } from '@/products/product-form.provider';

const NewProductPageContainer = () => {
  const { handleError } = useErrorHandler();
  const navigate = useNavigate();
  const { form, resetForm } = useProductForm();
  const { createProduct, uploadDrugPackagingsFile } = useProductsService();
  const queryClient = useQueryClient();

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async () => {
    setSubmitting(true);

    try {
      const product = await createProduct(form);
      await resetDrugsQuery(queryClient);

      const triggerSuccessMessage = () => notifySuccess({ title: 'Drug Created', message: 'Successfully created drug.' });
      const triggerNavigation = () => navigate(`/drugs/${product.id}`);

      const drugPackagingsFile = form.drugPackagingsFile[0];
      if (!drugPackagingsFile) {
        triggerSuccessMessage();
        triggerNavigation();
        return Promise.resolve();
      }

      try {
        await uploadDrugPackagingsFile(drugPackagingsFile, product.id);
        triggerSuccessMessage();
      } catch (fileUploadError) {
        handleError(
          fileUploadError as Error,
          {
            title: 'Gold Standard Upload Failed',
            message: 'Drug creation was successful, but the Gold Standard file could not be parsed. Please try importing the data again.'
          },
          'warn'
        );
      } finally {
        triggerNavigation();
      }

      resetForm();
    } catch (ex) {
      handleError(ex as Error, { title: 'Drug Creation Failed', message: 'We encountered an unexpected error while creating the Drug.' });
    } finally {
      setSubmitting(false);
    }
  };

  const handleCancel = () => {
    navigate('/drugs');
  };

  return (
    <>
      <Seo title="Add Drug" />
      <Breadcrumbs breadcrumbs={[
        { icon: Cubes, label: 'Drugs', url: '/drugs' },
        { label: 'Add Drug', url: '/drugs/new' }
      ]} />
      <PageTitleRow title="Add Drug" />
      <ProductForm onSubmit={handleSubmit} onCancel={handleCancel} submitting={submitting} />
    </>
  );
};

const NewProductPage = () => {
  return (
    <ProtectedRoute policies={['canManageDrugs']}>
      <ProductFormProvider>
        <NewProductPageContainer />
      </ProductFormProvider>
    </ProtectedRoute>
  );
};

export default NewProductPage;
