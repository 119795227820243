import React from 'react';

import { PageTitleRow } from '@/components-new/page-title-row';
import { PostStatusBadge } from '@/features/posts/components/post-status-badge';
import { PostBase, PostStatusCode } from '@/features/posts/types/post-base';

export const CreateUpdatePostPageTitle = ({ title, post }: { title: React.ReactNode, post?: PostBase }) => {
  // post status or draft by default
  const status = post ? post.status : { label: 'Draft', code: PostStatusCode.Draft };

  return (
    <PageTitleRow
      title={
        <div className="flex items-center gap-3">
          {title}
          <PostStatusBadge status={status} className="flex" />
        </div>
      }
    />
  );
};
