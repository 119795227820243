import clsx from 'clsx';
import React from 'react';

import { Badge } from '@/components-new/badge';
import { PostStatusCode } from '@/features/posts/types/post-base';
import { CodeBasedLookup } from '@/types/lookup';

type PostStatusBadgeProps = {
  status: CodeBasedLookup;
} &  React.ComponentPropsWithoutRef<'span'>;

export const PostStatusBadge = ({ status, className }: PostStatusBadgeProps) => {
  let color: 'green' | 'blue' | 'zinc';
  if (status.code === PostStatusCode.Published) { // Published
    color = 'green';
  } else if (status.code === PostStatusCode.Scheduled) { // Scheduled
    color = 'blue';
  } else { // Draft
    color = 'zinc';
  }

  return (
    <Badge color={color} className={clsx(className)}>{status.label}</Badge>
  );
};
