// TODO: CoverageTag.type sometimes comes from the server as CoverageTagType, and sometimes as a string.
export type CoverageTag = {
  id: number;
  name: string;
  type: CoverageTagType | undefined;
  isDefault: boolean;
  canDelete: boolean;
};

export type CoverageTagType = {
  code: string;
  name: string;
}

export const getSortableCoverageTag = (coverageTag: CoverageTag) => {
  switch (coverageTag.type) {
    //@ts-ignore ; see TODO comment at top of file.
    case 'Formulation':
      return { coverageTag, sortOrder: 1 };
    //@ts-ignore
    case 'Dosage':
      return { coverageTag, sortOrder: 2 };
    //@ts-ignore
      case 'Other':
      return { coverageTag, sortOrder: 3 };
    //@ts-ignore
      case 'Indication':
      return { coverageTag, sortOrder: 4 };
    default:
      return { coverageTag, sortOrder: 5 };
  }
};
