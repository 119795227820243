import { hasAnyOfRoles, hasRole, Role } from '@shared/auth/roles';
import { User } from 'oidc-client-ts';

import { CurrentUser } from '@/types/me';

/**
 * Handles determining whether a user satisfies a given policy.
 */
export type PolicyResolverFn = (user: CurrentUser) => boolean;

/**
 * Maps policy keys to their corresponding policy resolvers.
 */
export type PolicyMap<P> = {
  [K in keyof P]: PolicyResolverFn;
};

/**
 * Creates a premium feature policy resolver function for checking whether a
 * user has access to the given premium feature(s). Artia users will always
 * evaluate to true for premium features.
 *
 * @param premiumFeatures The premium feature(s) to check.
 */
const createPremiumFeaturePolicy = (premiumFeatures: string[]): PolicyResolverFn => {
  return (user: CurrentUser) => isAnyArtiaUser(user) || user.premiumFeatures.some((feature) => premiumFeatures.includes(feature));
};

export const canViewCalendar = createPremiumFeaturePolicy(['calendar']);

/**
 * Creates a role policy resolver function for checking whether a user has one
 * of the required roles.
 */
const createRolePolicy = (requiredRoles: Role[]): PolicyResolverFn => {
  return (user: CurrentUser) => hasAnyOfRoles(user, requiredRoles);
};

export const isAssignedToAnyClient: PolicyResolverFn = (user: CurrentUser) => {
  return !!(user && user.assignedClients && user.assignedClients.length > 0);
};

export const isClientUser: PolicyResolverFn = (user: CurrentUser) => {
  return (user)?.client != null;
};

export const isAnyArtiaUser = createRolePolicy([
  Role.ArtiaStaff,
  Role.Administrator,
  Role.ClinicalsManager,
  Role.ClinicalsAnalyst,
  Role.FinancialsManager,
  Role.FinancialsAnalyst
]);

export const canManageBidAnalyses = createRolePolicy([
  Role.Administrator,
  Role.FinancialsManager,
  Role.FinancialsAnalyst,
]);

export const canManageUsers = (user: User) => hasRole(user, Role.Administrator);

export const canManageCalendar = createRolePolicy([
  Role.Administrator,
  Role.ClinicalsManager,
]);

export const canManageClassifications = createRolePolicy([
  Role.Administrator,
  Role.ClinicalsManager,
  Role.ClinicalsAnalyst,
  Role.FinancialsManager,
  Role.FinancialsAnalyst
]);

export const canManageClients = createRolePolicy([
  Role.Administrator,
  Role.ClinicalsManager,
  Role.FinancialsManager,
]);

export const canManageClientAssignments = createRolePolicy([
  Role.Administrator,
  Role.FinancialsManager
]);

export const canManageCoverageTags = createRolePolicy([
  Role.Administrator,
  Role.ClinicalsManager,
  Role.ClinicalsAnalyst,
  Role.FinancialsManager,
  Role.FinancialsAnalyst
]);

export const canManageDrugs = createRolePolicy([
  Role.Administrator,
  Role.ClinicalsManager,
  Role.ClinicalsAnalyst,
  Role.FinancialsManager,
  Role.FinancialsAnalyst
]);

export const canManageDrugPackagings = createRolePolicy([
  Role.Administrator,
  Role.FinancialsManager,
  Role.FinancialsManager
]);

export const canManageMarketBaskets = createRolePolicy([
  Role.Administrator,
  Role.FinancialsManager,
  Role.FinancialsAnalyst
]);

export const canManageMedicaidDatasets = createRolePolicy([
  Role.Administrator,
  Role.FinancialsManager
]);

export const canTrackDrugsForClients = createRolePolicy([
  Role.Administrator,
  Role.FinancialsManager,
  Role.FinancialsAnalyst,
  Role.ClinicalsManager,
  Role.ClinicalsAnalyst,
]);

/**
 * Contains policies used throughout the application to determine whether admin have authorization or not.
 */
export const policiesMap = {
  isAnyArtiaUser,
  isAssignedToAnyClient,
  isClientUser,
  canViewCalendar,
  canManageBidAnalyses,
  canManageCalendar,
  canManageClassifications,
  canManageClients,
  canManageClientAssignments,
  canManageCoverageTags,
  canManageDrugs,
  canManageDrugPackagings,
  canManageMarketBaskets,
  canManageMedicaidDatasets,
  canManageUsers,
  canTrackDrugsForClients
};

