import { useErrorHandler } from '@shared/errors';
import { createContext, useContext, useState } from 'react';
import React from 'react';

import { CoverageTag } from '@/features/drugs/types/coverage-tag';
import { Drug } from '@/features/drugs/types/drug';
import { DrugPackaging } from '@/features/drugs/types/drug-packaging';
import { useProductsService } from '@/products/api/use-products-service';

type ProductDetailsContextValue = {
  product?: Drug;
  loadingProduct: boolean;
  loadProduct: (id: number) => void;
  addCoverageTagToPackaging: (packaging: DrugPackaging, coverageTag: CoverageTag) => void;
  removeCoverageTagFromPackaging: (packaging: DrugPackaging, coverageTag: CoverageTag) => void;
};

const DEFAULT_CONTEXT_VALUE: ProductDetailsContextValue = {
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Product | u... Remove this comment to see the full error message
  product: null,
  loadingProduct: false,
  loadProduct: () => null,
  addCoverageTagToPackaging: () => null,
  removeCoverageTagFromPackaging: () => null
};

const ProductDetailsContext = createContext<ProductDetailsContextValue>(DEFAULT_CONTEXT_VALUE);

// eslint-disable-next-line react-refresh/only-export-components
export const useProductDetails = () => {
  const context = useContext<ProductDetailsContextValue>(ProductDetailsContext);

  if (!context) throw new Error('useProductDetails must be used within a ProductDetailsProvider');

  return context;
};

export const ProductDetailsProvider = ({ children }: ProductDetailsProps) => {
  const { handleError } = useErrorHandler();
  const { getProduct } = useProductsService();

  const [loadingProduct, setLoadingProduct] = useState(false);
  // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  const [product, setProduct] = useState<Drug>(null);

  const loadProduct = async (id: number) => {
    setLoadingProduct(true);

    try {
      const product = await getProduct(id);
      setProduct(product);
    } catch (ex) {
      handleError(
        ex as Error,
        {
          title: 'Failed to Load Product',
          message: 'We encountered an unexpected error while fetching Product information. Please try again or contact an administrator.'
        }
      );
    } finally {
      setLoadingProduct(false);
    }
  };

  const contextValue: ProductDetailsContextValue = {
    addCoverageTagToPackaging: (packaging, coverageTag) => {
      setProduct((current) => {
        if (!current) return current;

        const result = {
          ...current,
          packagings: [...current.packagings]
        };

        const packagingToUpdate = result.packagings.findIndex(it => it.ndc === packaging.ndc);
        if (packagingToUpdate === -1) return current;

        result.packagings[packagingToUpdate] = {
          ...result.packagings[packagingToUpdate],
          coverageTags: [
            ...result.packagings[packagingToUpdate].coverageTags,
            coverageTag
          ].sort((a, b) => a.name.localeCompare(b.name))
        };

        return result;
      });
    },
    removeCoverageTagFromPackaging: (packaging, coverageTag) => {
      setProduct((current) => {
        if (!current) return current;

        const result = {
          ...current,
          packagings: [...current.packagings]
        };

        const packagingToUpdate = result.packagings.findIndex(it => it.ndc === packaging.ndc);
        if (packagingToUpdate === -1) return current;

        result.packagings[packagingToUpdate] = {
          ...result.packagings[packagingToUpdate],
          coverageTags: result.packagings[packagingToUpdate].coverageTags
            .filter(it => it.id !== coverageTag.id)
        };

        return result;
      });
    },
    product,
    loadingProduct,
    loadProduct
  };

  return (
    <ProductDetailsContext.Provider value={contextValue}>
      {children}
    </ProductDetailsContext.Provider>
  );
};

type ProductDetailsProps = {
  children?: React.ReactNode
};
