import { CodeBasedLookup, IdBasedLookup } from '@/types/lookup';

export const PostStatusCode = {
  Published: 'P',
  Scheduled: 'S',
  Draft: 'D',
} as const;

export type PostBase = {
  id: number;
  status: CodeBasedLookup;
  title: string;
  summary: string;
  category: IdBasedLookup;
  author: IdBasedLookup;
  postedAt: string; // the date it was actually published on
  createdAt: string;
  scheduledForPublicationOn?: string;
};
