import { useModal } from '@shared/modal';
import { Box, ColumnConfig, DataTable, Tip } from 'grommet';
import { StatusWarning } from 'grommet-icons';
import React, { useMemo } from 'react';

import { CaretTip } from '@/components/caret-tip';
import { DrugMarketDetailsFilters } from '@/components/drug-market-details-filters';
import { useFilters } from '@/components/filters';
import { TableEmptyPlaceholder, TableLoadingOverlay } from '@/components/loading';
import { MonospaceText } from '@/components/monospace-text';
import { Prompt } from '@/components/prompt';
import { DrugName } from '@/components-new/drugs';
import { BidAnalysisDrugPackaging, CalculationDetails } from '@/features/bid-analysis/types/bid-analysis';
import { ACCENT_1_25 } from '@/styles';
import { formatCurrency } from '@/utils/formatting';

import { useBidAnalysisDetails } from '../../bid-analysis-details.provider';
import { AmpCell } from './amp-cell';
import { EstimatedTotalUraCell } from './estimated-total-ura-cell';
import { UraPercentWacCell } from './ura-percent-wac-cell';
import { WacPriceCell } from './wac-price-cell';
import { WacUnitPriceCell } from './wac-unit-price-cell';

export const DrugMarketDetails = ({ bidAnalysisId }: { bidAnalysisId: number }) => {
  const { bidAnalysis, loadingBidAnalysis } = useBidAnalysisDetails();
  const { openModal, closeModal } = useModal();
  const [filters, setFilters] = useFilters<{ ndc?: string, name?: string, product?: string }>(`bid-analysis-${bidAnalysisId}-drug-market-details`, {});

  const filteredPackagings = useMemo(() => {
    if (!bidAnalysis?.drugPackagings) {
      return [];
    }

    const { ndc, name, product } = filters;

    return bidAnalysis.drugPackagings.filter(packaging =>
      (!ndc || packaging.ndc.includes(ndc))
      && (!name || packaging.name.toLowerCase().includes(name.toLowerCase()))
      && (!product || packaging.drug?.name?.toLowerCase().includes(product.toLowerCase()))
    );
  }, [filters, bidAnalysis]);

  const openLogModal = (calculationDetails: CalculationDetails) => {
    openModal(
      {
        component:
          <Prompt
            header="Calculation Details"
            actions={[ { name: 'Close', onClick: () => closeModal(), variant: 'filled' } ]}
          >
            <Box pad={{ horizontal: 'small' }}>
              <pre style={{ whiteSpace: 'pre-wrap' }}>
                {calculationDetails.log}
              </pre>
            </Box>
          </Prompt>
      },
      { type: 'layer', showCloseIcon: true, onClose: () => closeModal() }
    );
  };

  const buildRowProps = (drugMarketDetails: BidAnalysisDrugPackaging[]) => drugMarketDetails.reduce((
    previous,
    current
  ) => {
    if (current.needsAttention) {
      return {
        ...previous,
        [current.ndc]: { background: ['accent-4'] }
      };
    }

    if (current.belongsToMarketBasketDrug) {
      return {
        ...previous,
        [current.ndc]: { background: [ACCENT_1_25] }
      };
    }

    return { ...previous };
  }, {});

  const drugMarketDetailsColumns: ColumnConfig<BidAnalysisDrugPackaging>[] = [
    {
      property: 'ndc',
      header: 'NDC',
      size: '10rem',
      render: (detail) => {
        if (detail.needsAttention) {
          return (
            <Tip
              content={<CaretTip message="This NDC is missing information. Bid Analysis Calculations cannot be performed until the missing information is provided." width="large" />}
              dropProps={{ align: { left: 'right' } }}
              plain
            >
              <Box direction="row" gap="small" justify="end" alignSelf="end" style={{ cursor: 'pointer' }}>
                {detail.needsAttention && <StatusWarning size="medium" />}
                <MonospaceText>{detail.ndc}</MonospaceText>
              </Box>
            </Tip>
          );
        } else {
          return (
            <MonospaceText>{detail.ndc}</MonospaceText>
          );
        }
      }
    },
    {
      property: 'name',
      header: 'Description',
    },
    {
      property: 'drug.name',
      header: 'Product',
      render: (detail) => (
        <DrugName drug={detail.drug}/>
      )
    },
    {
      property: 'currentWacPrice',
      header: 'WAC Price',
      align: 'end',
      render: ({ currentWacPrice }) => <WacPriceCell wac={currentWacPrice} />
    },
    {
      property: 'currentWacUnitPrice',
      header: 'WAC Unit Price',
      align: 'end',
      render: ({ currentWacUnitPrice }) => <WacUnitPriceCell wacUnit={currentWacUnitPrice} />
    },
    {
      property: 'amp',
      header: 'AMP',
      align: 'end',
      render: ({ amp }) => <AmpCell amp={amp} />
    },
    {
      property: 'acaFederalUpperLimitUnitPrice',
      header: 'ACA-FUL Unit Price',
      align: 'end',
      render: (detail) => detail.acaFederalUpperLimitUnitPrice ? formatCurrency(detail.acaFederalUpperLimitUnitPrice) : <>&mdash;</>
    },
    {
      property: 'acaWeightedAverageManufacturersPrice',
      header: 'ACA-WAMP Unit Price',
      align: 'end',
      render: (detail) => detail.acaWeightedAverageManufacturersPrice ? formatCurrency(detail.acaWeightedAverageManufacturersPrice) : <>&mdash;</>
    },
    {
      property: 'netCostPerUnit',
      header: 'NADAC',
      align: 'end',
      render: (detail) => detail.netCostPerUnit ? formatCurrency(detail.netCostPerUnit) : <>&mdash;</>
    },
    {
      property: 'estimatedTotalUra',
      header: 'Estimated Total URA',
      align: 'end',
      render: ({ totalUra }) => <EstimatedTotalUraCell ura={totalUra} onViewCalculationDetails={openLogModal} />
    },
    {
      property: 'uraPercentWac',
      header: 'URA % WAC',
      align: 'end',
      render: ({ uraPercentWac }) => <UraPercentWacCell uraPercentWac={uraPercentWac} onViewCalculationDetails={openLogModal} />
    }
  ];

  return (
    <Box>
      <Box>
        <DrugMarketDetailsFilters defaultValue={filters} onApplyFilters={setFilters} />
        <DataTable
          columns={drugMarketDetailsColumns}
          data={filteredPackagings}
          sortable
          step={10}
          paginate
          primaryKey="ndc"
          rowProps={buildRowProps(filteredPackagings)}
          placeholder={
            (loadingBidAnalysis || filteredPackagings.length === 0) &&
            <Box fill>
              {loadingBidAnalysis && <TableLoadingOverlay />}
              {!loadingBidAnalysis && filteredPackagings.length === 0 && <TableEmptyPlaceholder content="No Drug Packaging data is available." />}
            </Box>
          }
        />
      </Box>
    </Box>
  );
};
