import { Box, ColumnConfig, DataTable } from 'grommet';
import { DateTime } from 'luxon';
import React, { ReactNode, useMemo, useState } from 'react';
import { HiOutlineArrowTopRightOnSquare, HiOutlinePencil } from 'react-icons/hi2';

import { TableEmptyPlaceholder, TableLoadingOverlay } from '@/components/loading';
import { MultiLineText } from '@/components/multiline-text';
import { Button } from '@/components-new/button';
import { TextLink } from '@/components-new/text';

import { ColumnConfiguration, ColumnConfigurationType, ColumnConfigurationUsage, CustomState } from '../types';
import { EditStateDataDialog } from './edit-state-data-dialog';
import { ExternalDataCellWrapper, ExternalDataTableProvider } from './external-data-providers';
import { IntegerCell } from './integer-cell';
import { StateDataTab } from './state-data-tabs';
import { useStatesProvider } from './states-provider';

type StateDataTableProps = {
  activeTab: StateDataTab;
  columns: ColumnConfiguration[];
  states: CustomState[];
  loading: boolean;
  children?: ReactNode;
};

export const StateDataTable = ({ activeTab, columns, states, loading, children }: StateDataTableProps) => {
  const [editingRow, setEditingRow] = useState<CustomState | null>(null);
  const { refresh } = useStatesProvider();

  const tableColumns = useMemo(() => {
    const baseColumns: ColumnConfig<CustomState>[] = [
      {
        property: 'name',
        header: 'State',
        pin: true
      }
    ];

    let isRowEditable = false;
    for (const config of columns) {
      // the row is editable if AT LEAST one column has the "Update" usage
      isRowEditable = isRowEditable || config.usages.includes(ColumnConfigurationUsage.UPDATE);

      // don't display any columns in the table that don't have the "View from table" usage
      if (!config.usages.includes(ColumnConfigurationUsage.VIEW_FROM_TABLE)) continue;

      baseColumns.push({
        property: config.propertyName,
        header: config.header,
        align: config.type === ColumnConfigurationType.INTEGER ? 'end' : 'start',
        render: (row) => <StateDataCell row={row} config={config} />
      });
    }

    if (isRowEditable) {
      baseColumns.push({
        property: 'actions',
        size: 'small',
        align: 'end',
        header: '',
        render: (row) => (
          <Button
            plain
            aria-label={`Edit ${row.name}`}
            title={`Edit ${row.name}`}
            onClick={() => setEditingRow(row)}
          >
            <HiOutlinePencil/>
          </Button>
        )
      });
    }

    return baseColumns;
  }, [columns]);

  return (
    <ExternalDataTableProvider columnConfigurations={columns}>
      {children}
      <Box overflow="auto">
        <DataTable
          columns={tableColumns}
          data={states}
          primaryKey="id"
          placeholder={
            (loading || states.length === 0) &&
            <Box fill>
              {loading && <TableLoadingOverlay />}
              {!loading && states.length === 0 && <TableEmptyPlaceholder content="No State data is available." />}
            </Box>
          }
        />
      </Box>
      <EditStateDataDialog
        activeTab={activeTab}
        editingState={editingRow}
        columnConfigurations={columns}
        onClose={() => setEditingRow(null)}
        onSubmitComplete={() => {
          setEditingRow(null);
          refresh();
        }}
      />
    </ExternalDataTableProvider>
  );
};

type StateDataCellProps = {
  row: CustomState,
  config: ColumnConfiguration
};

const StateDataCell = ({ row, config }: StateDataCellProps) => {
  const nullValue = <>&mdash;</>;
  const rawValue = row.values[config.propertyName];

  // "cell content" determines any formatting that needs to be done
  let cellContent = <>{rawValue ? rawValue : nullValue}</>;

  if (config.type === ColumnConfigurationType.BOOLEAN) {
    cellContent = <>{rawValue === 'True' ? 'Yes' : 'No'}</>;
  } else if (config.type === ColumnConfigurationType.INTEGER) {
    cellContent = <IntegerCell number={rawValue ? parseInt(rawValue) : null} />;
  } else if (config.type === ColumnConfigurationType.URL) {
    const isValidUrl = rawValue?.startsWith('http://') || rawValue?.startsWith('https://');
    cellContent = rawValue ? (
      <>
        {isValidUrl ? (
          <TextLink href={rawValue} referrerPolicy="no-referrer" target="_blank">
            <HiOutlineArrowTopRightOnSquare className="size-5"/>
          </TextLink>
        ) : rawValue}
      </>
    ) : nullValue;
  } else if (config.type === ColumnConfigurationType.MULTILINE_STRING) {
    cellContent = rawValue ? <MultiLineText style={{ width: 'max-content' }}>{rawValue}</MultiLineText> : nullValue;
  } else if (config.type === ColumnConfigurationType.DATE_ONLY) {
    const convertedValue = rawValue ? DateTime.fromFormat(rawValue, 'yyyy-mm-dd') : null;
    // @ts-expect-error TS(2322): Type 'Element | null' is not assignable to type 'E... Remove this comment to see the full error message
    cellContent = convertedValue && convertedValue.isValid
      ? <time dateTime={rawValue}>{convertedValue.toFormat('mm/dd/yyyy')}</time>
      : null;
  }

  return <ExternalDataCellWrapper row={row} config={config}>{cellContent}</ExternalDataCellWrapper>;
};
