import Nullish from '@/components/nullish';
import { Badge } from '@/components-new/badge';
import { displayPdlStatus, PdlStatus } from '@/features/coverage/types/pdl-status';

const statusColors = {
  [PdlStatus.PREFERRED]: 'secondary',
  [PdlStatus.PREFERRED_NOT_LISTED]: 'secondary',
  [PdlStatus.NON_PREFERRED]: 'salmon',
  [PdlStatus.NON_PREFERRED_NOT_LISTED]: 'salmon'
} as const;

export const PdlStatusBadge = ({ pdlStatus }: { pdlStatus?: PdlStatus }) => {
  if (!pdlStatus || pdlStatus === PdlStatus.BLANK) return <Nullish />;

  const statusColor = statusColors[pdlStatus as keyof typeof statusColors] ?? 'zinc';

  const display = displayPdlStatus(pdlStatus);

  return (
    <Badge color={statusColor}>
      {display}
    </Badge>
  );
};
