import { Box } from 'grommet';
import React from 'react';
import { HiOutlineClock, HiOutlinePencil } from 'react-icons/hi2';

import { Button } from '@/components-new/button';
import { DrugRef } from '@/features/drugs/types/drug';

type ActionsCellProps = {
  drug?: DrugRef;
  ndc: string;
  needsAttention: boolean;
  onEditClick: (productId: number, ndc: string) => void;
  onOverrideClick: (ndc: string) => void;
};

export const ActionsCell = (props: ActionsCellProps) => {
  const {
    drug,
    ndc,
    needsAttention,
    onEditClick,
    onOverrideClick
  } = props;

  const drugId = drug?.id;
  const hasProduct = drugId !== undefined && drugId !== null;

  const handleEditClick = () => {
    if (drugId) {
      onEditClick(drugId, ndc);
    }
  };

  const handleOverrideClick = () => {
    onOverrideClick(ndc);
  };

  return (
    <Box direction="row" align="center">
      <Button
        plain
        title={needsAttention ? 'Please resolve missing NDC information before overriding pricing.' : 'Override NDC Pricing'}
        aria-label="Override NDC Pricing"
        disabled={needsAttention}
        onClick={handleOverrideClick}
      >
        <HiOutlinePencil/>
      </Button>

      <Button
        plain
        title={hasProduct ? 'Edit Historical NDC details' : 'NDC must be associated to a product before you can edit these details'}
        aria-label="Edit Historical Details"
        disabled={!hasProduct}
        onClick={handleEditClick}
      >
        <HiOutlineClock/>
      </Button>
    </Box>
  );
};
