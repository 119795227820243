import { KeyboardEvent, ReactNode, useRef, useState } from 'react';

import {
  PromptContext,
  PromptContextValue,
  PromptFn,
  PromptOptions
} from '@/components/interactions/prompt-context';
import { Button } from '@/components-new/button';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '@/components-new/dialog';
import { Field } from '@/components-new/fieldset';
import { Input } from '@/components-new/input';

type InteractionsContextProviderProps = {
  children: ReactNode;
};

export const PromptContextProvider = ({ children }: InteractionsContextProviderProps) => {
  const [promptOptions, setPromptOptions] = useState<PromptOptions | null>();
  const awaitingPromiseRef = useRef<{ resolve: (value?: string | PromiseLike<string>) => void } | null>(null);
  const [promptValue, setPromptValue] = useState<string>('');

  const prompt: PromptFn = ({ cancelText = 'Cancel', confirmText = 'Submit', defaultValue, ...rest }: PromptOptions) => {
    setPromptOptions({ confirmText, cancelText, defaultValue, ...rest });
    setPromptValue(defaultValue ?? '');

    return new Promise((resolve) => {
      // TODO: revisit this type checking
      awaitingPromiseRef.current = { resolve: resolve as any };
    });
  };

  const closePrompt = () => {
    setPromptOptions(null);
    setPromptValue('');
  };

  const handlePromptCancel = () => {
    closePrompt();
  };

  const handlePromptConfirm = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(promptValue);
    }

    closePrompt();
  };

  const contextValue: PromptContextValue = {
    prompt
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    const { key } = event;

    if (key === 'Enter') {
      handlePromptConfirm();
      event.stopPropagation();
    }

    if (key === 'Escape') {
      handlePromptCancel();
      event.stopPropagation();
    }
  };

  return (
    <PromptContext.Provider value={contextValue}>
      {children}
      {!!promptOptions && (
        <Dialog
          onClose={handlePromptCancel}
          open={!!promptOptions}
          onKeyDown={handleOnKeyDown}
        >
          <DialogTitle>{promptOptions.title}</DialogTitle>
          <DialogDescription>{promptOptions.text}</DialogDescription>
          <DialogBody>
            <Field>
              <Input name="promptValue" value={promptValue ?? ''} onChange={({ target }) => setPromptValue(target.value)} />
            </Field>
          </DialogBody>
          <DialogActions>
            <Button outline onClick={handlePromptCancel}>
              {promptOptions.cancelText}
            </Button>
            <Button type="submit" color="secondary" onClick={handlePromptConfirm}>
              {promptOptions.confirmText}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </PromptContext.Provider>
  );
};
