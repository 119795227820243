import { useErrorHandler } from '@shared/errors';
import { Box } from 'grommet';
import React, { useState } from 'react';

import { Busy } from '@/components/busy';
import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import { Button } from '@/components-new/button';
import { useBidAnalysisService } from '@/features/bid-analysis';
import { notifySuccess } from '@/lib/notification/notifications';

type RefreshMarketDetailsDialogProps = {
  open: boolean;
  onClose: (submitted: boolean) => void;
  bidAnalysisId: number;
}

/**
 * Dialog for prompting the user to confirm that they want to refresh the market details for a bid analysis.
 */
export const RefreshMarketDetailsDialog = (props: RefreshMarketDetailsDialogProps) => {
  const {
    open,
    onClose,
    bidAnalysisId,
  } = props;

  const [ processing, setProcessing ] = useState(false);
  const { refreshMarketDetails } = useBidAnalysisService();
  const { handleError } = useErrorHandler();

  const handleCancel = () => {
    onClose(false);
  };

  const handleRefresh = async () => {
    setProcessing(true);
    try {
      await refreshMarketDetails(bidAnalysisId);
      notifySuccess({ title: 'Refresh succeeded', message: 'Market details were successfully refreshed.' });
      onClose(true);
    } catch (ex) {
      handleError(ex as Error, {
        title: 'Refresh Failed',
        message: 'We encountered an unexpected error while refreshing market details. Please try again or contact an administrator.',
      });
    } finally {
      setProcessing(false);
    }
  };


  return (
    <Dialog open={open}>
      {/* @ts-expect-error TS(2322): Type 'false | (() => void)' is not assignable to t... Remove this comment to see the full error message */}
      <DialogHeader title="Refresh Market Details" onClose={!processing && handleCancel} />
      <DialogBody>
        <Box width="large" pad={{ horizontal: 'small' }}>
          Are you sure you want to update this bid analysis with the latest market details from its market basket?
        </Box>
      </DialogBody>
      <DialogActions>
        <Button onClick={handleCancel} disabled={processing}>
          Cancel
        </Button>
        <Button onClick={handleRefresh} disabled={processing}>
          <Busy busy={processing} content="Refresh" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
