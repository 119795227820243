import clsx from 'clsx';
import { ReactNode } from 'react';

type ButtonBarProps = {
  children: ReactNode;
  className?: string;
  align?: keyof typeof styles.alignment;
}

const styles = {
  base: 'mt-6 flex flex-row gap-1',
  alignment: {
    'center': 'justify-center',
    'left': 'justify-start',
    'right': 'justify-end',
  }
};

export const ButtonBar = ({ className, children, align = 'right' }: ButtonBarProps) => {
  const classes = clsx(
    className,
    styles.base,
    styles.alignment[align]
  );
  return (
    <div className={classes}>
      {children}
    </div>
  );
};
