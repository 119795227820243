import { CalendarPageContents } from '@pages/(protected)/calendar/(index)/_calendar';
import React from 'react';

const CalendarPage = () => {
  return (
    <CalendarPageContents
      mode="tracked"
      hint="Meetings pertaining to your tracked drugs."
    />
  );
};

export default CalendarPage;
