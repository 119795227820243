import { useMarkdown } from '@shared/markdown/use-markdown';
import { Box, NameValueList, NameValuePair, Text } from 'grommet';
import React from 'react';

import { Date } from '@/components/date-time';
import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import Nullish from '@/components/nullish';
import { Button } from '@/components-new/button';
import { RichTextPreview } from '@/components-new/rich-text-editor';
import { TextLink } from '@/components-new/text';
import CoverageTagChip from '@/features/coverage/components/coverage-tag-chip';
import { StateCoverage } from '@/features/coverage/types/state-coverage';
import { CoverageTag } from '@/features/drugs/types/coverage-tag';
import { Lookup } from '@/types/lookup';
import { stepTherapyDisplay } from '@/utils/state-coverage';

export const ViewCoverageDialog = ({ open, coverage, onClose }: ViewCoverageDialogProps) => {
  const { toHtml } = useMarkdown();

  if (!open) return <></>;

  const drugName = `${coverage.genericName ? (`${coverage.name} (${coverage.genericName})`) : coverage.name}`;

  return (
    <Dialog width="xlarge" open={open}>
      <DialogHeader
        title={coverage.state.name}
        subtitle={
          <Box direction="row" gap="small" align="center">
            <Text size="large" color="brand">{drugName}</Text>
            <Text size="large" color="brand">
              {coverage.coverageTags.map((tag: CoverageTag) =>
                <CoverageTagChip key={tag.id} coverageTag={tag}/>
              )}
            </Text>
          </Box>
        }
        onClose={onClose}
      />
      <DialogBody>
        <NameValueList nameProps={{ width: '16rem' }} valueProps={{ width: '100%' }} margin="small">
          <NameValuePair name="PDL Status Date">
            <Date
              // @ts-ignore
              value={coverage.pdlStatusDate}
            />
          </NameValuePair>
          <NameValuePair name="Status Change Effective Date">
            <Date
              // @ts-ignore
              value={coverage.pdlStatusEffectiveDate}
            />
          </NameValuePair>
          <NameValuePair name="PDL Status">
            <Text>{coverage.pdlStatus}</Text>
          </NameValuePair>
          <NameValuePair name="PA Types">
            <Text>{coverage.paTypes?.map((it: Lookup) => it.label)?.join('; ')}</Text>
          </NameValuePair>
          <NameValuePair name="Step Therapy">
            <Text>
              <Nullish value={stepTherapyDisplay(coverage.stepTherapyCount, coverage.hasAdditionalSteps)} />
            </Text>
          </NameValuePair>
          <NameValuePair name="PA Criteria Link">
            <Text>{coverage.paCriteriaSourceUrl ? <TextLink href={coverage.paCriteriaSourceUrl} target="_blank">{coverage.paCriteriaSourceUrl}</TextLink> : ''}</Text>
          </NameValuePair>
          <NameValuePair name="Has Auto PA?">
            <Text>{coverage.hasAutoPa ? 'Yes' : 'No'}</Text>
          </NameValuePair>
          <NameValuePair name="PA Criteria">
            <RichTextPreview content={toHtml(coverage.paCriteriaDescription ?? '')} />
          </NameValuePair>
          <NameValuePair name="Notes">
            <RichTextPreview content={toHtml(coverage.notes ?? '')}/>
          </NameValuePair>
        </NameValueList>
      </DialogBody>
      <DialogActions>
        <Button onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type ViewCoverageDialogProps = {
  open: boolean;
  coverage: StateCoverage;
  onClose: () => void;
};
