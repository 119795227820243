import { ProtectedRoute } from '@shared/auth';
import { Box } from 'grommet';
import { toString } from 'lodash';
import React, { Suspense } from 'react';
import { HiOutlinePlus } from 'react-icons/hi2';
import { Outlet } from 'react-router-dom';

import { TableLoadingOverlay } from '@/components/loading';
import { Button } from '@/components-new/button';
import { useCoverageTags } from '@/features/drugs/api/use-coverage-tags';
import { useDrug } from '@/features/drugs/api/use-drug';
import CoverageTagsTable from '@/features/drugs/components/coverage-tags-table';
import { Link, useParams } from '@/router';

const CoverageTagsPage = () => {
  const { id } = useParams('/drugs/:id/coverage-tags');

  return (
    <ProtectedRoute policies={['isAnyArtiaUser']}>
      <Suspense fallback={<TableLoadingOverlay />}>
        <CoverageTagsPageContent drugId={parseInt(id)} />
      </Suspense>
      <Outlet />
    </ProtectedRoute>
  );
};

type Props = {
  drugId: number;
}

const CoverageTagsPageContent = ({ drugId }: Props) => {
  const { data: drug } = useDrug(drugId);
  const { data: coverageTags } = useCoverageTags(drugId);

  return (
    <Box margin={{ top: 'small' }}>
      <Box direction="row" justify="end">
        <Link to="/drugs/:id/coverage-tags/new" params={{ id: toString(drugId) }}>
          <Button>
            <HiOutlinePlus/>
            Add New
          </Button>
        </Link>
      </Box>

      <CoverageTagsTable drug={drug} coverageTags={coverageTags.filter(tag => !tag.isDefault)}/>
      <Outlet />
    </Box>
  );
};

export default CoverageTagsPage;
