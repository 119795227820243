import { ProtectedRoute } from '@shared/auth';
import { useErrorHandler } from '@shared/errors';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Breadcrumbs } from '@/components-new/breadcrumbs';
import { Page } from '@/components-new/page';
import { PageTitleRow } from '@/components-new/page-title-row';
import { useMedicaidDatasetsService } from '@/features/medicaid-datasets';
import { MedicaidDatasetForm } from '@/features/medicaid-datasets/medicaid-dataset-form';
import {
  MedicaidDatasetFormProvider,
  useMedicaidDatasetForm
} from '@/features/medicaid-datasets/medicaid-dataset-form.provider';
import { notifySuccess } from '@/lib/notification/notifications';

const NewMedicaidDataset = () => {
  const { form, resetForm } = useMedicaidDatasetForm();
  const { createMedicaidDataset } = useMedicaidDatasetsService();
  const navigate = useNavigate();
  const { handleError } = useErrorHandler();

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async () => {
    setSubmitting(true);

    try {
      await createMedicaidDataset(form);
      notifySuccess({ title: 'Medicaid Dataset Added', message: 'The Medicaid dataset was added successfully.' });
      resetForm();
      navigate('/medicaid-datasets');
    } catch (ex) {
      handleError(ex as Error, { title: 'Error Adding Medicaid Dataset', message: 'We encountered an unexpected error while adding the Medicaid dataset.' });
    } finally {
      setSubmitting(false);
    }
  };

  const handleCancel = () => {
    navigate('..');
  };

  return (
    <Page
      title="Add Medicaid Dataset"
      header={({ title }) => (
        <>
          <Breadcrumbs
            breadcrumbs={[
              { label: 'Medicaid Datasets', url: '/medicaid-datasets' },
              { label: 'Add Medicaid Dataset', url: '/medicaid-datasets/new' }
            ]}
          />
          <PageTitleRow title={title}/>
        </>
      )}
    >
      <MedicaidDatasetForm onSubmit={handleSubmit} onCancel={handleCancel} submitting={submitting} />
    </Page>
  );
};

const NewMedicaidDatasetPage = () => {
  return (
    <MedicaidDatasetFormProvider>
      <ProtectedRoute component={NewMedicaidDataset} policies={['canManageMedicaidDatasets']} />
    </MedicaidDatasetFormProvider>
  );
};

export default NewMedicaidDatasetPage;
