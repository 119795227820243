import { useErrorHandler } from '@shared/errors';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Busy } from '@/components/busy';
import { Button } from '@/components-new/button';
import { Combobox } from '@/components-new/combobox';
import { Dialog, DialogActions, DialogBody, DialogTitle } from '@/components-new/dialog';
import { ErrorMessage, Field, FieldGroup, Fieldset, Label } from '@/components-new/fieldset';
import { Text } from '@/components-new/text';
import { useMovePackagings } from '@/features/drugs/api/use-move-packagings';
import { useDrugsLookup } from '@/hooks/use-lookups';
import { notifySuccess } from '@/lib/notification/notifications';
import { SlugBasedLookup } from '@/types/lookup';

type MovePackagingsDialogProps = {
  isOpen: boolean;
  packagings: string[];
  onCancel: () => void;
  onSuccess: () => void;
}

type MovePackagingsFormValues = {
  drug: SlugBasedLookup;
}

export const MovePackagingsDialog = ({
  packagings,
  isOpen,
  onCancel,
  onSuccess
}: MovePackagingsDialogProps) => {
  const packagingsCount = packagings.length;
  const form = useForm<MovePackagingsFormValues>();

  const movePackagings = useMovePackagings();
  const { isPending: movingPackagings } = movePackagings;
  const { handleError } = useErrorHandler();

  const [drugQuery, setDrugQuery] = useState<string | undefined>(undefined);

  const { data: drugs } = useDrugsLookup(drugQuery);

  const submitForm = async ({ drug }: MovePackagingsFormValues) => {
    await movePackagings.execute({ drugId: drug.id, ndcs: packagings });
  };

  useEffect(() => {
    const { isSuccess, isError, error } = movePackagings;

    if (isError) {
      handleError(error, { title: 'Failed to move NDCs' });
      return;
    }

    if (isSuccess) {
      onSuccess();
      notifySuccess( { title: 'Moved Successfully', message: `Successfully moved ${packagingsCount} NDC(s).` });
    }
    // TODO: review this effect, it's missing a lot of dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movePackagings.status]);

  useEffect(() => {
    if (!isOpen) {
      form.reset();
    }
    // TODO: review this effect, it depends on a value that it doesn't use and is missing the dependency it does use
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={onCancel} size="xl">
      <DialogTitle>{`Move ${packagingsCount} NDC(s)`}</DialogTitle>
      <DialogBody>
        <form>
          <Fieldset>
            <FieldGroup>
              <Controller
                rules={{
                  required: 'Please select a drug.',
                }}
                // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Lookup<numb... Remove this comment to see the full error message
                defaultValue={null}
                control={form.control}
                render={({ field, fieldState }) => (
                  <Field>
                    <Label>Drug</Label>
                    <Combobox
                      options={drugs}
                      value={field.value}
                      onChange={field.onChange}
                      onQueryChange={(value) => setDrugQuery(value)}
                      valueKey="id"
                      labelKey="label"
                    />
                    {fieldState.error?.message && <ErrorMessage>{fieldState.error?.message}</ErrorMessage>}
                  </Field>
                )}
                name="drug"
              />
              <Text className="text-base italic">
                Note: If the selected NDCs are associated to any coverage tags, their association will be removed by this action. You may associate these NDCs with coverage tags in the new drug when the move is complete.
              </Text>
            </FieldGroup>
          </Fieldset>
        </form>
      </DialogBody>
      <DialogActions>
        <Button plain disabled={movingPackagings} onClick={onCancel}>Cancel</Button>
        <Button
          onClick={form.handleSubmit(submitForm)}
          disabled={!form.formState.isValid || movingPackagings}
        >
          <Busy busy={movingPackagings} content="Move" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
