import { queryOptions, useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { meetingSchema } from '@/features/calendar/types/meeting';
import { artiaProtectedApi } from '@/lib/api-client';
import { QueryConfig } from '@/lib/react-query';
import { buildQueryString } from '@/utils/filtering-functions';

// get calender
type GetCalenderFilters = {
  tracked?: boolean;
  priority?: boolean;
};

const meetingsSchema = z.array(meetingSchema);

const fetchCalendar = async (drugId: number, filters: GetCalenderFilters) => {
  const data = await artiaProtectedApi.get(`drugs/${drugId}/meetings${buildQueryString(filters)}`);

  return meetingsSchema.parse(data);
};

const calendarQueryOptions = (drugId: number, filters: GetCalenderFilters) => {
  return queryOptions({
    queryKey: ['calendar', drugId, { ...filters }],
    queryFn: () => fetchCalendar(drugId, filters)
  });
};

type UseCalendarQueryOptions = {
  drugId: number;
  filters: GetCalenderFilters;
  queryConfig?: QueryConfig<typeof calendarQueryOptions>
};

export const useUpcomingMeetings = ({ drugId, filters, queryConfig }: UseCalendarQueryOptions) => {
  return useQuery({
    ...calendarQueryOptions(drugId, filters),
    ...queryConfig
  });
};
