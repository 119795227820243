import { DateTime } from 'luxon';

import { BoardMeetingFilterData } from './use-board-meeting-service';

export const generateDefaultBoardMeetingFilters = (): BoardMeetingFilterData => {
  const today = DateTime.local();
  const oneYearFromToday = today.plus({ year: 1 });

  return {
    statesOrPools: [],
    drugs: [],
    classes: [],
    meetingTypes: [],
    dateRangeBegin: today.toISODate()!,
    dateRangeEnd: oneYearFromToday.toISODate()!
  };
};
