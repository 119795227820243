import { useFeatures } from '@shared/feature-flags';
import { MarkdownPreview } from '@shared/markdown';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { LiaTimesSolid } from 'react-icons/lia';

import { Date, Timestamp } from '@/components/date-time';
import Nullish from '@/components/nullish';
import { Button } from '@/components-new/button';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '@/components-new/description-list';
import { Drawer, DrawerBody, DrawerHeader, DrawerTitle } from '@/components-new/drawer';
import { SectionHeading } from '@/components-new/section';
import { TextLink } from '@/components-new/text';
import { PdlStatusBadge } from '@/features/drugs/components/pdl-status-badge';
import { DrugCoverage } from '@/features/drugs/types/drug-coverage';
import { useDelayedClose } from '@/hooks/use-delayed-close';
import { automatedPaDisplay, stepTherapyDisplay } from '@/utils/state-coverage';

type CoverageDetailsDrawerProps = {
  coverage?: DrugCoverage;
  onClose: () => void;
}

export const CoverageDetailsDrawer = ({ coverage, onClose }: CoverageDetailsDrawerProps) => {
  const { isOpen, handleOpen, handleClose } = useDelayedClose(!!coverage, onClose);

  const { getFeature } = useFeatures();
  const isStepTherapyEnabled = getFeature('clientViewStepTherapy');

  useEffect(() => {
    if (coverage) {
      handleOpen();
    }
  }, [coverage, handleOpen]);

  return (
    <Drawer open={isOpen} onClose={handleClose} size="3xl">
      {!!coverage && (
        <>
          <DrawerHeader>
            <DrawerTitle>
              Coverage Details
              {coverage.updatedAt &&
                <span className="px-1 text-sm font-normal text-gray-500">
                  (Last updated at <Timestamp value={coverage.updatedAt ?? ''} />)
                </span>}
            </DrawerTitle>
            <Button plain onClick={handleClose}>
              <LiaTimesSolid />
            </Button>
          </DrawerHeader>
          <DrawerBody>
            <div className="space-y-6">
              <div>
                <SectionHeading level={3}>General</SectionHeading>
                <DescriptionList>
                  <DescriptionTerm>State</DescriptionTerm>
                  <DescriptionDetails>{coverage.state.name}</DescriptionDetails>

                  <DescriptionTerm>Status Change Effective Date</DescriptionTerm>
                  <DescriptionDetails><Date value={coverage.pdlStatusEffectiveDate ?? ''}/></DescriptionDetails>

                  <DescriptionTerm>PDL Link</DescriptionTerm>
                  <DescriptionDetails>
                    <Nullish
                      value={coverage.pdlLink}
                      valueRenderer={(link) => <TextLink href={link}>{link}</TextLink>}
                    />
                  </DescriptionDetails>

                  <DescriptionTerm>PDL Status</DescriptionTerm>
                  <DescriptionDetails>
                    <PdlStatusBadge pdlStatus={coverage.pdlStatus} />
                  </DescriptionDetails>

                  <DescriptionTerm>PDL Status Date</DescriptionTerm>
                  <DescriptionDetails>
                    <Nullish
                      value={coverage.pdlStatusDate}
                      valueRenderer={(pdlStatusDate) => <Date value={pdlStatusDate}/>}
                    />
                  </DescriptionDetails>
                </DescriptionList>
              </div>
              <div>
                <SectionHeading>Prior Authorization</SectionHeading>
                <DescriptionList>
                  <DescriptionTerm>Type</DescriptionTerm>
                  <DescriptionDetails>
                    <Nullish value={coverage.paTypes?.map(type => type.label).join('; ')} />
                  </DescriptionDetails>

                  {isStepTherapyEnabled && (
                    <>
                      <DescriptionTerm>Step Therapy</DescriptionTerm>
                      <DescriptionDetails>
                        <Nullish value={stepTherapyDisplay(coverage.stepTherapyCount, coverage.hasAdditionalSteps)} />
                      </DescriptionDetails>
                    </>
                  )}

                  <DescriptionTerm>Link</DescriptionTerm>
                  <DescriptionDetails>
                    <Nullish
                      value={coverage.paCriteriaSourceUrl}
                      valueRenderer={(link) => <TextLink href={link}>{link}</TextLink>}
                    />
                  </DescriptionDetails>

                  <DescriptionTerm>Automated</DescriptionTerm>
                  <DescriptionDetails>
                    {automatedPaDisplay(coverage.hasAutoPa)}
                  </DescriptionDetails>
                </DescriptionList>
              </div>
              <div>
                <SectionHeading className="mb-3">Prior Authorization Criteria</SectionHeading>
                {isEmpty(coverage.paCriteriaDescription) ?
                  (<span className="text-sm text-gray-500">Additional criteria not specified.</span>) :
                  (<MarkdownPreview maxHeight="100%" markdown={coverage.paCriteriaDescription}/>)
                }
              </div>
            </div>
          </DrawerBody>
        </>
      )}
    </Drawer>
  );
};
