import { useErrorHandler } from '@shared/errors';
import React, { createContext, useContext, useState } from 'react';

import { useClassificationService } from '@/features/classifications/api/use-classifications-service';
import { Classification } from '@/features/classifications/types/classification';

type ClassificationDetailsContextValue = {
  classification?: Classification;
  loadingClassification: boolean;
  loadClassification: (id: number) => void;
};

const DEFAULT_CONTEXT_VALUE: ClassificationDetailsContextValue = {
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Classificat... Remove this comment to see the full error message
  classification: null,
  loadingClassification: false,
  loadClassification: () => null
};

const ClassificationDetailsContext = createContext<ClassificationDetailsContextValue>(DEFAULT_CONTEXT_VALUE);

// eslint-disable-next-line react-refresh/only-export-components
export const useClassificationDetails = () => useContext<ClassificationDetailsContextValue>(ClassificationDetailsContext);

type ClassificationDetailsProps = {
  children: React.ReactNode
};

export const ClassificationDetailsProvider = ({ children }: ClassificationDetailsProps) => {
  const { getClassification } = useClassificationService();
  const { handleError } = useErrorHandler();

  // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  const [classification, setClassification] = useState<Classification>(null);
  const [loadingClassification, setLoadingClassification] = useState(false);

  const loadClassification = async (id: number) => {
    setLoadingClassification(true);

    try {
      const classification = await getClassification(id);
      setClassification(classification);
    } catch (ex) {
      handleError(
        ex as Error,
        {
          title: 'Failed to Load Classification',
          message: 'We encountered an unexpected error while fetching Classification information. Please try again or contact an administrator.'
        });
    } finally {
      setLoadingClassification(false);
    }
  };

  const contextValue: ClassificationDetailsContextValue = {
    classification,
    loadingClassification,
    loadClassification
  };

  return (
    <ClassificationDetailsContext.Provider value={contextValue}>
      {children}
    </ClassificationDetailsContext.Provider>
  );
};
