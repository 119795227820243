import { Close } from 'grommet-icons';
import React, { ReactNode } from 'react';

import { Tabs } from '@/components/tabs';
import { Button } from '@/components-new/button';

import { ColumnConfigurationGroup } from '../types';

export type StateDataTab = {
  key: ColumnConfigurationGroup;
  name: string;
  active: boolean;
  userCreated?: boolean;
};

type StateDataTabsProps = {
  tabs: StateDataTab[];
  onTabsChange: (tabs: StateDataTab[]) => void;
  children: (tab: StateDataTab) => ReactNode;
};

export const StateDataTabs = ({ tabs, onTabsChange, children }: StateDataTabsProps) => {
  const activeIndex = tabs.findIndex((tab) => tab.active);

  const changeActiveTab = (activeIndex: number) => {
    const newTabs = tabs.map((tab, index) => ({
      ...tab,
      active: activeIndex === index
    }));
    onTabsChange(newTabs);
  };

  const closeTab = (closingTab: StateDataTab) => {
    const newTabs = tabs.filter((tab) => tab.key !== closingTab.key);
    const closingIndex = tabs.findIndex((tab) => tab.key === closingTab.key);

    // choose the next active tab if the one closed was active
    if (closingIndex === activeIndex) {
      if (closingIndex === 0) {
        newTabs[0] = { ...newTabs[0], active: true };
      } else {
        newTabs[closingIndex - 1] = { ...newTabs[closingIndex - 1], active: true };
      }
    }

    onTabsChange(newTabs);
  };

  // @ts-ignore
  return (
    <Tabs
      activeIndex={activeIndex}
      onSelect={(index) => changeActiveTab(index)}
      items={tabs.map((tab) => ({
        key: tab.key,
        name: (
          <>
            {tab.name}
            {tabs.length > 1 ? (
              <Button
                plain
                aria-label="Close Tab"
                // @ts-ignore
                onClick={event => {
                  event.stopPropagation();
                  closeTab(tab);
                }}
              >
                <Close size="small" />
              </Button>
            ) : null}
          </>
        ),
        content: children(tab)
      }))}
    />
  );
};
