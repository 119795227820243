import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { HiArrowRight } from 'react-icons/hi';
import { HiArrowLeft } from 'react-icons/hi2';
import { z } from 'zod';

import { BulletStepProgress } from '@/components-new/bullet-step-progress';
import { Button, ButtonLoadingIcon } from '@/components-new/button';
import { Dialog, DialogActions, DialogBody, DialogTitle } from '@/components-new/dialog';
import { ErrorMessage, Field, Label } from '@/components-new/fieldset';
import { Heading } from '@/components-new/heading';
import { Input } from '@/components-new/input';
import { StepperProvider } from '@/components-new/stepper';
import { useStepper, useStepperContext } from '@/components-new/stepper.hooks';
import { Text } from '@/components-new/text';
import { useUpdateNotificationSettings, useUpdateUserSettings, useUserNotificationSettings } from '@/features/user-settings/api/api';
import { UserNotificationSettingsForm } from '@/features/user-settings/components/user-notification-settings-form';
import { NotificationSetting, NotificationSettingsForm } from '@/features/user-settings/types/notification-setting';
import { CurrentUser } from '@/types/me';

/**
 *
 * The first step in the onboarding process.
 */
const IntroStep = () => {
  return (
    <>
      <Heading>Getting Started</Heading>
      <Text>
        Before you begin using Capsule, let’s set up your account. Follow the steps below to complete your account.
      </Text>
    </>
  );
};

/**
 * The last step in the onboarding process.
 * @param onComplete - Callback function for completing the onboarding process.
 */
const CompleteStep = ({ onComplete }: { onComplete: () => void }) => {
  const { onNextStep } = useStepperContext();

  onNextStep(() => {
    onComplete();
    return true;
  });

  return (
    <>
      <Heading>Complete</Heading>
      <Text>
        Your account is now complete! You can now begin using Capsule.
      </Text>
    </>
  );
};

/**
 * The step in the onboarding process that allows the user to set their notification preferences.
 * @param userNotificationSettings - The user's notification settings.
 */
const NotificationSettingsStep = ({ userNotificationSettings = [] }: { userNotificationSettings: NotificationSetting[] }) => {
  const notificationSettingsForm = useForm<NotificationSettingsForm>({ mode: 'all' });

  const { mutateAsync: updateUserNotificationSettings } = useUpdateNotificationSettings();

  const { onNextStep } = useStepperContext();

  onNextStep(async () => {
    const isValid = await notificationSettingsForm.trigger();

    if (!isValid) return false;

    try {
      await updateUserNotificationSettings(notificationSettingsForm.getValues('notificationSettings'));
    } catch(error) {
      return false;
    }

    return true;
  });

  return (
    <>
      <Heading>Notification Preferences</Heading>
      <Text>
        Choose how you would like to receive Artia Alerts.
      </Text>
      <UserNotificationSettingsForm
        notificationSettingsForm={notificationSettingsForm}
        userNotificationSettings={userNotificationSettings ?? []}
      />
    </>
  );
};

// profile form schema for validation
const profileFormSchema = z.object({
  fullName: z
    .string()
    .trim()
    .min(1, { message: 'Full name is required.' })
    .max(150, { message: 'Full name must be less than 150 characters.' }),
}).required();

type ProfileForm = z.infer<typeof profileFormSchema>;

/**
 * The step in the onboarding process that allows the user to set their profile information.
 * @param user - The user's profile information.
 */
const ProfileStep = ({ user }: { user: CurrentUser }) => {
  const { onNextStep } = useStepperContext();

  const {
    mutateAsync: updateUserSettings
  } = useUpdateUserSettings();

  const profileForm = useForm<ProfileForm>({
    mode: 'all',
    resolver: zodResolver(profileFormSchema)
  });

  onNextStep(async () => {
    const isValid = await profileForm.trigger();

    if (!isValid) return false;

    try {
      await updateUserSettings({
        name: profileForm.getValues('fullName')
      });
    } catch(error) {
      return false;
    }

    return true;
  });

  return (
    <>
      <Heading>Profile</Heading>
      <Text>
        Confirm the information below or make any necessary changes.
      </Text>
      <Field>
        <Label>Full name</Label>
        <Controller
          control={profileForm.control}
          name="fullName"
          defaultValue={user.name}
          render={({ field, fieldState: { error } }) => (
            <>
              <Input {...field} invalid={!!error} />
              {error && <ErrorMessage>{error.message}</ErrorMessage>}
            </>
          )}
        />
      </Field>
    </>
  );
};

const OnboardingStepperNavigation = () => {

  const {
    nextStep,
    previousStep,
    currentStepIndex,
    totalSteps,
    isLastStep,
    isFirstStep,
    isLoading,
  } = useStepperContext();

  const handleNext = async () => {
    await nextStep();
  };

  return (
    <div className="flex flex-1 justify-between">
      <Button outline className="self-start" onClick={previousStep} disabled={isFirstStep || isLoading}>
        <HiArrowLeft></HiArrowLeft>
        Back
      </Button>
      <BulletStepProgress numberOfSteps={totalSteps} currentStep={currentStepIndex}/>
      <Button color="secondary" onClick={handleNext} disabled={isLoading}>
        {isLoading && <ButtonLoadingIcon />}
        {isLastStep ? 'Done' : 'Next'}
        {!isLastStep && <HiArrowRight></HiArrowRight>}
      </Button>
    </div>
  );
};

type NewClientOnboardingDialogProps = {
  user: CurrentUser;
};

/**
 * A dialog that guides a client user through the onboarding process.
 * @param user - The user to onboard.
 */
export const NewClientOnboardingDialog = ({ user }: NewClientOnboardingDialogProps) => {
  const { data: userNotificationSettings } = useUserNotificationSettings();

  const [open, setOpen] = React.useState(user.needsProvisioning);

  React.useEffect(() => {
    if (user.needsProvisioning) {
      setOpen(true);
    }
  }, [user.needsProvisioning]);

  const steps = React.useMemo(() => ([
    <IntroStep />,
    <ProfileStep user={user} />,
    <NotificationSettingsStep userNotificationSettings={userNotificationSettings ?? []}/>,
    <CompleteStep onComplete={() => setOpen(false)} />,
  ]), [user, userNotificationSettings]);

  const stepper = useStepper({ numberOfSteps: steps.length });
  const { currentStepIndex } = stepper;

  const currentStep = React.useMemo(() =>
    steps[currentStepIndex], [currentStepIndex, steps]);

  return (
    <Dialog open={open} onClose={() => {}} size="3xl">
      <DialogTitle>
        Welcome to Capsule
      </DialogTitle>
      <StepperProvider stepper={stepper}>
        <DialogBody>
          <div className="flex flex-col gap-4">
            {currentStep}
          </div>
        </DialogBody>
        <DialogActions>
          <OnboardingStepperNavigation />
        </DialogActions>
      </StepperProvider>
    </Dialog>
  );
};
