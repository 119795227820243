import AppErrorBoundary from '@shared/errors/app-error-boundary';
import { ErrorHandlerProvider } from '@shared/errors/error-handler.provider';
import { HeaderDetailsProvider } from '@shared/header';
import { HttpClientProvider } from '@shared/http';
import { ModalRoot } from '@shared/modal';
import { ModalProvider } from '@shared/modal/modal.provider';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { Grommet } from 'grommet';
import React from 'react';
import { AuthProvider } from 'react-oidc-context';

import { PromptContextProvider } from '@/components/interactions';
import { env } from '@/config/env';
import { BugsnagManager } from '@/lib/bugsnag/bugsnag-manager';
import { oidcConfig } from '@/lib/odic';
import { ReactIconsProvider } from '@/lib/react-icons';
import { persistOptions, queryClient } from '@/lib/react-query';
import { APP_THEME, ThemedGlobalStyle } from '@/styles';

const AppProvider = ({ children }: {  children: React.ReactNode }) => {
  return (
    <PersistQueryClientProvider client={queryClient} persistOptions={persistOptions}>
      {env.REACT_QUERY_DEV_TOOLS_ENABLED && <ReactQueryDevtools initialIsOpen={false}  />}
        <ReactIconsProvider>
          <Grommet theme={APP_THEME} full>
            <AppErrorBoundary>
                <AuthProvider {...oidcConfig}>
                    <BugsnagManager />
                    <HttpClientProvider>
                      <ThemedGlobalStyle />
                      <ModalProvider>
                        <ErrorHandlerProvider>
                          <HeaderDetailsProvider>
                            <PromptContextProvider>
                              {children}
                            </PromptContextProvider>
                          </HeaderDetailsProvider>
                          <ModalRoot />
                        </ErrorHandlerProvider>
                      </ModalProvider>
                    </HttpClientProvider>
                </AuthProvider>
            </AppErrorBoundary>
          </Grommet>
        </ReactIconsProvider>
    </PersistQueryClientProvider>
  );
};

export { AppProvider };
