import { useErrorHandler } from '@shared/errors';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { makeCoverageTagsQueryKey } from '@/features/drugs/api/use-coverage-tags';
import { artiaProtectedApi } from '@/lib/api-client';
import { notifySuccess } from '@/lib/notification/notifications';

const deleteCoverageTag = (drugId: number, coverageTagId: number): Promise<void> => {
  return artiaProtectedApi.delete(`drugs/${drugId}/coverage-tags/${coverageTagId}`);
};

export const useDeleteCoverageTag = () => {
  const queryClient = useQueryClient();
  const { handleError } = useErrorHandler();

  return useMutation({
    mutationFn: ({ drugId, coverageTagId }: { drugId: number, coverageTagId: number }) => deleteCoverageTag(drugId, coverageTagId),
    onSuccess: () => {
      notifySuccess({ title: 'Saved', message: 'The coverage tag was successfully deleted.' });
    },
    onError: (error) => {
      handleError(error, { title: 'Save Failed', message: 'Unable to delete coverage tag.' });
    },
    onSettled: async (_, __, { drugId }) => {
      await queryClient.invalidateQueries({ queryKey: makeCoverageTagsQueryKey(drugId) });
    }
  });
};
