import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { useForm } from 'react-hook-form';

import { Breadcrumbs } from '@/components-new/breadcrumbs';
import { Page } from '@/components-new/page';
import { usePost, useUpdatePost } from '@/features/posts/api';
import { CreateUpdatePostForm } from '@/features/posts/components/create-update-post-form';
import { CreateUpdatePostPageTitle } from '@/features/posts/components/create-update-post-page-title';
import {
  CreateUpdatePostFormSchema,
  makeCreateUpdatePostFormSchema
} from '@/features/posts/types/create-post-form-schema';
import { isPostPublished } from '@/features/posts/utils';
import { useNavigate, useParams } from '@/router';

const EditPostPage = () => {
  const { id: idString } = useParams('/posts/:id/edit');
  const id = parseInt(idString);
  const navigate = useNavigate();
  const { data: post, isLoading: isPostLoading } = usePost(id);

  const {
    mutate: updatePost,
    isPending: isUpdatingPost,
  } = useUpdatePost(id);

  const form = useForm<CreateUpdatePostFormSchema>({
    // dynamically create the resolver
    resolver: (values, context, options) => {
      const createResolver = zodResolver(makeCreateUpdatePostFormSchema(!!post && isPostPublished(post)));
      return createResolver(values, context, options);
    },
    mode: 'all'
  });

  const handleSubmit = (value: CreateUpdatePostFormSchema) => {
    updatePost(
      { form: value },
      {
        onSuccess: () => {
          navigate('/posts');
        }
      }
    );
  };

  return (
    <Page
      title="Edit Post"
      header={({ title }) => (
        <>
          <Breadcrumbs
            breadcrumbs={[
              { label: 'Posts', url: '/posts' },
              { label: 'Edit Post', url: `/posts/${id}/edit` },
            ]}
          />
          <CreateUpdatePostPageTitle title={title} post={post} />
        </>
      )}
    >
      {!isPostLoading && (
        <CreateUpdatePostForm
          post={post}
          form={form}
          submitButtonLabel="Update Post"
          isSubmitDisabled={isUpdatingPost}
          onSubmit={form.handleSubmit(handleSubmit)}
        />
      )}
    </Page>
  );
};

export default EditPostPage;
