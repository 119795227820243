import { useErrorHandler } from '@shared/errors';
import { QueryClient, queryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { DrugBase } from '@/features/drugs/types/drug';
import { artiaProtectedApi } from '@/lib/api-client';
import { notifySuccess } from '@/lib/notification/notifications';
import { MutationConfig, QueryConfig } from '@/lib/react-query';
import { drugName } from '@/utils/drugs';
import { buildQueryString } from '@/utils/filtering-functions';

export const queryKeys = {
  drugsBase: ['drugs'],
  allDrugs: ({ name = '', ndc = '' }: GetDrugsFilters) => [...queryKeys.drugsBase, name, ndc]
};

// DRUGS
export const resetDrugsQuery = async (queryClient: QueryClient) => {
  await queryClient.invalidateQueries({ queryKey: queryKeys.drugsBase });
};

type GetDrugsFilters = {
  name?: string;
  ndc?: string;
}

const getDrugs = async (filters: GetDrugsFilters): Promise<DrugBase[]> => {
  return await artiaProtectedApi.get(`drugs/${buildQueryString(filters)}`);
};

const drugsQueryOptions = (filters: GetDrugsFilters) => {
  return queryOptions({
    queryKey: [...queryKeys.allDrugs(filters)],
    queryFn: () => getDrugs(filters)
  });
};

type UseDrugsQueryOptions = {
  filters: GetDrugsFilters,
  queryConfig?: QueryConfig<typeof drugsQueryOptions>
}

export const useDrugsQuery = ({ filters, queryConfig }: UseDrugsQueryOptions) => {
  return useQuery({
    ...drugsQueryOptions(filters),
    ...queryConfig
  });
};

// DELETE DRUG
const deleteDrug = async (drug: DrugBase): Promise<void> => {
  return await artiaProtectedApi.delete(`drugs/${drug.id}`);
};

type UseDeleteDrugOptions = {
  mutationConfig?: MutationConfig<typeof deleteDrug>;
}

export const useDeleteDrug = ({ mutationConfig }: UseDeleteDrugOptions = {})  => {
  const queryClient = useQueryClient();
  const { handleError } = useErrorHandler();

  const { onError, onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess: async (...args) => {
      const [_, variables] = args;
      notifySuccess({ title: 'Deleted', message: `${drugName(variables)} post was successfully deleted.` });
      await resetDrugsQuery(queryClient);
      onSuccess?.(...args);
    },
    onError: (...args) => {
      const [error, variables] = args;
      handleError(error, { title: 'Delete Failed', message: `Unable to delete ${drugName(variables)}.` });
      onError?.(...args);
    },
    mutationFn: deleteDrug,
    ...restConfig
  });
};
