import clsx from 'clsx';

type BulletStepProps = {
  /**
   * The number of the step.
   */
  stepNumber: number;
  /**
   * Whether the step is the current step.
   */
  current: boolean;
  /**
   * Whether the step is completed.
   */
  completed: boolean;
} & React.ComponentPropsWithoutRef<'li'>;

/**
 * Creates a step indicator made of bullet that are colored according to their state (completed, current, or not started).
 */
const BulletStep = ({ stepNumber, current, completed }: BulletStepProps) => {
  return (
    <span
      aria-current={current ? 'step' : undefined}
      className="relative flex items-center justify-center"
    >
      <span aria-current="step" className="relative flex items-center justify-center">
        {
          current && (
            <span aria-hidden="true" className="absolute flex size-5 p-px">
              <span className="size-full rounded-full bg-secondary-100"/>
            </span>
          )}
          <span className={clsx(
            (!completed && !current) && 'bg-gray-200',
            (completed || current) && 'bg-secondary-600',
            'relative block size-2.5 rounded-full'
          )}>
          <span className="sr-only">Step {stepNumber}</span>
        </span>
      </span>
    </span>
  );
};

type BulletStepProgressProps = {
  /**
   * The number of steps in the progress.
   */
  numberOfSteps: number;
  /**
   * The current step in the progress.
   */
  currentStep: number;
}

/**
 * Provides indication to the user of the progress of a multi-step process.
 */
export const BulletStepProgress = ({ numberOfSteps, currentStep }: BulletStepProgressProps) => {

  // fill an array with the number of steps
  const steps = new Array(numberOfSteps).fill(0).map((_, index) => index + 1);

  return (
    <div aria-label="Progress"
         className="flex flex-wrap-reverse items-center justify-center md:flex-row">
      <ol role="list" className="flex items-center space-x-5">
        {steps.map((stepNumber, index) => (
          <li key={stepNumber}>
            <BulletStep
              stepNumber={stepNumber}
              current={index === currentStep}
              completed={index < currentStep}
            />
          </li>
        ))}
      </ol>
    </div>
  );
};
