import { useQuery } from '@tanstack/react-query';

import { artiaProtectedApi } from '@/lib/api-client';
import { IdBasedLookup, SlugBasedLookup } from '@/types/lookup';
import { buildQueryString } from '@/utils/filtering-functions';

// CLIENTS
const getClients = (query: string | undefined): Promise<IdBasedLookup[]> => {
  return artiaProtectedApi.get(`lookups/clients${buildQueryString({ q: query })}`);
};

export const useClientsLookup = (query: string | undefined) => {
  return useQuery({
    queryKey: ['lookup', 'clients', query],
    queryFn: async () => getClients(query)
  });
};

// CLASSIFICATIONS
const getClassifications = (query: string | undefined): Promise<IdBasedLookup[]> => {
  return artiaProtectedApi.get(`lookups/classifications${buildQueryString({ q: query })}`);
};

export const useClassificationsLookup = (query: string | undefined) => {
  return useQuery({
    queryKey: ['lookup', 'classifications', query],
    queryFn: async () => getClassifications(query)
  });
};

// DRUGS
const getDrugs = (query: string | undefined): Promise<SlugBasedLookup[]> => {
  return artiaProtectedApi.get(`lookups/products${buildQueryString({ q: query })}`);
};

export const useDrugsLookup = (query: string | undefined) => {
  return useQuery({
    queryKey: ['lookup', 'drugs', query],
    queryFn: async () => getDrugs(query)
  });
};
