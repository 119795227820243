import { useAuthorization } from '@shared/auth/use-authorization';
import { PaginationResult } from '@shared/pagination';
import { Box, FormField, TextInput } from 'grommet';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Busy } from '@/components/busy';
import { LazyLoadMultiSelectList } from '@/components/form-controls';
import { Button } from '@/components-new/button';
import { useLookupsService } from '@/hooks/use-lookups-service';
import { Lookup } from '@/types/lookup';

import { Client } from './models/client';
import { ClientFormInputs } from './models/client-form-inputs';

type ClientFormProps = {
  defaultValues?: Client;
  saving: boolean;
  onSubmit: (value: ClientFormInputs) => void;
  onCancel: () => void;
};

export const ClientForm = (props: ClientFormProps) => {
  const {
    defaultValues = {
      id: 0,
      name: '',
      assignedAnalysts: []
    },
    saving,
    onSubmit,
    onCancel
  } = props;

  const {
    control,
    formState: { errors, isValid },
    handleSubmit
  } = useForm<ClientFormInputs>({
    mode: 'all',
    defaultValues: {
      name: defaultValues.name,
      assignedAnalysts: defaultValues.assignedAnalysts.map(({ id, name }) => ({ id, label: name }))
    }
  });

  const submitDisabled = !isValid || saving;

  const { getAnalystLookups } = useLookupsService();
  const { checkPolicies } = useAuthorization();
  const canManageClientAssignments = checkPolicies(['canManageClientAssignments']);

  return (
    <>
      <Box pad={{ vertical: 'medium' }}>
        <Controller
          control={control}
          name="name"
          rules={{
            maxLength: 100,
            required: true
          }}
          render={({ field: { ref, value, onChange, onBlur } }) => (
            <FormField
              name="name"
              htmlFor="name"
              label="Name"
              error={errors.name?.message}
            >
              <TextInput
                ref={ref}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                id="name"
                name="name"
                maxLength={100}
                placeholder="Client Name"
              />
            </FormField>
          )}
        />
        {canManageClientAssignments && (
          <Box direction="column" gap="medium">
            <Box>
              <Controller
                control={control}
                name="assignedAnalysts"
                render={({ field, fieldState: { error } }) => (
                  <LazyLoadMultiSelectList
                    label="Assigned Analysts"
                    showSelectedInline
                    lazyLoadRequest={async (searchTerm, page, rpp) => {
                      const result = await getAnalystLookups({ query: searchTerm, page, rpp });
                      return result as PaginationResult<Lookup>;
                    }}
                    {...field}
                    error={error?.message}
                  />
                )}
              />
            </Box>
          </Box>
        )}
      </Box>

      <Box direction="row" justify="end" gap="small">
        <Button plain onClick={onCancel} disabled={saving}>Cancel</Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          disabled={submitDisabled}
        >
          <Busy busy={saving} content="Save" />
        </Button>
      </Box>
    </>
  );
};
