import { DrugDisplayable } from '@/features/drugs/types/drug';

/**
 * Formats a drug name for display. Use this function when using the <DrugName/>
 * component is not feasible.
 *
 * @param drug The drug to format the name for.
 * @param includeGenericName Whether to include the generic name in the
 * formatted name.
 */
export const drugName = (drug: DrugDisplayable | undefined, includeGenericName = false) => {
  if (!drug) return '';

  const extra = includeGenericName && drug.genericName ? ` (${drug.genericName})` : '';

  return drug.displayAsBrand
    ? drug.name.toUpperCase() + extra
    : drug.name + extra;
};
