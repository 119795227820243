import { useAuthorization } from '@shared/auth/use-authorization';
import { useHeaderDetails } from '@shared/header';
import { Box, ColumnConfig, DataTable } from 'grommet';
import React, { useMemo, useState } from 'react';
import { HiOutlinePencil, HiOutlinePlus, HiOutlineTrash } from 'react-icons/hi2';
import { useNavigate } from 'react-router-dom';

import { useFilters } from '@/components/filters';
import { TableEmptyPlaceholder, TableLoadingOverlay } from '@/components/loading';
import { Button } from '@/components-new/button';
import { PageTitleRow } from '@/components-new/page-title-row';
import { Classification } from '@/features/classifications';
import { ClassificationsProvider, useClassifications } from '@/features/classifications/classifications.provider';
import { DeleteClassificationDialog } from '@/features/classifications/components/delete-classification-dialog';
import { insertIf } from '@/utils/arrays';

const Classifications = () => {
  const { setHeaderDetails, clearHeaderDetails } = useHeaderDetails();
  const { classifications, loadClassifications, loadingClassifications } = useClassifications();
  const navigate = useNavigate();
  const { checkPolicies } = useAuthorization();
  const canManageClassifications = checkPolicies(['canManageClassifications']);

  const [filters] = useFilters<{ name?: string }>('classifications', {});

  const filteredClassifications = useMemo(() => {
    const { name } = filters;

    return classifications.filter(classification =>
      !name || classification.name.toLowerCase().includes(name.toLowerCase())
    );
  }, [filters, classifications]);

  React.useEffect(() => {
    loadClassifications();

    setHeaderDetails({
      documentTitle: 'Classifications',
      pageTitle: (
        <PageTitleRow title="Classifications">
          {canManageClassifications && (
            <Button color="secondary" aria-label="Add Classification" onClick={onCreateClassification}>
              <HiOutlinePlus/>
              Add New
            </Button>
          )}
        </PageTitleRow>
      ),
      breadcrumbs: []
    });

    return () => {
      clearHeaderDetails();
    };
    // TODO: revisit this, missing deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCreateClassification = () => {
    navigate('/classifications/new');
  };

  const onEditClassification = (id: number) => {
    navigate(`/classifications/${id}/edit`);
  };

  const [ deletingClassificationId, setDeletingClassificationId ] = useState<number>();
  const [ deletingClassificationName, setDeletingClassificationName ] = useState<string>();

  const handleDelete = (classification: Classification) => {
    setDeletingClassificationId(classification.id);
    setDeletingClassificationName(classification.name);
  };

  const handleCancelDelete = () => {
    setDeletingClassificationId(undefined);
    setDeletingClassificationName(undefined);
  };

  const handleDeleteSuccessful = () => {
    setDeletingClassificationId(undefined);
    setDeletingClassificationName(undefined);
    loadClassifications();
  };

  const columns: ColumnConfig<Classification>[] = [
    { property: 'name', header: 'Name' },
    ...insertIf(
      canManageClassifications,
      {
        property: 'actions',
        sortable: false,
        size: 'xsmall',
        render: (classification: Classification) => (
          <Box direction="row" gap="xxsmall">
            <Button
              plain
              title={`Edit ${classification.name}`}
              aria-label={`Refresh ${classification.name}`}
              onClick={() => onEditClassification(classification.id)}
            >
              <HiOutlinePencil/>
            </Button>
            <Button
              plain
              title={`Delete ${classification.name}`}
              aria-label={`Delete ${classification.name}`}
              onClick={() => handleDelete(classification)}
            >
              <HiOutlineTrash/>
            </Button>
          </Box>
        )
      }
    )
  ];

  return (
    <Box pad={{ bottom: 'medium' }}>
      <Box margin={{ top: 'small' }} gap="small">
        {/*<ClientFilters defaultValue={filters} onApplyFilters={setFilters} />*/}
        <DataTable
          columns={columns}
          data={filteredClassifications}
          sortable
          step={10}
          paginate
          placeholder={
            (loadingClassifications || filteredClassifications.length === 0) &&
            <Box fill>
              {loadingClassifications && <TableLoadingOverlay />}
              {!loadingClassifications && filteredClassifications.length === 0 && <TableEmptyPlaceholder content="No classifications have been created yet." />}
            </Box>
          }
        />
      </Box>

      <DeleteClassificationDialog
        open={!!deletingClassificationId}
        // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
        classificationId={deletingClassificationId}
        // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        name={deletingClassificationName}
        onClose={handleCancelDelete}
        onDeleteComplete={handleDeleteSuccessful}
      />
    </Box>
  );
};

const ClassificationsPage = () => {
  return (
    <ClassificationsProvider>
      <Classifications />
    </ClassificationsProvider>
  );
};

export default ClassificationsPage;
