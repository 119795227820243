import Link from '@tiptap/extension-link';
import { Placeholder } from '@tiptap/extension-placeholder';
import { EditorEvents, EditorOptions, useEditor } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import { useEffect } from 'react';


/**
 * Configuration options for the text editor.
 * Comes with all defaults used within Capsule.
 */
export type UseTextEditorOptions = {
  /**
   * Displayed when there is no value in the text editor.
   */
  placeholder?: string;
  /**
   * Triggered whenever the value is change.
   * @param value - New value of the text editor as HTML.
   */
  onChange?: (value: string) => void;
  /**
   * Initial value of the text editor either HTML or plain text string.
   */
  value?: string;
} & Partial<Omit<EditorOptions, 'content' | 'onUpdate'>>;

export const useTextEditor = ({
  placeholder,
  onChange,
  value,
  editable = true,
  ...textEditorOptions
}: UseTextEditorOptions) => {

  const editor = useEditor({
    content: value,
    editable,
    immediatelyRender: true,
    shouldRerenderOnTransaction: false,
    extensions: [
      Placeholder.configure({ placeholder }),
      StarterKit.configure({
        heading: {
          levels: [1, 2, 3]
        },
        code: false,
        codeBlock: false
      }),
      Link.configure({ openOnClick: false, })
    ],
    onUpdate: ({ editor }: EditorEvents['update']) => {

      const html = editor.getHTML();
      onChange?.(html);
    },
    ...textEditorOptions
  });

  useEffect(() => {
    if (!editor) return;

    if (!value && editor.isEmpty) return;

    if (value === editor.getHTML()) return;

    const { from, to } = editor.state.selection;
    editor.commands.setContent(value ?? '', false);

    // force text editor to maintain selection after controlling content update
    editor.commands.setTextSelection({ from, to });
  }, [value, editor]);

  useEffect(() => {
    if (!editor) return;

    editor.setOptions({ editable });
  }, [editor, editable]);

  // this provides a mechanism to make the placeholder reactive
  useEffect(() => {
    if (editor !== null && placeholder !== '') {
      editor.extensionManager.extensions.filter(
        (extension) => extension.name === 'placeholder'
      )[0].options['placeholder'] = placeholder;
      editor.view.dispatch(editor.state.tr);
    }
  }, [editor, placeholder]);

  return { editor };
};
