import { useErrorHandler } from '@shared/errors';
import React from 'react';
import { HiOutlineArrowDownTray } from 'react-icons/hi2';

import { Button } from '@/components-new/button';
import { useNetCostComparisonService } from '@/features/bid-analysis/api/net-cost-comparison-service';

import { useBidAnalysisDetails } from '../../../bid-analysis-details.provider';

export const ExportToCsvButton = () => {
  const { bidAnalysis } = useBidAnalysisDetails();
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const { exportToCsv } = useNetCostComparisonService(bidAnalysis.id);
  const { handleError } = useErrorHandler();

  const onExportNetCostComparison = async () => {
    try {
      const csv = await exportToCsv();

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(csv.contents);
      link.download = csv.filename;
      link.click();
    } catch (ex) {
      handleError(
        ex as Error,
        {
          title: 'Export to CSV Failed',
          message: 'We encountered an unexpected error while exporting the market basket. Please try again or contact an administrator.',
        }
      );
    }
  };

  return (
    <Button
      aria-label="Export to CSV"
      title="Export to CSV"
      onClick={() => onExportNetCostComparison()}
    >
      <HiOutlineArrowDownTray/>
      Export
    </Button>
  );
};
