import { CalendarPageContents } from '@pages/(protected)/calendar/(index)/_calendar';
import React, { Suspense } from 'react';
import { LiaCalendar
} from 'react-icons/lia';

import { EmptyState, EmptyStateBody, EmptyStateHeading } from '@/components-new/empty-state';
import { OverlayLoader } from '@/components-new/overlay-loader';
import { TextLink } from '@/components-new/text';
import { useUserSettings } from '@/features/user-settings/api/api';

const CalendarPage = () => {
  const { data: userSettings } = useUserSettings();
  const hasPriorityStates = userSettings.priorityStates.length > 0;
  const emptyState = !hasPriorityStates ? (
    <EmptyState>
      <LiaCalendar className="size-12 text-gray-500"/>
      <EmptyStateHeading>No priority states</EmptyStateHeading>
      <EmptyStateBody>
        You don't have any priority states set up. Go to your <TextLink href="/user-settings">settings</TextLink> to add some.
      </EmptyStateBody>
    </EmptyState>
  ) : undefined;

  return (
    <Suspense fallback={<OverlayLoader />}>
      <CalendarPageContents
        mode="priority"
        hint="Meetings pertaining to your tracked drugs in your priority states."
        emptyState={emptyState}
      />
    </Suspense>
  );
};

export default CalendarPage;
