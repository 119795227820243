import { z } from 'zod';

export type Lookup<T = number> = {
  id: T;
  label: string;
  nested?: boolean;
}

export const idBasedLookupSchema = z.object({
  id: z.number(),
  label: z.string(),
});

/**
 * A lookup for an entity that uses an integer id as its unique identifier.
 */
export type IdBasedLookup = z.infer<typeof idBasedLookupSchema>;

export const codeBasedLookupSchema = z.object({
  code: z.string(),
  label: z.string(),
});

/**
 * A lookup for an entity that uses a code as its unique identifier. e.g., an
 * enum or U.S. state.
 */
export type CodeBasedLookup = z.infer<typeof codeBasedLookupSchema>;


export const slugBasedLookupSchema = z.object({
  /**
   * @deprecated Entities with unique slugs should use the `slug` in place of
   * the id. This will be removed once the application is updated to use slugs
   * for these entities.
   */
  id: z.number(),
  slug: z.string(),
  label: z.string(),
});

/**
 * A lookup for an entity that uses a slug as its unique identifier.
 */
export type SlugBasedLookup = z.infer<typeof slugBasedLookupSchema>;
