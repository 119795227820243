import { Box } from 'grommet';
import React from 'react';
import { HiOutlinePlus } from 'react-icons/hi2';

import { InMemoryMultiSelectList } from '@/components/form-controls';
import { Button } from '@/components-new/button';
import { Lookup } from '@/types/lookup';

type AddColumnsFormProps = {
  value: Lookup<string>[];
  options: Lookup<string>[];
  onChange: (newValue: Lookup<string>[]) => void;
  onAdd: () => void;
};

export const AddColumnsForm = ({ value, options, onChange, onAdd }: AddColumnsFormProps) => {
  return (
    <Box direction="row" gap="small" align="end">
      <InMemoryMultiSelectList
        name="columnsToAdd"
        label="Add Columns"
        options={options}
        value={value}
        onChange={onChange}
        width="100%"
      />
      <Button
        className="size-max"
        onClick={onAdd}
      >
        <HiOutlinePlus/>
        Add
      </Button>
    </Box>
  );
};
