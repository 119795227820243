import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

import { ComboBoxItem } from '@/components/combo-box';
import { Lookup } from '@/types/lookup';

export type ProductFormValue = {
  name: string;
  displayAsBrand: boolean;
  genericName?: string;
  client: Lookup;
  classification?: ComboBoxItem;
  drugPackagingsFile: File[];
};

type ProductFormContextValue = {
  form: ProductFormValue;
  updateForm: Dispatch<SetStateAction<ProductFormValue>>;
  resetForm: () => void;
};

const DEFAULT_CONTEXT_VALUE: ProductFormContextValue = {
  form: {
    name: '',
    displayAsBrand: false,
    genericName: undefined,
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Lookup<numb... Remove this comment to see the full error message
    client: null,
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'ComboBoxIte... Remove this comment to see the full error message
    classification: null,
    drugPackagingsFile: []
  },
  updateForm: () => null,
  resetForm: () => null,
};

const ProductFormContext = createContext<ProductFormContextValue>(DEFAULT_CONTEXT_VALUE);

export const useProductForm = () => useContext<ProductFormContextValue>(ProductFormContext);

type ProductFormProps = {
  children?: React.ReactNode
};

export const ProductFormProvider = ({ children }: ProductFormProps) => {
  const [form, setForm] = useState(DEFAULT_CONTEXT_VALUE.form);

  const resetForm = () => {
    setForm(DEFAULT_CONTEXT_VALUE.form);
  };

  const contextValue: ProductFormContextValue = {
    form,
    updateForm: setForm,
    resetForm
  };

  return (
    <ProductFormContext.Provider value={contextValue}>
      {children}
    </ProductFormContext.Provider>
  );
};
