import { useAsync, UseAsyncStatus } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import React, { useEffect } from 'react';

import { useMarketBasketService } from '@/features/market-baskets';
import { DrugLookupDialog } from '@/features/market-baskets/components/drug-lookup-dialog';
import { DRUG_LOOKUP_ASSOCIATION } from '@/features/market-baskets/types/drug-lookup-associations';
import { MarketBasketDrugPackaging } from '@/features/market-baskets/types/market-basket';
import { notifySuccess } from '@/lib/notification/notifications';
import { useProductsService } from '@/products/api/use-products-service';

type AssociateNdcsModalProps = {
  onSubmitComplete: () => void;
  onClose: () => void;
  open: boolean;
  selectedDrugPackagings?: MarketBasketDrugPackaging[];
};

/**
 * Displays a modal prompting the user to select a product to associate the
 * selected NDCs with.
 */
export const AssociateNdcsDialog = (props: AssociateNdcsModalProps) => {
  const {
    onSubmitComplete,
    onClose,
    open,
    selectedDrugPackagings = [],
  } = props;

  const createProductAndAssociatePackages = async (productName: string, clientId: number, packagings: MarketBasketDrugPackaging[]) => {
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'File[]'.
    const { id } = await createProduct({ name: productName, client: { id: clientId, label: '' }, drugPackagingsFile: null });

    await associateUnknownPackagings(id, packagings.map(p => p.ndc));
  };

  const { handleError } = useErrorHandler();
  const { associateUnknownPackagings } = useMarketBasketService();
  const { createProduct } = useProductsService();

  const createProductAndLinkUnknownPackagings = useAsync(createProductAndAssociatePackages);
  const linkUnknownPackagings = useAsync(associateUnknownPackagings);

  const saving = createProductAndLinkUnknownPackagings.status === UseAsyncStatus.Pending || linkUnknownPackagings.status === UseAsyncStatus.Pending;

  useEffect(() => {
    const { status, error } = linkUnknownPackagings;
    if (status === UseAsyncStatus.Pending || status === UseAsyncStatus.Idle) return;

    if (error) {
      handleError(error, { title: 'Association Failed', message: 'Unable to associate NDC(s).' });
      return;
    }

    notifySuccess({ title: 'Association Succeeded', message: `Associated ${selectedDrugPackagings.length} NDC(s).` });
    onSubmitComplete();

    // TODO: review this effect, it's missing a lot of dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkUnknownPackagings.status]);

  useEffect(() => {
    const { status, error } = createProductAndLinkUnknownPackagings;
    if (status ===  UseAsyncStatus.Pending || status === UseAsyncStatus.Idle) return;

    if (status === UseAsyncStatus.Error) {
      handleError(error, { title: 'Association Failed', message: 'Unable to associate NDC(s).' });
      return;
    }

    notifySuccess({ title: 'Success', message: `Created new product and associated ${selectedDrugPackagings.length} NDC(s).` });
    onSubmitComplete();

    // TODO: review this effect, it's missing a lot of dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createProductAndLinkUnknownPackagings.status]);

  return (
    <DrugLookupDialog
      header={`Associate ${selectedDrugPackagings.length} NDC(s)`}
      open={open}
      onSubmit={({ associationType, productName, client, product }) => {
        if (associationType === DRUG_LOOKUP_ASSOCIATION.EXISTING_PRODUCT) {
          void linkUnknownPackagings.execute(product.id, selectedDrugPackagings.map(it => it.ndc));
        } else if (associationType === DRUG_LOOKUP_ASSOCIATION.NEW_PRODUCT) {
          void createProductAndLinkUnknownPackagings.execute(productName, client?.id, selectedDrugPackagings);
        }
      }}
      onClose={onClose}
      mode="new-and-existing"
      saving={saving}
      submitButtonLabel="Associate NDC(s)"
    />
  );
};
