import { useAsync, UseAsyncStatus } from '@shared/async';
import { ProtectedRoute } from '@shared/auth';
import { useErrorHandler } from '@shared/errors';
import { useHeaderDetails } from '@shared/header';
import { Box } from 'grommet';
import { Stakeholder } from 'grommet-icons';
import React, { Suspense, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { FormCard } from '@/components/form-card';
import { OverlayLoader } from '@/components/loading';
import { useClient } from '@/features/clients/api/use-client';
import { useClientService } from '@/features/clients/api/use-clients-service';
import { ClientForm } from '@/features/clients/client-form';
import { ClientFormInputs } from '@/features/clients/models/client-form-inputs';
import { notifySuccess } from '@/lib/notification/notifications';
import { queryClient } from '@/lib/react-query';
import { useParams } from '@/router';

type Props = {
  clientId: number;
}

const EditClientScreen = ({ clientId }: Props) => {
  const { updateClient } = useClientService();
  const { handleError } = useErrorHandler();
  const { setHeaderDetails, clearHeaderDetails } = useHeaderDetails();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: client } = useClient(clientId);

  const updateClientAsync = useAsync(updateClient);
  const saving = updateClientAsync.status === UseAsyncStatus.Pending;

  React.useEffect(() => {
    const breadcrumbs = client ? [
      { icon: Stakeholder, label: 'Clients', url: '/clients' },
      { label: client.name, url: `/clients/${client.id}` },
      { label: 'Edit', url: '' }
    ] : [];

    setHeaderDetails({
      documentTitle: 'Edit Client',
      pageTitle: 'Edit Client',
      breadcrumbs
    });

    return () => {
      clearHeaderDetails();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  useEffect(() => {
    const { status, error } = updateClientAsync;
    if (status === UseAsyncStatus.Pending || status === UseAsyncStatus.Idle) return;

    if (status === UseAsyncStatus.Error) {
      handleError(error, { title: 'Update Client Failed', message: 'Unable to update Client' });
      return;
    }

    void queryClient.resetQueries({ queryKey: ['client', client.id] });
    notifySuccess({ title: 'Success', message: 'Successfully updated Client.' });
    navigate(location.state?.type === 'clinicals-products-table' ? '/drug-coverage' : '/clients');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateClientAsync.status]);

  const handleFormSubmit = async (value: ClientFormInputs) => {
    await updateClientAsync.execute(clientId, value);
  };

  const handleCancel = () => {
    navigate(location.state?.referrer ? location.state.referrer : '/clients');
  };

  return (
    <Box gap="small">
      <FormCard>
        {client ? (
          <ClientForm
            defaultValues={client}
            saving={saving}
            onSubmit={handleFormSubmit}
            onCancel={handleCancel}
          />
        ) : null}
      </FormCard>
    </Box>
  );
};

const EditClientPage = () => {
  const { id } = useParams('/clients/:id/edit');

  return (
    <ProtectedRoute policies={['canManageClients']}>
      <Suspense fallback={<OverlayLoader />}>
        <EditClientScreen clientId={parseInt(id)} />
      </Suspense>
    </ProtectedRoute>
  );
};

export default EditClientPage;
