import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Notification } from '@/features/notifications/types/notification';
import { artiaProtectedApi } from '@/lib/api-client';

const updateNotifications = async (operation: 'read' | 'unread' | 'delete' | 'undelete', ids: number[]): Promise<void> => {
  await artiaProtectedApi.post('notifications', {
    operation,
    ids
  });
};

export const useUpdateNotifications = () => {
  const queryClient = useQueryClient();

  return useMutation({
      mutationFn: ({ operation, ids }: { operation: 'read' | 'unread' | 'delete' | 'undelete', ids: number[] }) => updateNotifications(operation, ids),
      onMutate: ({ operation, ids }) => {
        const notifications = queryClient.getQueryData<Notification[]>(['user-notifications']);

        if (!notifications) {
          return { notificationData: notifications };
        }

        const mutatedNotifications: Notification[] = [];
        for (const notification of notifications) {
          if (ids.includes(notification.id)) {
            if (operation === 'read') {
              mutatedNotifications.push({ ...notification, read: true });
            } else if (operation === 'unread') {
              mutatedNotifications.push({ ...notification, read: false });
            } else if (operation === 'delete') {
              mutatedNotifications.push({ ...notification, deleted: true });
            } else if (operation === 'undelete') {
              mutatedNotifications.push({ ...notification, deleted: false });
            }
          } else {
            mutatedNotifications.push(notification);
          }
        }

        queryClient.setQueryData<Notification[]>(['user-notifications'], mutatedNotifications);

        return { notificationData: mutatedNotifications };
      }
    }
  );
};
