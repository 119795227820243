import React from 'react';

import { Heading } from '@/components-new/heading';
import { Text } from '@/components-new/text';
import { CurrentUser } from '@/types/me';

export const WelcomeMessage = ({ user, isClientUser }: { user: CurrentUser, isClientUser: boolean }) => {
  const name = user.profile.name;

  return (
    <div className="overflow-hidden rounded-lg border border-gray-300 bg-white">
      <div className="flex flex-col items-center gap-4 px-6 py-5 sm:flex-row">
        <img
          className="h-14 w-auto"
          src="/artia-capsule-logo.png"
          alt="Artia Capsule logo"
        />
        <div>
          <Heading level={3} className="font-serif">Welcome back, {name}.</Heading>
          {isClientUser ? (
            <Text>Quickly access drug coverage and upcoming meeting information.</Text>
          ) : (
            <Text>Quickly access your most important tasks.</Text>
          )}
        </div>
      </div>
    </div>
  );
};
