import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useAuth } from 'react-oidc-context';

import { makeMeQueryKey, useMe } from '@/features/home/api/get-me';
import { makeCurrentUser } from '@/hooks/use-current-user';
import { CurrentUser } from '@/types/me';

type UseAuthFlow = (
  { isAuthenticated: true; error?: never; user: CurrentUser; } |
  { isAuthenticated: boolean; error?: Error | null; user?: CurrentUser; }
  ) & {
  /**
   * Sign the user in via redirect.
   */
  signInAsync: () => Promise<void>;
  /**
   * Sign the user out via redirect.
   */
  signOutAsync: () => Promise<void>;
  /**
   * Sign the user in without a redirect.
   */
  signInSilentAsync: () => Promise<void>,
  /**
   * Whether the user is currently being authenticated.
   */
  isAuthenticating: boolean;
  /**
   * Whether the users additional details are loading.
   */
  isLoadingUserDetails: boolean;
}


/**
 * Handle use authentication flows and management.
 */
export const useAuthFlow = (): UseAuthFlow => {
  const {
    isAuthenticated,
    user,
    error,
    isLoading,
    signinRedirect,
    signoutRedirect,
    signinSilent,
  } = useAuth();

  const { data: me, error: meError, isLoading: isMeLoading } = useMe({ user });
  const queryClient = useQueryClient();

  const signInAsync = useCallback(async () => {
    await queryClient.resetQueries({ queryKey: makeMeQueryKey() });
    await signinRedirect();
  }, [queryClient, signinRedirect]);

  const signOutAsync = useCallback(async () => {
    await queryClient.cancelQueries({ queryKey: makeMeQueryKey() });
    queryClient.removeQueries({ queryKey: makeMeQueryKey() });
    await signoutRedirect();
  }, [queryClient, signoutRedirect]);

  const signInSilentAsync = useCallback(async () => {
    try {
      await queryClient.resetQueries({ queryKey: makeMeQueryKey() });
      await signinSilent();
    } catch (error) {
      console.error('Failed silent signin: ', error);
      await signInAsync();
    }
  }, [queryClient, signInAsync, signinSilent]);

  return {
    isAuthenticated: isAuthenticated,
    isAuthenticating: isLoading,
    isLoadingUserDetails: isMeLoading,
    error: error || meError,
    signInAsync,
    signOutAsync,
    signInSilentAsync,
    user: (user && me) ? makeCurrentUser(me, user) : undefined,
  };
};
