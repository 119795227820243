import { useAsync, UseAsyncStatus } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { Box, Text } from 'grommet';
import React, { useEffect } from 'react';

import { Busy } from '@/components/busy';
import { Dialog, DialogActions, DialogBody, DialogHeader } from '@/components/dialog';
import { Button } from '@/components-new/button';
import { useBidRecommendationsService } from '@/features/bid-analysis/api/bid-recommendations-service';
import { BidRecommendation } from '@/features/bid-analysis/types/bid-recommendation';
import { notifySuccess } from '@/lib/notification/notifications';

type DeletePackagingsDialogProps = {
  open: boolean;
  bidAnalysisId: number;
  bidRecommendation: BidRecommendation;
  packagingIds: number[];
  onClose: () => void;
  onRemoveComplete: () => void;
};

export const DeletePackagingsDialog = (props: DeletePackagingsDialogProps) => {
  const {
    open,
    bidAnalysisId,
    bidRecommendation,
    packagingIds,
    onClose,
    onRemoveComplete
  } = props;

  const { deleteHistoricalPackagings } = useBidRecommendationsService();
  const { handleError } = useErrorHandler();

  const deleteHistoricalPackagingsAsync = useAsync(deleteHistoricalPackagings);
  const deleting = deleteHistoricalPackagingsAsync.status === UseAsyncStatus.Pending;

  useEffect(() => {
    const { status, error } = deleteHistoricalPackagingsAsync;
    if (status === UseAsyncStatus.Pending || status === UseAsyncStatus.Idle) return;

    if (status === UseAsyncStatus.Error) {
      handleError(error, { title: 'Failed to delete packagings', message: 'Unable to delete selected packagings from Historical Bid.' });

      return;
    }

    notifySuccess({ title: 'Deleted successfully', message: 'Successfully deleted packagings from Historical Bid.' });
    onRemoveComplete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteHistoricalPackagingsAsync.status]);

  const handleDelete = () => {
    void deleteHistoricalPackagingsAsync.execute(bidAnalysisId, bidRecommendation.id, packagingIds);
  };

  return (
    <Dialog open={open} width="large">
      {/* @ts-expect-error TS(2322): Type 'false | (() => void)' is not assignable to t... Remove this comment to see the full error message */}
      <DialogHeader title="Delete Packagings" onClose={!deleting && onClose}/>
      <DialogBody>
        <Box>
          <Text>Are you sure you want to delete <span>{packagingIds?.length === 1 ? 'this packaging': `${packagingIds?.length} packagings`}</span>?</Text>
        </Box>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={onClose} disabled={deleting}>Cancel</Button>
        <Button
          color="red"
          onClick={handleDelete}
          disabled={deleting}
        >
          <Busy busy={deleting} content="Delete" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
