import { isEqual } from 'lodash';
import React from 'react';

import { DebounceState, useDebounceCallback } from '@/hooks/use-debounce-callback';

type UseDebounceValueOptions<T> = {
  leading?: boolean;
  trailing?: boolean;
  maxWait?: number;
  equalityFn?: (value: T, other: T) => boolean;
}

export const useDebounceValue = <T>(
  initialValue: T,
  delay?: number,
  options?: UseDebounceValueOptions<T>
): [T, DebounceState<(value: T) => void>] => {
  const checkEquality = options?.equalityFn ?? isEqual;
  const unwrappedInitialValue = initialValue instanceof Function
    ? initialValue()
    : initialValue;

  const [debouncedValue, setDebouncedValue] = React.useState<T>(unwrappedInitialValue);
  const previousValueRef = React.useRef<T | undefined>(unwrappedInitialValue);

  const updatedDebouncedValue = useDebounceCallback(
    setDebouncedValue,
    delay,
    options,
  );

  // update the debounced value if the initial value changes
  if (!checkEquality(previousValueRef.current as T, unwrappedInitialValue)) {
    updatedDebouncedValue(unwrappedInitialValue);
    previousValueRef.current = unwrappedInitialValue;
  }

  return [debouncedValue, updatedDebouncedValue];
};
