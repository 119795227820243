import { useAuthorization } from '@shared/auth/use-authorization';
import { keepPreviousData } from '@tanstack/react-query';
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState
} from '@tanstack/react-table';
import { isEmpty, isNil, toString } from 'lodash';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Subscription } from 'react-hook-form/dist/utils/createSubject';
import { HiMagnifyingGlass, HiOutlinePencil, HiOutlinePlus, HiOutlineTrash } from 'react-icons/hi2';
import { LiaPillsSolid } from 'react-icons/lia';

import { Breadcrumbs } from '@/components-new/breadcrumbs';
import { Button } from '@/components-new/button';
import { ConfirmDeleteDialog } from '@/components-new/confirm-delete-dialog';
import { DataPaginator } from '@/components-new/data-paginator';
import { DataTable } from '@/components-new/data-table';
import { DrugName } from '@/components-new/drugs';
import { EmptyState, EmptyStateBody, EmptyStateHeading } from '@/components-new/empty-state';
import { Field, FieldGroup } from '@/components-new/fieldset';
import { Input, InputGroup } from '@/components-new/input';
import { Loader } from '@/components-new/loader';
import { Overlay } from '@/components-new/overlay';
import { Page } from '@/components-new/page';
import { PageTitleRow } from '@/components-new/page-title-row';
import { TextLink } from '@/components-new/text';
import { useDeleteDrug, useDrugsQuery } from '@/features/drugs/api';
import { DrugBase } from '@/features/drugs/types/drug';
import { useDebounceValue } from '@/hooks/use-debounce-value';
import { useUnmount } from '@/hooks/use-unmount';
import { useTable } from '@/lib/react-table';
import { insertIf } from '@/utils/arrays';
import { drugName } from '@/utils/drugs';

const columnHelper = createColumnHelper<DrugBase>();

type FilterForm = {
  name?: string;
  ndc?: string;
};

const DrugsPage = () => {
  const { checkPolicies } = useAuthorization();
  const canManageDrugs = checkPolicies(['canManageDrugs']);
  const isArtiaUser = checkPolicies(['isAnyArtiaUser']);

  const filtersWatchSubscriptionRef = React.useRef<Subscription | undefined>();
  const filtersForm = useForm<FilterForm>();

  const [filters, setFilters] = useDebounceValue(filtersForm.getValues(), 350);
  const filtersEmpty = Object.values(filters).every(isEmpty);
  const { watch } = filtersForm;

  const [drugForDelete, setDrugForDelete] = React.useState<DrugBase | undefined>();

  React.useEffect(() => {
    filtersWatchSubscriptionRef.current = watch(setFilters);
  }, [setFilters, watch]);

  useUnmount(() => {
    if (filtersWatchSubscriptionRef.current) {
      filtersWatchSubscriptionRef.current.unsubscribe();
    }
  });

  const {
    data: drugs,
    isFetching: isLoadingDrugs
  } = useDrugsQuery({
    filters,
    queryConfig: {
      placeholderData: keepPreviousData,
    }
  });

  const showDrugsLoader = isLoadingDrugs;
  const hasDrugs = !isEmpty(drugs) && !isNil(drugs);
  const showDrugsEmptyState = !hasDrugs && !showDrugsLoader;

  const {
    mutate: deleteDrug,
    isPending: isDeletingDrug,
  } = useDeleteDrug();

  const [sorting, setSorting] = React.useState<SortingState>([{ id: 'name', desc: false }]);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const columns = useMemo(() => [
    columnHelper.accessor(
      'name',
      {
        header: 'Name',
        sortUndefined: 'last',
        sortDescFirst: false,
        cell: ({ row }) => (
          <TextLink href="/drugs/:id" params={{ id: row.original.id.toString() }}>
            <DrugName drug={row.original} />
          </TextLink>
        )
      }
    ),
    columnHelper.accessor(
      (row) => row.classification?.name ?? '',
      {
        id: 'classification',
        header: 'Classification',
        sortUndefined: 'last',
        sortDescFirst: false,
        cell: info => info.getValue() ?? ''
      }
    ),
    ...insertIf(
      canManageDrugs,
      columnHelper.display({
        id: 'actions',
        header: () => (
          <span className="sr-only">Actions</span>
        ),
        cell: ({ row }) => (
          <div className="flex justify-end">
            <Button
              plain
              title={`Edit ${drugName(row.original)}`}
              href={{
                to: '/drugs/:id/edit',
                params: { id: toString(row.original.id) },
                state: { referrer: '/drugs' }
              }}
              aria-label={`Edit ${drugName(row.original)}`}
            >
              <HiOutlinePencil/>
            </Button>

            <Button
              plain
              title={`Delete ${drugName(row.original)}`}
              aria-label={`Delete ${drugName(row.original)}`}
              onClick={() => setDrugForDelete(row.original)}
            >
              <HiOutlineTrash/>
            </Button>
          </div>
        )
      })
    )
  ], [canManageDrugs]);

  const table = useTable({
    data: drugs ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableSorting: true,
    state: {
      pagination,
      sorting,
    }

  });

  const handleDelete = (drug: DrugBase) => {
    deleteDrug(
      drug,
      {
        onSuccess: () => {
          setDrugForDelete(undefined);
        }
      }
    );
  };

  const clearFilters = () => {
    filtersForm.reset();
  };

  return (
    <Page
      title="Drugs"
      header={({ title }) => (
        <>
          <Breadcrumbs breadcrumbs={[{ label: 'Drug', url: '/drugs' }]} />
          <PageTitleRow title={title}>
            {canManageDrugs && (
              <Button
                color="secondary"
                aria-label="Add Drug"
                href="/drugs/new"
              >
                <HiOutlinePlus/>
                Add New
              </Button>
            )}
          </PageTitleRow>
        </>
      )}
    >
      <div className="my-4 flex flex-col justify-between gap-4 sm:flex-row">
        <FieldGroup className="max-w-2xl flex-1">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <Controller
              control={filtersForm.control}
              defaultValue=""
              render={({ field }) => (
                <Field>
                  <InputGroup>
                    <HiMagnifyingGlass />
                    <Input {...field} type="search" placeholder="Search By Drug Name"/>
                  </InputGroup>
                </Field>
              )}
              name="name"
            />
            {isArtiaUser && <Controller
              control={filtersForm.control}
              defaultValue=""
              render={({ field }) => (
                <Field>
                  <InputGroup>
                    <HiMagnifyingGlass />
                    <Input {...field} type="search" placeholder="Search By NDC"/>
                  </InputGroup>
                </Field>
              )}
              name="ndc"
            />}
          </div>
        </FieldGroup>
      </div>
      <DataTable
        table={table}
        placeholder={() => (
          <div className="relative flex h-48 items-center justify-center">
            {showDrugsLoader && (
              <Overlay>
                <Loader message="Loading drugs..."/>
              </Overlay>
            )}
            {
              showDrugsEmptyState && (
                <EmptyState>
                  <LiaPillsSolid className="size-12 text-gray-500"/>
                  <EmptyStateHeading>No drugs</EmptyStateHeading>
                  <EmptyStateBody>
                    {
                      filtersEmpty
                        ? 'There are no drugs available.'
                        : 'Try adjusting your search and try again.'
                    }
                  </EmptyStateBody>
                </EmptyState>
              )
            }
          </div>
        )}
      />
      <DataPaginator table={table} />

      <ConfirmDeleteDialog
        item={drugForDelete}
        title="Delete Drug"
        message={`Are you sure you want to delete ${drugName(drugForDelete)}?`}
        deleteButtonTitle="Delete Drug"
        isDeleting={isDeletingDrug}
        onClose={() => setDrugForDelete(undefined)}
        onDeleteConfirmed={handleDelete}
      />
    </Page>
  );
};

export default DrugsPage;
