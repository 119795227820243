import React, { useRef } from 'react';

/**
 * Runs a cleanup function when a component unmounts.
 * @param func - Cleanup function to execute.
 */
export const useUnmount = (func: () => void) => {
  const funcRef = useRef(func);

  funcRef.current = func;

  React.useEffect(() => {
    return () => {
      funcRef.current();
    };
  }, []);
};
