import { flexRender, Header, Row, RowData, Table as TableInstance } from '@tanstack/react-table';
import clsx from 'clsx';
import { isFunction } from 'lodash';
import React from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi2';

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components-new/table';

const SortDirection = {
  ascending: 'asc',
  descending: 'desc',
} as const;

const SortAscIcon = () => <HiChevronUp className="size-4"/>;
const SortDescIcon = () => <HiChevronDown className="size-4"/>;

type SortIconProps = {
  isSorted: false | typeof SortDirection[keyof typeof SortDirection];
  sortDirection: string
  nextSortDirection: string
}

const SortIcon = ({ isSorted, sortDirection, nextSortDirection }: SortIconProps) => {
  return (
    <span
      className={clsx(
        'ml-2 flex-none rounded p-0.5',
        isSorted && 'bg-gray-100 text-gray-900 group-hover:bg-gray-200',
        !isSorted && 'invisible text-gray-400 group-hover:visible group-focus:visible'
      )}
    >
        {isSorted && (
          sortDirection === SortDirection.ascending
            ? <SortAscIcon/>
            : <SortDescIcon/>
        )}
      {!isSorted && (
        nextSortDirection === SortDirection.ascending
          ? <SortAscIcon/>
          : <SortDescIcon/>
      )}
      </span>
  );
};

const TableHeaderCell = <T, >({ header }: { header: Header<T, unknown> }) => {
  const isColumnSortable = header.column.getCanSort();
  const nextSortDirection = header.column.getNextSortingOrder() as string;
  const isSorted = header.column.getIsSorted();
  const currentSortDirection = isSorted as string;

  const getTitle = () => {
    if (!isColumnSortable) return undefined;

    if (nextSortDirection === SortDirection.ascending) return 'Sort ascending';

    if (nextSortDirection === SortDirection.descending) return 'Sort descending';

    return 'Clear sort';
  };

  return (
    header.isPlaceholder ?
      null : (
        <div
          className={clsx(isColumnSortable && 'group cursor-pointer select-none')}
          onClick={header.column.getToggleSortingHandler()}
          title={getTitle()}
        >
          <div className="flex items-center">
            {flexRender(
              header.column.columnDef.header,
              header.getContext()
            )}
            {isColumnSortable && (
              <SortIcon
                isSorted={isSorted}
                nextSortDirection={nextSortDirection}
                sortDirection={currentSortDirection}
              />
            )}
          </div>
        </div>
      )
  );
};

type DataTableProps<T extends RowData> = {
  /**
   * The instance of the headless table.
   */
  table: TableInstance<T>
  /**
   * Placeholder rendered when there is no data.
   */
  placeholder?: ((table: TableInstance<T>) => React.ReactNode) | React.ReactNode,
  /**
   * Override for rendering a row.
   */
  rowRenderer?: ({ table, row }: { table: TableInstance<T>, row: Row<T>, tableRowBodyProps: Record<string, any> }) => React.ReactNode,
  /**
   * Whether to display the aggregate row when grouping.
   */
  showAggregateGroupRow?: boolean,
  /**
   * Props to compute and pass to the row during render.
   */
  tableBodyRowProps?: ({ table, row }: { table: TableInstance<T>, row: Row<T> }) => Record<string, any>,
}

/**
 * Renders a managed data table.
 */
const DataTable = <T extends RowData>({
  table,
  placeholder,
  rowRenderer,
  showAggregateGroupRow = false,
  tableBodyRowProps
}: DataTableProps<T>) => {
  return (
    <Table>
      <TableHead>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeader
                  colSpan={header.colSpan}
                  key={header.id}
                  className={header.column.columnDef.meta?.className}>
                  <TableHeaderCell header={header}/>
                </TableHeader>
              ))}
            </TableRow>
          ))}
      </TableHead>
      <TableBody>
        {table.getRowModel().rows.map(row => {
          const rowProps = tableBodyRowProps ?
            tableBodyRowProps({ table, row }) :
            {};

          if (!showAggregateGroupRow && row.getIsGrouped()) return null;

          if (rowRenderer) {
            return rowRenderer({ table, row, tableRowBodyProps: rowProps });
          }

          return (
            <TableRow key={row.id} {...rowProps}>
              {row.getVisibleCells().map((cell) => {
                const cellProps = cell.column.columnDef.meta?.cellProps ?
                  cell.column.columnDef.meta?.cellProps({ table, row }) :
                  {};

                return (
                  <TableCell key={cell.id} className={cell.column.columnDef.meta?.className} {...cellProps}>
                    {
                      flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
        {table.getRowModel().rows.length === 0 && placeholder && (
          <TableRow>
            <TableCell colSpan={table.getAllColumns().length}>
              {isFunction(placeholder) ? placeholder(table) : placeholder}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
    );
  };

  export {
    DataTable
  };
