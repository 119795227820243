import { useQuery } from '@tanstack/react-query';

import { artiaProtectedApi } from '@/lib/api-client';
import { State } from '@/types/state';

const getStates = async (): Promise<State[]> => {
  return await artiaProtectedApi.get('states');
};

export const useGetStates = () => {
  return useQuery({
    queryKey: ['states'],
    queryFn: async () => getStates()
  });
};
