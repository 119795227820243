import { useErrorHandler } from '@shared/errors';
import React, { createContext, useContext, useState } from 'react';

import { useMedicaidDatasetsService } from '@/features/medicaid-datasets/api/use-medicaid-datasets-service';
import { MedicaidDataset } from '@/features/medicaid-datasets/types/medicaid-dataset';
import { RefreshMedicaidDatasetRequest } from '@/features/medicaid-datasets/types/refresh-medicaid-dataset-request';
import { notifySuccess } from '@/lib/notification/notifications';

type MedicaidDatasetsContextValue = {
  medicaidDatasets: MedicaidDataset[];
  loadingMedicaidDatasets: boolean;
  loadMedicaidDatasets: () => void;
  deleteMedicaidDataset: (id: string) => void;
  refreshingMedicaidDataset: string;
  refreshMedicaidDataset: (id: string, request: RefreshMedicaidDatasetRequest) => void;
};

const DEFAULT_CONTEXT_VALUE: MedicaidDatasetsContextValue = {
  medicaidDatasets: [],
  loadingMedicaidDatasets: false,
  loadMedicaidDatasets: () => null,
  deleteMedicaidDataset: () => null,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
  refreshingMedicaidDataset: null,
  refreshMedicaidDataset: () => null
};

const MedicaidDatasetsContext = createContext<MedicaidDatasetsContextValue>(DEFAULT_CONTEXT_VALUE);

// eslint-disable-next-line react-refresh/only-export-components
export const useMedicaidDatasets = () => useContext<MedicaidDatasetsContextValue>(MedicaidDatasetsContext);

type MedicaidDatasetsProps = {
  children?: React.ReactNode
};

export const MedicaidDatasetsProvider = ({ children }: MedicaidDatasetsProps) => {
  const { handleError } = useErrorHandler();
  const { getMedicaidDatasets, deleteMedicaidDataset, refreshMedicaidDataset } = useMedicaidDatasetsService();

  const [loadingMedicaidDatasets, setLoadingMedicaidDatasets] = useState(false);
  const [medicaidDatasets, setMedicaidDatasets] = useState<MedicaidDataset[]>([]);
  // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  const [refreshingMedicaidDataset, setRefreshingMedicaidDataset] = useState<string>(null);

  const loadMedicaidDatasets = async () => {
    setLoadingMedicaidDatasets(true);

    try {
      const items = await getMedicaidDatasets();
      setMedicaidDatasets(items);
    } catch (ex) {
      handleError(
        ex as Error,
        {
          title: 'Failed to Load Medicaid Datasets',
          message: 'We encountered an unexpected error while loading Medicaid Datasets. Please try again or contact an administrator.'
        }
      );
    } finally {
      setLoadingMedicaidDatasets(false);
    }
  };

  const deleteDataset = async (id: string) => {
    try {
      await deleteMedicaidDataset(id);

      notifySuccess({ title: 'Successfully Deleted', message: 'The Medicaid Dataset was successfully deleted.' });

      await loadMedicaidDatasets();
    } catch (ex) {
      handleError(
        ex as Error,
        {
          title: 'Failed to Delete Medicaid Dataset',
          message: 'We encountered an unexpected error while deleting the Medicaid Dataset. Please try again or contact an administrator.'
        }
      );
    }
  };

  const refreshDataset = async (id: string, request: RefreshMedicaidDatasetRequest) => {
    setRefreshingMedicaidDataset(id);

    try {
      await refreshMedicaidDataset(id, request);

      notifySuccess({ title: 'Refresh Queued', message: 'The Medicaid Dataset was successfully queued for refresh.' });
    } catch (ex) {
      handleError(
        ex as Error,
        {
          title: 'Failed to refresh Medicaid Dataset',
          message: 'We encountered an unexpected error while refreshing Medicaid Dataset. Please try again or contact an administrator.'
        }
      );
    } finally {
      // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      setRefreshingMedicaidDataset(null);
    }
  };

  const contextValue: MedicaidDatasetsContextValue = {
    medicaidDatasets,
    loadMedicaidDatasets,
    loadingMedicaidDatasets,
    deleteMedicaidDataset: deleteDataset,
    refreshingMedicaidDataset,
    refreshMedicaidDataset: refreshDataset
  };

  return (
    <MedicaidDatasetsContext.Provider value={contextValue}>
      {children}
    </MedicaidDatasetsContext.Provider>
  );
};
