import { Editor, EditorContent } from '@tiptap/react';
import clsx from 'clsx';
import React, { forwardRef, useEffect, useState } from 'react';
import { FaRedo, FaUndo } from 'react-icons/fa';
import { FaBold, FaItalic, FaLink, FaLinkSlash, FaListOl, FaListUl } from 'react-icons/fa6';

import { usePromptContext } from '@/components/interactions';
import { PromptFn } from '@/components/interactions/prompt-context';
import { ButtonGroup, ButtonGroupButton } from '@/components-new/button-group';
import { Field } from '@/components-new/fieldset';
import { Listbox, ListboxLabel, ListboxOption } from '@/components-new/listbox';
import { useTextEditor } from '@/components-new/rich-text-editor.hooks';

type ParagraphLevelOption = {
  label: string;
  value: 'paragraph';
}

type HeadingLevelOption = {
  label: string;
  value: 'heading';
  level: 1 | 2 | 3;
};

// option available to user for level select
const levelOptions: Array<ParagraphLevelOption | HeadingLevelOption> = [
  { label: 'Paragraph', value: 'paragraph', },
  { label: 'Heading 1', value: 'heading', level: 1 },
  { label: 'Heading 2', value: 'heading', level: 2 },
  { label: 'Heading 3', value: 'heading', level: 3, }
];

/**
 * Toolbar control that allows user to select what level of text they would like to use when typing in the editor.
 */
const LevelSelect = ({ editor }: { editor: Editor }) => {
  const [selectedLevel, setSelectedLevel] = useState(levelOptions[0]);

  const handleLevelItemSelect = (option: typeof levelOptions[number]) => {
    if (option?.value === 'paragraph') {
      editor.chain().focus().setParagraph().run();
    } else if (option?.value === 'heading') {
      editor.chain().focus().setHeading({ level: option.level }).run();
    }
  };

  useEffect(() => {
    const handleUpdate = () => {
      setSelectedLevel(currentLevel =>
        levelOptions.reduce((acc, option) => {
          if (editor) {
            if (option.value === 'paragraph' && editor.isActive('paragraph')) {
              return option;
            }

            if (editor.isActive('heading', { level: (option as HeadingLevelOption).level })) {
              return option;
            }
          }

          return acc;
        }, currentLevel)
      );
    };

    if (editor) {
      editor.on('update', handleUpdate);
      editor.on('selectionUpdate', handleUpdate);
    }

    return () => {
      if (editor) {
        editor.off('update', handleUpdate);
        editor.off('selectionUpdate', handleUpdate);
      }
    };
  }, [editor]);

  console.log({ levelOptions });

  return (
    <Field>
      <Listbox
        value={selectedLevel}
        onChange={handleLevelItemSelect}>
        {levelOptions.map((option) => (
          <ListboxOption key={option.label} value={option}>
            <ListboxLabel>{option.label}</ListboxLabel>
          </ListboxOption>
        ))}
      </Listbox>
    </Field>
  );
};

/**
 * Toolbar control that allows user to select what inline text-mark they would like to use.
 */
const TextMarkControls = ({ editor }: { editor: Editor }) => {
  return (
    <ButtonGroup>
      <ButtonGroupButton
        title="Bold"
        aria-label="Bold"
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        solid={editor.isFocused && editor.isActive('bold')}
      >
        <FaBold />
      </ButtonGroupButton>
      <ButtonGroupButton
        title="Italic"
        aria-label="Italic"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        solid={editor.isFocused && editor.isActive('italic')}
      >
        <FaItalic />
      </ButtonGroupButton>
    </ButtonGroup>
  );
};

/**
 * Toolbar control that allows the users to select the list formatting the would like to use.
 */
const ListControls = ({ editor }: { editor: Editor }) => {
  return (
    <ButtonGroup>
      <ButtonGroupButton
        title="Bullet list"
        aria-label="Bullet list"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        disabled={!editor.can().chain().focus().toggleBulletList().run()}
        solid={editor.isFocused && editor.isActive('bulletList')}
      >
        <FaListUl />
      </ButtonGroupButton>
      <ButtonGroupButton
        title="Numbered list"
        aria-label="Numbered list"
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        disabled={!editor.can().chain().focus().toggleOrderedList().run()}
        solid={editor.isFocused && editor.isActive('orderedList')}
      >
        <FaListOl />
      </ButtonGroupButton>
    </ButtonGroup>
  );
};

/**
 * Toolbar control that allows users to set and unset external links.
 */
const LinkControls = ({ editor }: { editor: Editor }) => {
  const { prompt } = usePromptContext();

  const handleSetLink = (editor: Editor, prompt: PromptFn) =>  {
    const existingHref = editor.isActive('link') ? editor.getAttributes('link').href : '';
    prompt({ title: 'Add link', text: 'Enter the URL for this link.', defaultValue: existingHref })
      .then((value: string) => {
        const trimmedValue = value.trim();

        if (trimmedValue) {
          editor.chain().focus().setLink({ href: trimmedValue }).run();
        } else if (editor.isActive('link')) {
          editor.chain().focus().unsetLink().run();
        }
      });
  };

  return (
    <ButtonGroup>
      <ButtonGroupButton
        title="Set link"
        aria-label="Set link"
        onClick={() => handleSetLink(editor, prompt)}
      >
        <FaLink />
      </ButtonGroupButton>
      <ButtonGroupButton
        title="Unset link"
        aria-label="Unset link"
        onClick={() => editor.chain().focus().unsetLink().run()}
      >
        <FaLinkSlash />
      </ButtonGroupButton>
    </ButtonGroup>
  );
};

/**
 * Toolbar control that allows user to navigate their edit history.
 */
const HistoryControls = ({ editor }: { editor: Editor }) => {
  return (
    <ButtonGroup>
      <ButtonGroupButton
        title="Undo"
        aria-label="Undo"
        onClick={() => editor.chain().undo().run()}
        disabled={!editor.can().chain().undo().run()}
      >
        <FaUndo />
      </ButtonGroupButton>
      <ButtonGroupButton
        title="Redo"
        aria-label="Redo"
        onClick={() => editor.chain().redo().run()}
        disabled={!editor.can().chain().redo().run()}
      >
        <FaRedo />
      </ButtonGroupButton>
    </ButtonGroup>
  );
};

/**
 * Toolbar used for managing the way a user wants text in the editor to look when they type it.
 */
export const RichTextEditorToolbar = ({
  editor,
  className,
}: { editor: Editor, className?: string }) => {
  return (
    <div className={clsx(className, 'w-full bg-white')}>
      <div className="flex w-full items-center gap-4">
        <LevelSelect editor={editor} />
        <TextMarkControls editor={editor} />
        <ListControls editor={editor} />
        <LinkControls editor={editor} />
        <HistoryControls editor={editor} />
      </div>
    </div>
  );
};

/**
 * Readonly preview of rich-text.
 */
export const RichTextPreview = ({ content, className }: { content: string, className?: string }) => {
  const { editor } = useTextEditor({ value: content, editable: false });

  if (!editor) return null;

  return (
    <RichTextEditorContent editor={editor} className={className} />
  );
};

/**
 * Controllable rich text content editor.
 */
export const RichTextEditorContent = forwardRef((
  { editor, className, ...props }: { editor: Editor } & React.ComponentPropsWithoutRef<'div'>,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  return (
    <EditorContent
      {...props}
      editor={editor}
      ref={ref}
      className={clsx(className, 'prose h-full overflow-auto antialiased')}
    />
  );
});

type RichTextEditorInputProps = {
  className?: string;
  disabled?: boolean;
  value?: string;
  onChange?: (event: string) => void;
  placeholder?: string;
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'>

/**
 * Write a lot of gorgeous text, you know, like a blog post 😉.
 */
export const RichTextEditorInput = forwardRef((
  {
    className,
    onChange,
    disabled,
    value,
    placeholder,
    ...props
  }: RichTextEditorInputProps,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const { editor } = useTextEditor({
    editable: !disabled,
    onChange,
    value,
    placeholder,
  });

  if (!editor) return null;

  return (
    <div
      data-slot="control"
      className={clsx([
        className,
        'flex w-full flex-col overflow-hidden',
      ])}
    >
      {!disabled && <RichTextEditorToolbar editor={editor} className="mb-2" /> }
      <RichTextEditorContent
        editor={editor}
        className={clsx([
          // Basic layout
          'relative flex size-full appearance-none flex-col rounded-lg px-[calc(theme(spacing[3.5])-1px)] py-[calc(theme(spacing[2.5])-1px)] sm:px-[calc(theme(spacing.3)-1px)] sm:py-[calc(theme(spacing[1.5])-1px)]',
          // Typography
          'text-base/6 text-zinc-950 placeholder:text-zinc-500 dark:text-white sm:text-sm/6',
          // Border
          'border border-zinc-950/10 data-[hover]:border-zinc-950/20 dark:border-white/10 dark:data-[hover]:border-white/20',
          // Background color
          'bg-transparent dark:bg-white/5',
          // focus
          'focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-500',
          // Invalid state
          'data-[invalid]:border-red-500 data-[invalid]:data-[hover]:border-red-500 data-[invalid]:dark:border-red-600 data-[invalid]:data-[hover]:dark:border-red-600',
          // Disabled state
          'disabled:border-zinc-950/20 disabled:dark:border-white/15 disabled:dark:bg-white/[2.5%] dark:data-[hover]:disabled:border-white/15',
          'prose flex-1 overflow-auto'
        ])}
        {...props}
        ref={ref}
      />
    </div>
  );
});
