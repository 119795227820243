import { ProtectedRoute } from '@shared/auth';
import { parseInt } from 'lodash';
import React, { Suspense, useState } from 'react';
import { LiaCalendar } from 'react-icons/lia';

import { TableLoadingOverlay } from '@/components/loading';
import { EmptyState, EmptyStateBody, EmptyStateHeading } from '@/components-new/empty-state';
import { SectionDescription, SectionHeading } from '@/components-new/section';
import { SegmentedControl } from '@/components-new/segmented-control';
import { TextLink } from '@/components-new/text';
import { MeetingDetailsDrawer, MeetingsList } from '@/features/calendar/components/meetings';
import { Meeting } from '@/features/calendar/types/meeting';
import { useDrug } from '@/features/drugs/api/use-drug';
import { useUpcomingMeetings } from '@/features/drugs/api/use-upcoming-meetings';
import { useUserSettings } from '@/features/user-settings/api/api';
import { useCurrentUser } from '@/hooks/use-current-user';
import { useParams } from '@/router';
import { drugName } from '@/utils/drugs';

const DrugMeetingsPage = () => {
  const { id } = useParams('/drugs/:id/meetings');
  const { data: drug } = useDrug(id);

  const [meetingsFilter, setMeetingsFilter] = useState<string>('all');

  const {
    data: meetings,
    isLoading: isMeetingsLoading,
  } = useUpcomingMeetings({
    drugId: parseInt(id),
    filters: {
      priority: meetingsFilter === 'priority'
    }
  });

  const [selectedMeeting, setSelectedMeeting] = useState<Meeting | undefined>();
  const drugDisplayName = drugName(drug);

  const showMeetingDetails = (meeting: Meeting) => {
    setSelectedMeeting(meeting);
  };


  const { user: currentUser } = useCurrentUser();
  const { data: userSettings } = useUserSettings();
  const hasPriorityStates = userSettings.priorityStates.length > 0;

  const meetingsEmptyState = !hasPriorityStates ? (
    <EmptyState>
      <LiaCalendar className="size-12 text-gray-500"/>
      <EmptyStateHeading>No priority states</EmptyStateHeading>
      <EmptyStateBody>
        You don't have any priority states set up. Go to your <TextLink href="/user-settings">settings</TextLink> to add some.
      </EmptyStateBody>
    </EmptyState>
  ) : <EmptyState>
    <LiaCalendar className="size-12 text-gray-500"/>
    <EmptyStateHeading>No upcoming meetings</EmptyStateHeading>
    <EmptyStateBody>
      There are no upcoming meetings for {drugDisplayName}.
    </EmptyStateBody>
  </EmptyState>;

  return (
    <div className="mt-4">
      {currentUser.client && (
        <SegmentedControl
          className="mb-4 mt-2"
          items={[{ label: 'All States', value: 'all' }, { label: 'My Priority States', value: 'priority' }]}
          onChange={setMeetingsFilter}
        />
      )}

      <div>
        <SectionHeading level={2}>
          Upcoming Meetings
        </SectionHeading>
        <SectionDescription>
          Upcoming meetings pertaining to {drugDisplayName} or its classification(s).
        </SectionDescription>
      </div>

      <div className="relative mt-4 min-h-20 lg:col-span-7 xl:col-span-8">
        <MeetingsList
          isLoading={isMeetingsLoading}
          meetings={meetings}
          emptyState={meetingsEmptyState}
          onShowMeetingDetails={showMeetingDetails}
        />
      </div>

      <MeetingDetailsDrawer
        meeting={selectedMeeting}
        onClose={() => setSelectedMeeting(undefined)}
      />
    </div>
  );
};

const DrugMeetingsPageWrapper = () => {
  return (
    <ProtectedRoute policies={['canViewCalendar']}>
      <Suspense fallback={<TableLoadingOverlay/>}>
        <DrugMeetingsPage/>
      </Suspense>
    </ProtectedRoute>
  );
};

export default DrugMeetingsPageWrapper;
