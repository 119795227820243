import React, { useEffect } from 'react';

import { Button } from '@/components-new/button';
import { Dialog, DialogActions, DialogBody, DialogTitle } from '@/components-new/dialog';
import { useDelayedClose } from '@/hooks/use-delayed-close';

export type DeletableItem = {
  id: any;
  name: string;
};

type ConfirmDeleteDialogProps<T extends DeletableItem> = {
  item: T | undefined;
  title: string;
  message: string;
  deleteButtonTitle: string;
  isDeleting: boolean;
  onClose: () => void;
  onDeleteConfirmed: (item: T) => void;
};

export const ConfirmDeleteDialog = <T extends DeletableItem, >({
  item,
  title,
  message,
  deleteButtonTitle,
  isDeleting,
  onClose,
  onDeleteConfirmed
}: ConfirmDeleteDialogProps<T>) => {
  const { isOpen, handleOpen, handleClose } = useDelayedClose(!!item, onClose);
  const interpolatedMessage = message.replace('{name}', item?.name ?? '');

  useEffect(() => {
    if (item) {
      handleOpen();
    }
  }, [item, handleOpen]);

  const handleDelete = () =>{
    onDeleteConfirmed(item!);
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} size="xl">
      <DialogTitle>{title}</DialogTitle>
      <DialogBody className="text-sm/6 text-zinc-900 dark:text-white">
        {interpolatedMessage}
      </DialogBody>
      <DialogActions>
        <Button plain onClick={handleClose}>Cancel</Button>
        <Button color="red" disabled={isDeleting} onClick={handleDelete}>{deleteButtonTitle}</Button>
      </DialogActions>
    </Dialog>
  );
};
