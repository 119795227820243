import { useErrorHandler } from '@shared/errors';
import { ResultSet } from '@shared/http';
import React, { createContext, useContext, useState } from 'react';

import { useBidAnalysisService } from '@/features/bid-analysis/api/use-bid-analysis-service';
import { BidAnalysisBase } from '@/features/bid-analysis/types/bid-analysis';
import { GetBidAnalysesRequest } from '@/features/bid-analysis/types/get-bid-analyses-request';

type BidAnalysesContextValue = {
  bidAnalyses: ResultSet<BidAnalysisBase>;
  loadingBidAnalyses: boolean;
  loadBidAnalyses: (request?: GetBidAnalysesRequest) => void;
};

const DEFAULT_CONTEXT_VALUE: BidAnalysesContextValue = {
  bidAnalyses: { items: [] },
  loadingBidAnalyses: false,
  loadBidAnalyses: (_?: GetBidAnalysesRequest) => null
};

const BidAnalysesContext = createContext(DEFAULT_CONTEXT_VALUE);

// eslint-disable-next-line react-refresh/only-export-components
export const useBidAnalyses = () => useContext(BidAnalysesContext);

type BidAnalysesProps = {
  children?: React.ReactNode
};

export const BidAnalysesProvider = ({ children }: BidAnalysesProps) => {
  const { getBidAnalyses } = useBidAnalysisService();
  const { handleError } = useErrorHandler();

  const [bidAnalyses, setBidAnalyses] = useState(DEFAULT_CONTEXT_VALUE.bidAnalyses);
  const [loadingBidAnalyses, setLoadingBidAnalyses] = useState(DEFAULT_CONTEXT_VALUE.loadingBidAnalyses);

  const loadBidAnalyses = async (request: GetBidAnalysesRequest) => {
    setLoadingBidAnalyses(true);

    try {
      const bidAnalyses = await getBidAnalyses(request);
      setBidAnalyses(bidAnalyses);
    } catch (ex) {
      handleError(
        ex as Error,
        {
          title: 'Failed to Load Bid Analyses',
          message: 'We encountered an unexpected error while loading Bid Analyses. Please try again or contact an administrator.'
        }
      );
    } finally {
      setLoadingBidAnalyses(false);
    }
  };

  const contextValue: BidAnalysesContextValue = {
    bidAnalyses,
    loadingBidAnalyses,
    // @ts-expect-error TS(2322): Type '(request: GetBidAnalysesRequest) => Promise<... Remove this comment to see the full error message
    loadBidAnalyses
  };

  return (
    <BidAnalysesContext.Provider value={contextValue}>
      {children}
    </BidAnalysesContext.Provider>
  );
};
