import { zodResolver } from '@hookform/resolvers/zod';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Breadcrumbs } from '@/components-new/breadcrumbs';
import { Button } from '@/components-new/button';
import { Dialog, DialogActions, DialogBody, DialogTitle } from '@/components-new/dialog';
import { CommaSeparatedList } from '@/components-new/lists';
import { Page } from '@/components-new/page';
import { useCreatePost } from '@/features/posts/api';
import { CreateUpdatePostForm } from '@/features/posts/components/create-update-post-form';
import { CreateUpdatePostPageTitle } from '@/features/posts/components/create-update-post-page-title';
import {
  CreateUpdatePostFormSchema,
  makeCreateUpdatePostFormSchema
} from '@/features/posts/types/create-post-form-schema';
import { useNavigate } from '@/router';

const NewPostPage = () => {
  const navigate = useNavigate();
  const [ showConfirmation, setShowConfirmation ] = useState(false);

  const {
    mutate: createPost,
    isPending: isCreatingPost,
  } = useCreatePost();

  const form = useForm<CreateUpdatePostFormSchema>({
    resolver: zodResolver(makeCreateUpdatePostFormSchema(false)),
    mode: 'all'
  });

  const confirmSubmit = () => {
    createPost(
      { form: form.getValues() },
      {
        onSuccess: () => {
          navigate('/posts');
        }
      }
    );
  };

  const selectedStates = form.watch('targetStates') ?? [];

  const handleSubmit = async () => {
    await form.trigger();
    if (form.formState.isValid) {
      setShowConfirmation(true);
    }
  };

  return (
    <Page
      title="Create Post"
      header={({ title }) => (
        <>
          <Breadcrumbs
            breadcrumbs={[
              { label: 'Posts', url: '/posts' },
              { label: 'Create Post', url: '/posts/new' },
            ]}
          />
          <CreateUpdatePostPageTitle title={title} />
        </>
      )}
    >
      <CreateUpdatePostForm
        form={form}
        submitButtonLabel="Save"
        isSubmitDisabled={isCreatingPost}
        onSubmit={handleSubmit}
      />

      <Dialog open={showConfirmation} onClose={() => setShowConfirmation(false)} size="2xl">
        <DialogTitle>Confirm Post</DialogTitle>
        <DialogBody className="text-sm/6 text-zinc-900 dark:text-white">
          {selectedStates.length > 0 &&
            <p>
              A notification of this post will be sent to all users subscribed to this category and have any of the following priority states:&nbsp;
              <CommaSeparatedList
                items={selectedStates}
                renderItem={(stateCode) => (<strong>{stateCode}</strong>)}
              />.
            </p>
          }
          {selectedStates.length == 0 &&
             <p>A notification of this post will be sent to all users subscribed to this category.</p>
          }
        </DialogBody>
        <DialogActions>
          <Button plain onClick={() => setShowConfirmation(false)}>Cancel</Button>
          <Button color="secondary" onClick={confirmSubmit}>Create Post</Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default NewPostPage;
