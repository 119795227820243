import { useAsync } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { Text } from 'grommet';
import React, { createContext, ReactNode, useContext, useMemo } from 'react';

import {
  drugRows,
  drugTotalRows,
  grandTotalRow,
  stateHeaderRow,
  stateTotalRow
} from '@/features/bid-analysis/api/net-cost-comparison-rows';
import { useNetCostComparisonService } from '@/features/bid-analysis/api/net-cost-comparison-service';
import { getRowState, useNetCostComparisonRowExpansion } from '@/features/bid-analysis/api/use-net-cost-comparison-row-expansion';
import {
  NdcsByStateAndProduct,
  NetCostComparisonRow,
  NetCostComparisonRowExpansionArgs, PackagingNetCostComparison, TagNetCostComparison
} from '@/features/bid-analysis/types/net-cost-comparison';

type NetCostComparisonProviderProps = {
  bidAnalysisId: number;
  children: ReactNode;
};

type NetCostComparisonContextValue = {
  rows?: NetCostComparisonRow[];
  loadingTopLevelRows: boolean;
  ndcsByStateAndProduct: NdcsByStateAndProduct;
  expandRow: (args: NetCostComparisonRowExpansionArgs) => Promise<void>;
  collapseRow: (args: NetCostComparisonRowExpansionArgs) => void;
  onUpdateRow: (row: NetCostComparisonRow, ndc: PackagingNetCostComparison) => void;
  loadTopLevelRows: () => Promise<void>;
};

const NetCostComparisonContext = createContext<NetCostComparisonContextValue | null>(null);

// eslint-disable-next-line react-refresh/only-export-components
export const useNetCostComparisonProvider = () => {
  const value = useContext(NetCostComparisonContext);

  if (!value) throw new Error('useNetCostComparisonProvider must be called within a NetCostComparisonProvider!');

  return value;
};

export const NetCostComparisonProvider = ({ bidAnalysisId, children }: NetCostComparisonProviderProps) => {
  const { getInitialView, expandProduct } = useNetCostComparisonService(bidAnalysisId);
  const initialViewRequest = useAsync(getInitialView, true);

  const { handleError } = useErrorHandler();

  const [ndcsByStateAndProduct, dispatch] = useNetCostComparisonRowExpansion();

  const rows = useMemo<NetCostComparisonRow[]>(() => {
    if (!initialViewRequest.value) return [];

    const stateRows = initialViewRequest.value.states.flatMap((summary) => [
      stateHeaderRow(summary),
      ...drugRows(summary.state, summary.drugs, ndcsByStateAndProduct),
      stateTotalRow(summary)
    ]);

    return initialViewRequest.value.states.length > 1
      // if it's a pool bid, show totals for each product across all states
      ? [
        ...stateRows,
        ...drugTotalRows(initialViewRequest.value.drugTotals),
        grandTotalRow(initialViewRequest.value.grandTotal)
      ]
      // otherwise, no need for a rollup of all products across all states + a grand total
      : stateRows;
  }, [initialViewRequest.value, ndcsByStateAndProduct]);

  const value = useMemo<NetCostComparisonContextValue>(() => {
    return {
      rows,
      loadingTopLevelRows: false,
      onUpdateRow: async (row, updatedNdc) => {

        try {
          const promises: [Promise<unknown>, Promise<TagNetCostComparison[]>?] = [initialViewRequest.execute()];
          if (row.tag) {
            promises.push(
              // don't need to pass the tag ID, we want the expanded PRODUCT, which has the list of tags
              // @ts-expect-error TS(2532): Object is possibly 'undefined'.
              expandProduct(row.state.code, row.drug.id).then(it => it.tags)
            );
          }
          const [, updatedTags] = await Promise.all(promises);
          dispatch({
            type: 'update-packaging',
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            stateCode: row.state.code,
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            productId: row.drug.id,
            tags: updatedTags,
            packagings: [updatedNdc]
          });
        } catch (e) {
          handleError(e as Error, {
            title: 'Failed to Load Net Cost Comparison',
            message: (
              <span>
                <Text weight="bold">Your changes have been saved,</Text> but an error occurred
                while fetching the updated Net Cost Comparison information.
                Please try again or contact an administrator.
              </span>
            )
          });
        }
      },
      ndcsByStateAndProduct,
      expandRow: async ({ stateCode, productId, tagId }) => {
        const row = getRowState(ndcsByStateAndProduct, { stateCode, productId, tagId });

        if (!row) {
          try {
            dispatch({ type: 'fetch', stateCode, productId, tagId });
            const { tags, packagings } = await expandProduct(stateCode, productId, tagId);
            dispatch({ type: 'update', stateCode, productId, tagId, tags, packagings });
          } catch (e) {
            dispatch({ type: 'collapse', stateCode, productId, tagId });
            // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
            handleError(e, {
              title: `Failed to Load Data for ${stateCode}`,
              message: 'We encountered an unexpected error while fetching the NDCs in this row. Please try again or contact an administrator.',
              autoClose: false
            });
          }
        } else {
          dispatch({ type: 'expand', stateCode, productId, tagId });
        }
      },
      collapseRow: (args) => {
        dispatch({ type: 'collapse', ...args });
      },
      loadTopLevelRows: async () => {
        try {
          await initialViewRequest.execute();
        } catch (e) {
          // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
          handleError(e, {
            title: 'Failed to Load Net Cost Comparison',
            message: 'We encountered an unexpected error while fetching the Net Cost Comparison information. Please try again or contact an administrator.',
            autoClose: false
          });
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, ndcsByStateAndProduct]);

  return (
    <NetCostComparisonContext.Provider value={value}>
      {children}
    </NetCostComparisonContext.Provider>
  );
};
