import { useErrorHandler } from '@shared/errors';
import React, { createContext, useContext, useState } from 'react';

import { useMarketBasketService } from '@/features/market-baskets/api/use-market-baskets-service';
import { MarketBasket } from '@/features/market-baskets/types/market-basket';

type MarketBasketsContextValue = {
  marketBaskets: MarketBasket[];
  loadingMarketBaskets: boolean;
  loadMarketBaskets: () => void;
};

const DEFAULT_CONTEXT_VALUE: MarketBasketsContextValue = {
  marketBaskets: [],
  loadingMarketBaskets: false,
  loadMarketBaskets: () => null
};

const MarketBasketsContext = createContext(DEFAULT_CONTEXT_VALUE);

// eslint-disable-next-line react-refresh/only-export-components
export const useMarketBaskets = () => useContext(MarketBasketsContext);

type MarketBasketsProps = {
  children?: React.ReactNode
};

export const MarketBasketsProvider = ({ children }: MarketBasketsProps) => {
  const { handleError } = useErrorHandler();
  const { getMarketBaskets } = useMarketBasketService();

  const [loadingMarketBaskets, setLoadingMarketBaskets] = useState(DEFAULT_CONTEXT_VALUE.loadingMarketBaskets);
  const [marketBaskets, setMarketBaskets] = useState(DEFAULT_CONTEXT_VALUE.marketBaskets);

  const loadMarketBaskets = async () => {
    setLoadingMarketBaskets(true);

    try {
      const marketBaskets = await getMarketBaskets();
      setMarketBaskets(marketBaskets);
    } catch (ex) {
      handleError(
        ex as Error,
        {
          title: 'Failed to Load Market Baskets',
          message: 'We encountered an unexpected error while loading Market Baskets. Please try again or contact an administrator.'
        }
      );
    } finally {
      setLoadingMarketBaskets(false);
    }
  };

  const contextValue: MarketBasketsContextValue = {
    marketBaskets,
    loadingMarketBaskets,
    loadMarketBaskets
  };

  return (
    <MarketBasketsContext.Provider value={contextValue}>
      {children}
    </MarketBasketsContext.Provider>
  );
};
