import { DateTime } from 'luxon';
import { z } from 'zod';

/**
 * Makes the correct schema for whether a post is published or not.
 * When a post is published, the "Publish Date" is set to readonly and
 * no longer editable alongside other fields.
 * When a post is being edited before it's published or being created for the first
 *  time, the "Publish Date" must be in the future.
 * @param isPublished
 */
export const makeCreateUpdatePostFormSchema = (isPublished: boolean) => {
  const scheduledForPublicationOnSchema = z.string()
    .trim()
    .optional()
    .superRefine(
      (data, ctx) => {
        if (!data) return;

        // ensure date is valid
        if (!DateTime.fromISO(data).isValid) {
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_date,
            message: 'Invalid date',
          });
        }

        // ensure date is today or in the future
        const dateLessThanToday = DateTime.fromISO(data).startOf('day') < DateTime.now().startOf('day');
        if (!isPublished && dateLessThanToday) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Publish Date must be in the future.',
          });
        }
      }
    );

  return z.object({
    title: z.string()
      .trim()
      .min(1, 'Title is required.')
      .max(50, { message: 'Title must be less than 50 characters.' }),
    content: z.string()
      .trim()
      .min(1, 'Body is required.'),
    summary: z.string()
      .trim()
      .min(1, 'Summary is required.'),
    category: z.string()
      .trim()
      .min(1, 'Category is required.'),
    targetStates: z.array(z.string()),
    scheduledForPublicationOn: scheduledForPublicationOnSchema
  }).required();
};

export type CreateUpdatePostFormSchema = z.infer<ReturnType<typeof makeCreateUpdatePostFormSchema>>;
