import { useAsync, UseAsyncStatus } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { useHeaderDetails } from '@shared/header';
import { maxLength, required } from '@shared/validators';
import { isValidDate } from '@shared/validators/date-time-validators';
import {
  Box,
  FileInput,
  FormField,
  Select,
  TextInput
} from 'grommet';
import { Basket } from 'grommet-icons';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Busy } from '@/components/busy';
import { FormCard } from '@/components/form-card';
import { DateTextInput, NumericTextInput } from '@/components/form-controls';
import { Button } from '@/components-new/button';
import { TextLink } from '@/components-new/text';
import { useMarketBasketService } from '@/features/market-baskets';
import { CreateMarketBasketProvider, useCreateMarketBasket } from '@/features/market-baskets/create-market-basket.provider';
import { CreateMarketBasketFormInputs, defaultCreateMarketBasketForm } from '@/features/market-baskets/types/market-basket-form-inputs';
import { notifySuccess } from '@/lib/notification/notifications';

const NewMarketBasketPage = () => {
  const { searchClients, clientLookups } = useCreateMarketBasket();
  const { handleError } = useErrorHandler();
  const { setHeaderDetails, clearHeaderDetails } = useHeaderDetails();
  const { createMarketBasket, uploadMarketDetailsFile } = useMarketBasketService();
  const navigate = useNavigate();

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    reset
  } = useForm<CreateMarketBasketFormInputs>({ mode: 'onBlur', reValidateMode: 'onBlur', defaultValues: defaultCreateMarketBasketForm });

  // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout>(null);

  const onCreate = async (form: CreateMarketBasketFormInputs) => {
    try {
      const { id } = await createMarketBasket(form);

      const triggerSuccessMessage = () => notifySuccess({ title: 'Created Successfully', message: 'Successfully created market basket.' });
      const triggerNavigation = () => navigate(`/market-baskets/${id}`);

      const marketDetailsFile = form.marketDetailsFile;
      if (!marketDetailsFile) {
        reset();
        triggerSuccessMessage();
        triggerNavigation();
        return Promise.resolve();
      }

      try {
        await uploadMarketDetailsFile(marketDetailsFile, id);
        triggerSuccessMessage();
        reset();
      } catch (marketDetailsFileError) {
        handleError(
          marketDetailsFileError as Error,
          {
            title: 'Gold Standard Upload Failed',
            message: 'Market Basket creation was successful, but the Gold Standard file could not be parsed. Please try importing the data again.',
          },
          'warn'
        );
      } finally {
        triggerNavigation();
      }
    } catch (marketBasketError) {
      handleError(
        marketBasketError as Error,
        {
          title: 'Creation Failed',
          message: 'We encountered an unexpected error while creating the market basket. Please try again or contact an administrator.',
        }
      );
    }
  };

  const onCreateAsync = useAsync(onCreate);
  const saving = onCreateAsync.status === UseAsyncStatus.Pending;

  const submitDisabled = !isValid || saving;

  React.useEffect(() => {
    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setHeaderDetails({
      documentTitle: 'Add Market Basket',
      pageTitle: 'Add Market Basket',
      breadcrumbs: [
        { icon: Basket, label: 'Market Baskets', url: '/market-baskets' },
        { label: 'Add Market Basket', url: '/market-baskets/new' }
      ]
    });
    return () => {
      clearHeaderDetails();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { status, error } = onCreateAsync;
    if (status === UseAsyncStatus.Pending || status === UseAsyncStatus.Idle) return;

    if (status === UseAsyncStatus.Error) {
      handleError(error, { title: 'Error', message: 'Unable to create market basket.' });

      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCreateAsync.status]);

  const handleFormSubmit = async (value: CreateMarketBasketFormInputs) => {
    void onCreateAsync.execute(value);
  };

  const handleCancel = () => {
    navigate('..');
  };

  const delaySearch = (searchCallback: () => void) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(setTimeout(searchCallback, 100));
  };

  return (
    <CreateMarketBasketProvider>
      <Box flex align="center">
        <FormCard>
          <Controller
            control={control}
            name="name"
            rules={{
              validate: {
                maxLength: maxLength(100),
                required: required('Name is required'),
              }
            }}
            render={({ field: { ref, value, onChange, onBlur } }) => (
              <FormField
                required
                name="name"
                htmlFor="name"
                label="Name"
                error={errors.name?.message}
              >
                <TextInput
                  ref={ref}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  id="name"
                  name="name"
                  placeholder="Market Basket Name"
                />
              </FormField>
            )}
          />

          <Controller
            control={control}
            name="client"
            rules={{
              validate: {
                required: required('Client is required')
              }
            }}
            render={({ field: { value, onChange, onBlur } }) => (
              <FormField
                required
                name="client"
                htmlFor="client"
                label="Client"
                error={errors.client?.message}
              >
                <Select
                  id="client"
                  name="client"
                  options={clientLookups}
                  labelKey="label"
                  placeholder="Search for Clients"
                  onBlur={onBlur}
                  onChange={({ value: nextValue }) => onChange(nextValue)}
                  onSearch={(text) => delaySearch(() => searchClients(text))}
                  value={value}
                />
              </FormField>
            )}
          />

          <Box direction="row" gap="small">
            <Box basis="1/2">
              <Controller
                control={control}
                name="currentCpiu"
                rules={{
                  validate: {
                    required: required('Current CPI-U is required'),
                  }
                }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <FormField
                    required
                    name="currentCpiu"
                    htmlFor="current-cpiu"
                    error={errors.currentCpiu?.message}
                    label={
                      <TextLink
                        href="https://www.bls.gov/regions/mid-atlantic/data/consumerpriceindexhistorical_us_table.htm"
                        target="_blank"
                      >Current CPI-U</TextLink>}
                  >
                    <NumericTextInput
                      id="current-cpiu"
                      name="currentCpiu"
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      maxWholePlaces={3}
                      maxDecimalPlaces={3}
                      placeholder="123.456"
                    />
                  </FormField>
                )}
              />
            </Box>
            <Box basis="1/2">
              <Controller
                control={control}
                name="currentCpiuDate"
                rules={{
                  validate: {
                    required: required('Current CPI-U Date is required'),
                    valid: isValidDate
                  }
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormField
                    name="currentCpiuDate"
                    htmlFor="current-cpiu-date"
                    label="Current CPI-U Date"
                    error={errors.currentCpiuDate?.message}
                  >
                    <DateTextInput
                      id="current-cpiu-date"
                      name="currentCpiuDate"
                      value={value}
                      onChange={({ target }) => onChange((target as any).value)}
                      onBlur={onBlur}
                    />
                  </FormField>
                )}
              />
            </Box>
          </Box>

          <Controller
            control={control}
            name="marketDetailsFile"
            render={({ field: { onChange } }) => (
              <FormField
                label="Gold Standard File (Optional)"
                name="marketDetailsFile"
                htmlFor="market-details-file"
                error={errors.marketDetailsFile?.message}
              >
                <FileInput
                  id="market-details-file"
                  name="marketDetailsFile"
                  multiple={false}
                  accept=".csv"
                  messages={{
                    browse: 'Browse'
                  }}
                  // @ts-expect-error TS(2339): Property 'target' does not exist on type 'ChangeEv... Remove this comment to see the full error message
                  onChange={({ target: { files } }) => onChange(files.length > 0 && files[0])}
                />
              </FormField>
            )}
          />

          <Box flex direction="row" gap="small" margin={{ top: 'large' }} justify="end">
            <Button plain onClick={handleCancel}>Cancel</Button>

            <Button
              onClick={handleSubmit(handleFormSubmit)}
              disabled={submitDisabled}
            >
              <Busy busy={saving} content="Create" />
            </Button>
          </Box>
        </FormCard>
      </Box>
    </CreateMarketBasketProvider>
  );
};

export default NewMarketBasketPage;
