import { Table, TableOptions, useReactTable } from '@tanstack/react-table';


/**
 * Creates and returns a table instance with the provided configuration options.
 * Allows the team to set sensible defaults.
 */
export const useTable = <T>(options: TableOptions<T>): Table<T> => {

  return useReactTable({
    enableSorting: options.enableSorting ?? false,
    ...options,
  });
};
