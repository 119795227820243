import { useQuery } from '@tanstack/react-query';

import { Notification } from '@/features/notifications/types/notification';
import { artiaProtectedApi } from '@/lib/api-client';

const getNotifications = (): Promise<Notification[]> => {
  return artiaProtectedApi.get('notifications');
};

export const useNotifications = () => {
  return useQuery({
    queryKey: ['user-notifications'],
    queryFn: async () => getNotifications()
  });
};
