import { Anchor, Button, ButtonExtendedProps } from 'grommet';
import React, { ReactNode } from 'react';

type ArtiaButtonLinkProps = {
  onClick: ButtonExtendedProps['onClick'];
  children: ReactNode;
};

export const ArtiaButtonLink = ({ onClick, children }: ArtiaButtonLinkProps) => {
  return (
    <Button plain onClick={onClick} className="font-sans">
      <Anchor as="span">{children}</Anchor>
    </Button>
  );
};
