import React from 'react';

import { NotificationConfig } from '@/lib/notification/notifications';

export type ErrorSeverity = 'error' | 'warn';

export type ErrorHandlerContextProps = {
  handleError: (error: Error, notificationConfig: NotificationConfig, severity?: ErrorSeverity) => void;
};

export const DEFAULT_PROPS: ErrorHandlerContextProps = {
  handleError: () => null,
};

export const ErrorHandlerContext = React.createContext<ErrorHandlerContextProps>(DEFAULT_PROPS);
