import clsx from 'clsx';
import React, { useState } from 'react';

type SegmentedControlProps = {
  className?: string;
  items: {
    value: string;
    label: string;
  }[];
  onChange: (value: string) => void;
  value?: string;
};

/**
 * A segmented control is a grouping of two or more items where one item is
 * currently selected and the other items are clickable.
 *
 * @param className Additional classes to apply to the control.
 * @param items The items in the control.
 * @param onChange The onChange function when the selected item changes. The
 * value of the selected item is passed.
 * @param value The initial value of the control. Defaults to the first item.
 */
export const SegmentedControl = ({ className, items, onChange, value }: SegmentedControlProps) => {
  const [selectedValue, setSelectedValue] = useState(value ?? items[0].value);

  const onClick = (value: string) => {
    setSelectedValue(value);
    onChange(value);
  };

  return (
    <SegmentedButtons className={className}>
      {items.map(item => (
        <SegmentedButton
          key={item.value}
          selected={selectedValue === item.value}
          onClick={() => onClick(item.value)}
        >{item.label}</SegmentedButton>
      ))}
    </SegmentedButtons>
  );
};

type SegmentedButtonsProps = {
  children: React.ReactNode;
  className?: string;
};

const SegmentedButtons = ({ children, className }: SegmentedButtonsProps) => {
  return (
    <span className={clsx(
      'isolate inline-flex space-x-0.5 rounded-md border border-gray-200 bg-gray-200 p-0.5 shadow-sm',
      className
    )}>
      {children}
    </span>
  );
};

type SegmentedButtonProps = {
  children: React.ReactNode;
  onClick: () => void;
  selected?: boolean;
};

const SegmentedButton = ({ children, onClick, selected = false }: SegmentedButtonProps) => {
  const buttonOnClick = selected ? undefined : onClick;

  return (
    <button
      className={clsx(
        'relative inline-flex items-center px-3 py-2 text-sm font-semibold transition-all duration-300 ease-in-out focus:z-10 ',
        selected ? 'rounded-md bg-white text-gray-900 hover:bg-gray-50' : 'bg-transparent text-gray-900 hover:rounded-md hover:text-gray-900/75'
      )}
      onClick={buttonOnClick}
    >
      {children}
    </button>
  );
};
