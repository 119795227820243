import { useErrorHandler } from '@shared/errors';
import React, { createContext, useContext, useState } from 'react';

import { useMarketBasketService } from '@/features/market-baskets/api/use-market-baskets-service';
import { MarketBasket } from '@/features/market-baskets/types/market-basket';

type MarketBasketDetailsContextValue = {
  marketBasket?: MarketBasket;
  loadingMarketBasket: boolean;
  loadMarketBasket: (id: number) => void;
};

const DEFAULT_CONTEXT_VALUE: MarketBasketDetailsContextValue = {
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'MarketBaske... Remove this comment to see the full error message
  marketBasket: null,
  loadingMarketBasket: false,
  loadMarketBasket: () => null,
};

const MarketBasketDetailsContext = createContext<MarketBasketDetailsContextValue>(DEFAULT_CONTEXT_VALUE);

// eslint-disable-next-line react-refresh/only-export-components
export const useMarketBasketDetails = () => useContext<MarketBasketDetailsContextValue>(MarketBasketDetailsContext);

export const MarketBasketDetailsProvider = ({ children }: MarketBasketDetailsProps) => {
  const { handleError } = useErrorHandler();
  const { getMarketBasket } = useMarketBasketService();

  // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  const [marketBasket, setMarketBasket] = useState<MarketBasket>(null);
  const [loadingMarketBasket, setLoadingMarketBasket] = useState<boolean>(false);

  const loadMarketBasket = async (id: number) => {
    setLoadingMarketBasket(true);

    try {
      const marketBasket = await getMarketBasket(id);
      setMarketBasket(marketBasket);
    } catch (ex) {
      handleError(
        ex as Error,
        {
          title: 'Failed to load Market Basket',
          message: 'We encountered an unexpected error while fetching Market Basket information. Please try again or contact an administrator.'
        }
      );
    } finally {
      setLoadingMarketBasket(false);
    }
  };

  const contextValue: MarketBasketDetailsContextValue = {
    marketBasket,
    loadingMarketBasket,
    loadMarketBasket
  };

  return (
    <MarketBasketDetailsContext.Provider value={contextValue}>
      {children}
    </MarketBasketDetailsContext.Provider>
  );
};

type MarketBasketDetailsProps = {
  children?: React.ReactNode
};
